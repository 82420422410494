import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateEquipment = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const request = {
        nome: props.name,
        descricao: props.description,
      };

      instance
        .post("emprestavel", request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Emprestável salvo com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = () => {
  const [load, setLoad] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [errorName, setErrorName] = useState(false);

  const refName = useRef(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setName("");
    setDescription("");
    if (errorName) setErrorName(false);
  };

  const handleClose = (event) => {
    handleDeleteAll();
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="descricao"
              label="Descrição"
              name="descricao"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateEquipment
          name={name}
          description={description}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const EquipmentNew = () => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Novo Emprestável"}
      icon={<AddBoxIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default EquipmentNew;
