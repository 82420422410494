import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectParticipantKey = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [errorParticipant, setErrorParticipant] = useState(null);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const handleChange = (event, newValue) => {
    setParticipant(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setIsLoaded(false);
    setParticipant(props.dataParentToChild);
    setErrorParticipant(props.errorParentToChild);
    setDisabledSelect(false);
    if (props.idActivity === 0) {
      setIsLoaded(true);
      setDisabledSelect(true);
    } else {
      const fullURL = `atividade/${props.idActivity}/participante/key`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const participants = [];
          result.map((item) =>
            participants.push({
              label: `${item.nome === "" ? "Sem nome" : item.nome}`,
              id: item.userId,
            })
          );
          setItems(sortListByLabel(participants));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          setError(msg);
        });
    }
  }, [props.idActivity, props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        disabled={disabledSelect}
        value={participant}
        onChange={handleChange}
        disablePortal
        id="participant"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado para esta atividade"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorParticipant}
            label="Participante"
          />
        )}
      />
    );
  }
});

export default SelectParticipantKey;
