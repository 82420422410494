import React, { useState } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";

import MessageDialog from "components/atoms/info/MessageDialog";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
  },
}));

const HelpTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <MessageDialog open={open} onClose={handleClose} title="Ajuda">
        {props.children}
      </MessageDialog>
      <HtmlTooltip arrow placement={props.placement} title={props.children}>
        <IconButton onClick={handleOpen}>
          <HelpIcon sx={{ color: "primary.main" }} />
        </IconButton>
      </HtmlTooltip>
    </>
  );
};

HelpTooltip.defaultProps = {
  placement: undefined,
  children: undefined,
};

HelpTooltip.propTypes = {
  placement: PropTypes.string,
  children: PropTypes.node,
};

export default HelpTooltip;
