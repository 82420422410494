import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import { LocationPavement } from "components/molecules/BlockPavements";
import { LocationTypeContext, AccessContext } from "context/CommonProvider";
import PageContainer from "components/templates/PageContainer";
import SelectLocationType from "components/atoms/select/SelectLocationType";
import SelectLocationAccess from "components/atoms/select/SelectLocationAccess";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `bloco/${props.idBlock}/local`;

      const request = {
        idLocalTipo: props.locationType.id,
        idAcesso: props.access.id,
        nome: props.name,
        descricao: props.description,
        andar: props.pavement,
        numero: props.locationNumber,
        comprimento: props.locationLength,
        largura: props.locationWidth,
        assentos: props.seat,
      };

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível cadastrar este Local",
              "error"
            );
          } else {
            snackContext.setMessage(`Local salvo com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, ref, navigate
  const [load, setLoad] = useState(false);

  const [locationType, setLocationType] = useState(null);
  const [idBlock, setIdBlock] = useState("");
  const [block, setBlock] = useState("");
  const [access, setAccess] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [pavement, setPavement] = useState(0);
  const [locationNumber, setLocationNumber] = useState("");
  const [locationLength, setLocationLength] = useState("");
  const [locationWidth, setLocationWidth] = useState("");
  const [area, setArea] = useState(0);
  const [seat, setSeat] = useState("");

  const [minPavement, setMinPavement] = useState(0);
  const [maxPavement, setMaxPavement] = useState(0);

  const [errorLocationType, setErrorLocationType] = useState(false);
  const [errorAccess, setErrorAccess] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorLocationNumber, setErrorLocationNumber] = useState(false);
  const [errorLocationLength, setErrorLocationLength] = useState(false);
  const [errorLocationWidth, setErrorLocationWidth] = useState(false);
  const [errorSeat, setErrorSeat] = useState(false);

  const refLocationType = useRef(null);
  const refAccess = useRef(null);
  const refName = useRef(null);
  const refLocationNumber = useRef(null);
  const refLocationLength = useRef(null);
  const refLocationWidth = useRef(null);
  const refSeat = useRef(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const items = props.items;
    setIdBlock(items.id);
    setBlock(items.nome);
    setMinPavement(items.subsolo * -1);
    setMaxPavement(items.andares);
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = () => {
    handleBack();
  };

  const handleChangeLocationNumber = (event) => {
    if (load) setLoad(false);
    if (errorLocationNumber) setErrorLocationNumber(false);

    setLocationNumber(event.target.value);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const locationTypeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorLocationType) setErrorLocationType(false);

    setLocationType(childData);
  };

  const accessCallback = (childData) => {
    if (load) setLoad(false);
    if (errorAccess) setErrorAccess(false);

    setAccess(childData);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeSeat = (event) => {
    if (load) setLoad(false);
    if (errorSeat) setErrorSeat(false);
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setSeat(newValue);
  };

  const handleChangeLocationLength = (event) => {
    if (load) setLoad(false);
    if (errorLocationLength) setErrorLocationLength(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") {
      setLocationLength(newValue);
      setArea(newValue * locationWidth);
    }
  };

  const handleChangeLocationWidth = (event) => {
    if (load) setLoad(false);
    if (errorLocationWidth) setErrorLocationWidth(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") {
      setLocationWidth(newValue);
      setArea(newValue * locationLength);
    }
  };

  const handleChangeArea = (event) => {
    if (load) setLoad(false);

    setArea(event.target.value);
  };

  function upPavement() {
    if (load) setLoad(false);

    if (pavement < maxPavement) setPavement((pavement) => pavement + 1);
  }

  function downPavement() {
    if (load) setLoad(false);

    if (pavement > minPavement) setPavement((pavement) => pavement - 1);
  }

  const handleClick = () => {
    if (!locationNumber) {
      refLocationNumber.current.focus();
      if (!errorLocationNumber) setErrorLocationNumber(true);
      alert("Por favor, preencha o Número.");
    } else if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (!locationType) {
      refLocationType.current.focus();
      if (!errorLocationType) setErrorLocationType(true);
      alert("Por favor, preencha um Tipo de Local.");
    } else if (!access) {
      refAccess.current.focus();
      if (!errorAccess) setErrorAccess(true);
      alert("Por favor, preencha um Tipo de Acesso.");
    } else if (!seat) {
      refSeat.current.focus();
      if (!errorSeat) setErrorSeat(true);
      alert("Por favor, preencha o campo Assentos.");
    } else if (!locationLength) {
      refLocationLength.current.focus();
      if (!errorLocationLength) setErrorLocationLength(true);
      alert("Por favor, preencha o Comprimento.");
    } else if (!locationWidth) {
      refLocationWidth.current.focus();
      if (!errorLocationWidth) setErrorLocationWidth(true);
      alert("Por favor, preencha a Largura.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={3} md={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 10 }}
              inputRef={refLocationNumber}
              error={errorLocationNumber}
              value={locationNumber}
              onChange={handleChangeLocationNumber}
              id="locationNumber"
              label="Número"
              name="locationNumber"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={9} md={4}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 100 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              id="numberPavements"
              sx={{
                mt: 1,
                p: 0.8,
                border: 1,
                "&:hover": {
                  borderColor: "primary.main",
                },
                borderRadius: 2,
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  height: "40px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ ml: 1, mr: 1 }}>{block}</Typography>
                <LocationPavement pavement={pavement} />
                {pavement !== maxPavement ? (
                  <InfoTooltip placement="top-start" title="Subir">
                    <IconButton onClick={upPavement}>
                      <ArrowUpwardIcon />
                    </IconButton>
                  </InfoTooltip>
                ) : (
                  <Box sx={{ ml: 5 }}></Box>
                )}
                {pavement !== minPavement && (
                  <InfoTooltip placement="top-end" title="Descer">
                    <IconButton onClick={downPavement}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </InfoTooltip>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <SelectLocationType
              dataParentToChild={locationType}
              parentCallback={locationTypeCallback}
              ref={refLocationType}
              errorParentToChild={errorLocationType}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <SelectLocationAccess
              dataParentToChild={access}
              parentCallback={accessCallback}
              ref={refAccess}
              errorParentToChild={errorAccess}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição"
              name="description"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 3 }}
              inputRef={refSeat}
              error={errorSeat}
              value={seat}
              onChange={handleChangeSeat}
              id="seat"
              label="Assentos"
              name="seat"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 3 }}
              inputRef={refLocationLength}
              error={errorLocationLength}
              value={locationLength}
              onChange={handleChangeLocationLength}
              id="locationLength"
              label="Comprimento (m)"
              name="locationLength"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 3 }}
              inputRef={refLocationWidth}
              error={errorLocationWidth}
              value={locationWidth}
              onChange={handleChangeLocationWidth}
              id="locationWidth"
              label="Largura (m)"
              name="locationWidth"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={3} lg={2}>
            <TextField
              fullWidth
              disabled
              margin="dense"
              value={area}
              onChange={handleChangeArea}
              id="area"
              label="Área (m)"
              name="area"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateLocation
          idBlock={idBlock}
          locationType={locationType}
          access={access}
          name={name}
          description={description}
          pavement={pavement}
          locationNumber={locationNumber}
          locationLength={locationLength}
          locationWidth={locationWidth}
          seat={seat}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const PavementRange = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);
  const locationTypeContext = useContext(LocationTypeContext);
  const accessContext = useContext(AccessContext);

  useEffect(() => {
    const fullURL = `bloco/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, locationTypeContext, accessContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const LocationNew = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Novo Local"} icon={<AddBoxIcon />}>
      <PavementRange {...props} />
    </PageContainer>
  );
};

export default LocationNew;
