import React, { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import PageContainer from "components/templates/PageContainer";
import instance from "api/definitions";

const ReturnAuth = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token", "username"]);

  const navigate = useNavigate();

  useEffect(() => {
    if (cookies.token) {
      removeCookie("token");
      removeCookie("username");
      removeCookie("level");
    }
    setError(null);
    setIsLoaded(false);
    if (props.load) {
      if (!props.cpf) {
        props.handleEmptyField("cpf");
      } else if (!props.password) {
        props.handleEmptyField("password");
      } else {
        const request = {
          user: props.cpf,
          pass: props.password,
        };

        instance
          .post("user/login", request)
          .then(function (response) {
            setIsLoaded(true);
            const result = response.data;
            if (result.error) {
              setError("Erro.");
            } else {
              if (result.auth) {
                if (result.permit) {
                  const tokenAuth = `Bearer ${result.token}`;
                  instance.defaults.headers["Authorization"] = tokenAuth;
                  setCookie("token", tokenAuth);
                  const user = result.user;
                  setCookie("username", user.nome);
                  const roles = result.roles;
                  let level = "";
                  if (roles.find((element) => element === "Sysadmin")) {
                    level = "Sysadmin";
                  } else if (
                    roles.find((element) => element === "Administrador")
                  ) {
                    level = "Administrador";
                  } else if (roles.find((element) => element === "Operador")) {
                    level = "Operador";
                  } else if (
                    roles.find((element) => element === "Assistente")
                  ) {
                    level = "Assistente";
                  } else if (roles.find((element) => element === "Auxiliar")) {
                    level = "Auxiliar";
                  }
                  setCookie("level", level);
                  localStorage.setItem("photo", String(user.photo));
                  navigate("/");
                } else {
                  setError(
                    "Este usuário não tem permissão para acessar este sistema."
                  );
                }
              } else {
                setError("Usuário ou senha incorretos.");
              }
            }
          })
          .catch(function (error) {
            let msg = "";
            if (error.response) msg = "Usuário ou senha errados.";
            else if (error.request) msg = "Sem resposta do servidor.";
            else msg = `Erro ao configurar a requisição: ${error.message}`;

            setIsLoaded(true);
            setError(msg);
          });
      }
    }
  }, [props, cookies, setCookie, removeCookie, navigate]);

  if (props.load) {
    if (error) {
      return (
        <Typography sx={{ color: "error.main" }}>Erro: {error}</Typography>
      );
    } else if (!isLoaded) {
      return <CircularProgress />;
    }
  }
};

const Fields = () => {
  const [cpf, setCPF] = useState("");
  const [password, setPassword] = useState("");
  const [errorCPF, setErrorCPF] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const textFieldCPF = useRef(null);
  const textFieldPassword = useRef(null);

  const handleEmptyField = (childData) => {
    setLoad(false);
    if (childData === "cpf") {
      setErrorCPF(true);
    } else if (childData === "password") {
      textFieldPassword.current.focus();
      setErrorPassword(true);
    }
  };

  const ErrorFields = () => {
    let error = "";
    if (errorCPF) error = "CPF";
    else if (errorPassword) error = "Senha";
    if (error) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Erro: Por favor, preencha o campo {error}.
        </Typography>
      );
    }
  };

  const handleChangeCPF = (event) => {
    if (load) setLoad(false);
    if (errorCPF) setErrorCPF(false);

    setCPF(event.target.value);
  };

  const handleChangePassword = (event) => {
    if (load) setLoad(false);
    if (errorPassword) setErrorPassword(false);

    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = () => {
    textFieldCPF.current.focus();
    setLoad(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  return (
    <>
      <Box sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
        <TextField
          autoFocus
          inputRef={textFieldCPF}
          error={errorCPF}
          value={cpf}
          onChange={handleChangeCPF}
          fullWidth
          margin="dense"
          id="cpf"
          label="CPF"
          name="cpf"
          autoComplete="off"
          required
        />
        <TextField
          inputRef={textFieldPassword}
          error={errorPassword}
          value={password}
          onChange={handleChangePassword}
          fullWidth
          margin="dense"
          id="password"
          label="Senha"
          name="password"
          autoComplete="off"
          required
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ mt: 1 }}>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            onClick={handleClick}
          >
            Entrar
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Link
          href="https://gdi.vr.uff.br/gdi-front/passwordForget.html"
          target="_blank"
        >
          Esqueci minha senha
        </Link>
      </Box>
      <Box sx={{ mt: 1 }}>
        <ReturnAuth
          cpf={cpf}
          password={password}
          load={load}
          handleEmptyField={handleEmptyField}
        />
        <ErrorFields />
      </Box>
    </>
  );
};

const Login = () => {
  localStorage.removeItem("reservationCalendarDateReservation");
  localStorage.removeItem("photo");
  return (
    <PageContainer
      maxWidth="xs"
      title={`SisSala Campus ${process.env.REACT_APP_CAMPUS_ENV}`}
      icon={<LockOutlinedIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default Login;
