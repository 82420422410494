import React from "react";
import { useParams } from "react-router-dom";

import UnitUpdatePage from "components/pages/UnitDivision/UnitUpdate";

const UnitUpdate = () => {
  const { id } = useParams();

  return <UnitUpdatePage id={id} />;
};

export default UnitUpdate;
