import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import BlockPavements from "components/molecules/BlockPavements";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ModifyBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `bloco/${props.id}`;

      const request = {
        nome: props.name,
        descricao: props.description,
        andares: props.floor,
        subsolo: props.underground,
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível atualizar este bloco",
              "error"
            );
          } else if (result.affected === 0) {
            snackContext.setMessage(
              "Nada foi modificado. Modifique alguma informação ou clique em voltar.",
              "warning"
            );
          } else {
            snackContext.setMessage(`Atualização salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const [load, setLoad] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [floor, setFloor] = useState(0);
  const [underground, setUnderground] = useState(0);

  const [errorName, setErrorName] = useState(false);

  const refName = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const items = props.items;
    setId(String(items.id));
    setName(String(items.nome));
    setDescription(String(items.descricao));
    setFloor(items.andares);
    setUnderground(items.subsolo);
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = (event) => {
    handleBack();
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  function incrementFloor() {
    if (load) setLoad(false);

    setFloor((floor) => floor + 1);
  }

  function decrementFloor() {
    if (load) setLoad(false);

    if (floor > 0) setFloor((floor) => floor - 1);
  }

  function incrementUnderground() {
    if (load) setLoad(false);

    setUnderground((underground) => underground + 1);
  }

  function decrementUnderground() {
    if (load) setLoad(false);

    if (underground > 0) setUnderground((underground) => underground - 1);
  }

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Box
          id="numberFloors"
          sx={{
            mt: 1,
            p: 1,
            maxWidth: 470,
            border: 1,
            borderColor: "gray",
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Grid container spacing={0}>
            <Grid item>
              <Typography sx={{ mt: 1, ml: 0.5 }}>
                Número de Andares:
              </Typography>
            </Grid>
            <Grid item sx={{ width: "40px" }}>
              {underground > 0 && (
                <InfoTooltip
                  arrow
                  placement="bottom-end"
                  title="Remover andar abaixo do térreo"
                >
                  <IconButton onClick={decrementUnderground}>
                    <RemoveCircleIcon />
                  </IconButton>
                </InfoTooltip>
              )}
            </Grid>
            <Grid item>
              <InfoTooltip
                arrow
                placement="bottom-start"
                title="Adicionar andar abaixo do térreo"
              >
                <IconButton onClick={incrementUnderground}>
                  <AddCircleIcon />
                </IconButton>
              </InfoTooltip>
            </Grid>
            <Grid item>
              <Box sx={{ mt: 0.5 }}>
                <BlockPavements floor={floor} underground={underground} />
              </Box>
            </Grid>
            <Grid item>
              <InfoTooltip
                arrow
                placement="top-start"
                title="Adicionar andar acima do térreo"
              >
                <IconButton onClick={incrementFloor}>
                  <AddCircleIcon />
                </IconButton>
              </InfoTooltip>
            </Grid>
            <Grid item>
              {floor > 0 && (
                <InfoTooltip
                  arrow
                  placement="top-end"
                  title="Remover andar acima do térreo"
                >
                  <IconButton onClick={decrementFloor}>
                    <RemoveCircleIcon />
                  </IconButton>
                </InfoTooltip>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyBlock
          id={id}
          name={name}
          description={description}
          floor={floor}
          underground={underground}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const BlockItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = "bloco/" + props.id;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const BlockUpdate = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Atualizar Bloco"} icon={<EditIcon />}>
      <BlockItems {...props} />
    </PageContainer>
  );
};

export default BlockUpdate;
