import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const SelectLocation = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [location, setLocation] = useState([]);
  const [errorLocation, setErrorLocation] = useState(null);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const handleChange = (event, newValue) => {
    setLocation(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setIsLoaded(false);
    setLocation(props.dataParentToChild);
    setErrorLocation(props.errorParentToChild);
    setDisabledSelect(false);
    if (props.idBlock === 0) {
      setIsLoaded(true);
      setDisabledSelect(true);
    } else {
      const fullURL = "bloco/" + props.idBlock + "/local";
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const locations = [];
          result.map((item) =>
            locations.push({
              label: `${item.numero} - ${item.nome}`,
              id: item.id,
            })
          );
          setItems(locations);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          setError(msg);
        });
    }
  }, [props.idBlock, props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        disabled={disabledSelect}
        value={location}
        onChange={handleChange}
        disablePortal
        id="location"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado para este bloco"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorLocation}
            label="Local"
          />
        )}
      />
    );
  }
});

export default SelectLocation;
