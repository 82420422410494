import React from "react";
import PropTypes from "prop-types";
import Footer from "components/organisms/container/Footer";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

const PageContainer = (props) => {
  return (
    <>
      <Container component="main" maxWidth={props.maxWidth}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>{props.icon}</Avatar>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
        </Box>
        {props.children}
      </Container>
      <Footer />
    </>
  );
};

PageContainer.propTypes = {
  maxWidth: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageContainer;
