import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { PickersActionBar } from "@mui/x-date-pickers";

const ActionBar = (props) => {
  return <PickersActionBar {...props} />;
};

const ButtonDatePicker = (props) => {
  return (
    <MobileDatePicker
      {...props}
      closeOnSelect
      slots={{ actionBar: ActionBar }}
      slotProps={{
        actionBar: {
          actions: ["today"],
        },
      }}
      localeText={{
        cancelButtonLabel: "Cancelar",
        toolbarTitle: "Selecione a data",
        todayButtonLabel: "Hoje",
      }}
    />
  );
};

export default ButtonDatePicker;
