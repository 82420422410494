import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/Event";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import UpdateReservationSingle from "components/atoms/reservation/UpdateReservationSingle";
import UpdateReservationMany from "components/atoms/reservation/UpdateReservationMany";
import SelectBlock from "components/atoms/select/SelectBlock";
import SelectLocation from "components/atoms/select/SelectLocation";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import ButtonTimePicker from "components/atoms/dateTime/ButtonTimePicker";
import SwitchConflict from "components/molecules/switch/SwitchConflict";
import SwitchRepeatUpdate from "components/molecules/switch/SwitchRepeatUpdate";
import {
  getParseDate,
  getDisplayDate,
  getMinTime,
  getMaxTime,
  formatReservation,
  getFormattedTimeHour,
} from "components/atoms/dateTime/GetFormattedDate";

const Fields = (props) => {
  //#region useState, useRef and navigate
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [loadUpdateMany, setLoadUpdateMany] = useState(false);

  const [checkedConflict, setCheckedConflict] = useState(false);
  const [conflictInternal, setConflictInternal] = useState("manual");
  const [conflictExternal, setConflictExternal] = useState("manual");

  const [checkedRepeat, setCheckedRepeat] = useState(false);
  const [weekDays, setWeekDays] = useState(() => [1, 2, 3, 4, 5]);
  const [dateRepeatEnd, setDateRepeatEnd] = useState(null);

  const [block, setBlock] = useState(null);
  const [location, setLocation] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [errorBlock, setErrorBlock] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);

  const refBlock = useRef(null);
  const refLocation = useRef(null);

  const [dateDisplay, setDateDisplay] = useState(null);
  const [locationCurrent, setLocationCurrent] = useState(null);
  const [hourCurrent, setHourCurrent] = useState(null);

  const [idActivity, setIdActivity] = useState(null);
  const [activity, setActivity] = useState(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const reservation = props.reservation.reserva;
    const activity = props.reservation.atividade;
    const locationOld = props.reservation.local;
    const blockOld = props.block;
    setIdActivity(String(activity.id));
    setActivity(activity.nome);
    if (blockOld.nome) {
      setBlock({ label: blockOld.nome, id: blockOld.id });
      setLocation({
        label: `${locationOld.numero} - ${locationOld.nome}`,
        id: locationOld.id,
      });
      setLocationCurrent(
        `${locationOld.numero} - ${locationOld.nome} - ${blockOld.nome}`
      );
    } else {
      setLocationCurrent("Sem local");
    }

    const reservationDate = reservation.inicio.split(" ");
    const propsDate = reservationDate[0];
    setDateReservation(getParseDate(propsDate));

    setDateDisplay(getDisplayDate(propsDate));
    const formatedHourCurrent = formatReservation(reservation);
    setHourCurrent(formatedHourCurrent[2]);
    const hourEnd = formatedHourCurrent[0] + formatedHourCurrent[1];

    setDateBegin(getMinTime(String(formatedHourCurrent[0])));
    setDateEnd(getMinTime(String(hourEnd)));
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const cleanLoad = () => {
    if (loadUpdate) setLoadUpdate(false);
    if (loadUpdateMany) setLoadUpdateMany(false);
  };

  //#region Conflict
  const checkedConflictCallback = (childData) => {
    cleanLoad();
    setCheckedConflict(childData);
  };

  const conflictInternalCallback = (childData) => {
    cleanLoad();
    setConflictInternal(childData);
  };

  const conflictExternalCallback = (childData) => {
    cleanLoad();
    setConflictExternal(childData);
  };
  //#endregion

  //#region Reservation
  const blockCallback = (childData) => {
    cleanLoad();
    if (location) setLocation(null);
    if (errorBlock) setErrorBlock(false);

    setBlock(childData);
  };

  const locationCallback = (childData) => {
    cleanLoad();
    if (errorLocation) setErrorLocation(false);

    setLocation(childData);
  };

  const handleChangeDateReservation = (newValue) => {
    cleanLoad();
    setDateReservation(newValue);
  };

  const handleChangeDateBegin = (newValue) => {
    cleanLoad();
    setDateBegin(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    cleanLoad();
    setDateEnd(newValue);
  };

  const getDateEnd = () => {
    const hour = String(Number(getFormattedTimeHour(dateBegin)) + 1);

    return hour;
  };
  //#endregion

  //#region Repeat
  const checkedRepeatCallback = (childData) => {
    cleanLoad();
    setCheckedRepeat(childData);
  };

  const weekDaysCallback = (childData) => {
    cleanLoad();
    setWeekDays(childData);
  };

  const dateRepeatEndCallback = (childData) => {
    cleanLoad();
    setDateRepeatEnd(childData);
  };
  //#endregion

  const handleClick = () => {
    let errorLoad = false;

    if (!errorLoad && !block) {
      errorLoad = true;
      refBlock.current.focus();
      !errorBlock && setErrorBlock(true);
      alert("Por favor, selecione um bloco.");
    }
    if (!errorLoad && !location) {
      errorLoad = true;
      refLocation.current.focus();
      !errorLocation && setErrorLocation(true);
      alert("Por favor, selecione um local.");
    }
    if (!errorLoad && !dateReservation) {
      errorLoad = true;
      alert("Por favor, selecione uma data.");
    }
    if (!errorLoad && !dateBegin) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Início.");
    }
    if (!errorLoad && !dateEnd) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Fim.");
    }
    if (!errorLoad && dateBegin >= dateEnd) {
      errorLoad = true;
      alert(
        "O horário de início não poder ser igual ou maior que o horário fim."
      );
    }

    if (checkedRepeat) {
      if (!errorLoad && weekDays.length === 0) {
        errorLoad = true;
        alert("Por favor, selecione pelo menos um dia da semana.");
      }
      if (!errorLoad && !dateRepeatEnd) {
        errorLoad = true;
        alert("Por favor, selecione uma Data final da Repetição.");
      }
      if (!errorLoad && dateReservation >= dateRepeatEnd) {
        errorLoad = true;
        alert(
          "A Data da Reserva não poder ser igual ou maior que a Data final da Repetição."
        );
      }
    }

    if (!errorLoad) {
      if (checkedRepeat) setLoadUpdateMany(true);
      else setLoadUpdate(true);
    }
  };

  return (
    <>
      <Box id="controlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box
          id="reservationCurrent"
          sx={{
            mt: 1,
            p: 2,
            color: "white",
            bgcolor: "primary.main",
          }}
        >
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item>
              <Box sx={{ display: "flex", mb: 1 }}>
                <AssignmentIcon />
                <Typography sx={{ ml: 1 }}>{activity}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", mb: 1 }}>
                <AccountBalanceIcon />
                <Typography sx={{ ml: 1 }}>{locationCurrent}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", mb: 1 }}>
                <EventIcon />
                <Typography sx={{ ml: 1 }}>{dateDisplay}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: "flex", mb: 1 }}>
                <AccessTimeIcon />
                <Typography sx={{ ml: 1 }}>{hourCurrent}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box id="boxReservation" sx={{ mt: 1 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <SelectBlock
                dataParentToChild={block}
                parentCallback={blockCallback}
                ref={refBlock}
                errorParentToChild={errorBlock}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectLocation
                idBlock={block ? block.id : 0}
                dataParentToChild={location}
                parentCallback={locationCallback}
                ref={refLocation}
                errorParentToChild={errorLocation}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
              <ButtonDatePicker
                value={dateReservation}
                onChange={handleChangeDateReservation}
                id="dateReservation"
                label="Data da Reserva"
                name="dateReservation"
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <ButtonTimePicker
                id={"dateBegin"}
                views={["hours"]}
                skipDisabled
                minTime={getMinTime("07")}
                maxTime={getMaxTime("21")}
                value={dateBegin}
                label={
                  dateBegin == null
                    ? "Hora Início"
                    : `Hora Início: ${getFormattedTimeHour(dateBegin)}`
                }
                onChange={handleChangeDateBegin}
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <ButtonTimePicker
                disable
                id={"dateEnd"}
                views={["hours"]}
                disabled={!dateBegin}
                skipDisabled
                minTime={dateBegin && getMinTime(getDateEnd())}
                maxTime={getMaxTime("22")}
                value={dateEnd}
                label={
                  dateEnd == null
                    ? "Hora Fim"
                    : `Hora Fim: ${getFormattedTimeHour(dateEnd)}`
                }
                onChange={handleChangeDateEnd}
              />
            </Grid>
          </Grid>
        </Box>
        <Box id="boxConfig" sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} lg={6}>
              <SwitchRepeatUpdate
                checkedParentToChild={checkedRepeat}
                weekDaysParentToChild={weekDays}
                repeatParentToChild={""}
                jumpParentToChild={1}
                dateRepeatEndParentToChild={dateRepeatEnd}
                dateReservationParentToChild={dateReservation}
                checkedParentCallback={checkedRepeatCallback}
                weekDaysParentCallback={weekDaysCallback}
                dateRepeatEndParentCallback={dateRepeatEndCallback}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <SwitchConflict
                checkedParentToChild={checkedConflict}
                internalParentToChild={conflictInternal}
                externalParentToChild={conflictExternal}
                checkedParentCallback={checkedConflictCallback}
                conflictInternalParentCallback={conflictInternalCallback}
                conflictExternalParentCallback={conflictExternalCallback}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <UpdateReservationSingle
          idReservation={props.id}
          location={location}
          dateReservation={dateReservation}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          load={loadUpdate}
          handleClose={handleBack}
        />
        <UpdateReservationMany
          idReservation={props.id}
          idActivity={idActivity}
          activityName={activity}
          weekDays={weekDays}
          dateRepeatEnd={dateRepeatEnd}
          conflictInternal={conflictInternal}
          conflictExternal={conflictExternal}
          location={location}
          block={block}
          dateReservation={dateReservation}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          load={loadUpdateMany}
        />
      </Box>
    </>
  );
};

const GetBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const idBlock = props.reservation.local.idBloco;
    if (idBlock === 0) {
      setIsLoaded(true);
    } else {
      if (props.reservation) {
        const fullURL = `bloco/${idBlock}`;
        instance
          .get(fullURL)
          .then(function (response) {
            setIsLoaded(true);
            const result = response.data;
            setItems(result);
          })
          .catch(function (error) {
            setIsLoaded(true);
            const msg = getErrorMessage(error);
            snackContext.setMessage(msg, "error");
          });
      }
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} block={items} />;
};

const GetReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = "reserva/" + props.id;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetBlock {...props} reservation={items} />;
};

const ReservationUpdate = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={`Atualizar Reserva`}
      icon={<AddBoxIcon />}
    >
      <GetReservation {...props} />
    </PageContainer>
  );
};

export default ReservationUpdate;
