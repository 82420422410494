import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { alpha } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";

import Paper from "@mui/material/Paper";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import Pagination from "components/atoms/table/Pagination";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateLoan = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const navigate = useNavigate();
  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      let idReserva = null;
      let idLocal = null;
      if (props.idReservation) {
        idReserva = props.idReservation;
      }
      if (props.idLocation) {
        idLocal = Number(props.idLocation);
      }

      const request = {
        idEmprestavel: props.equipment,
        idReserva,
        idLocal,
        userId: props.userId,
        descricao: props.description,
      };

      instance
        .post("emprestimo", request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Empréstimo salvo com sucesso`, "success");
          }
          navigate("/");
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
          navigate("/");
        });
    }
  }, [props, snackContext, navigate]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleClick } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...{
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      }}
    >
      {numSelected > 0 ? (
        <>
          <Box sx={{ mr: 1 }}>
            <InfoTooltip title="Excluir" placement="top">
              <Button variant="contained" onClick={handleClick}>
                Emprestar
              </Button>
            </InfoTooltip>
          </Box>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}{" "}
            {numSelected === 1
              ? " recurso selecionado"
              : "recursos selecionados"}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Recursos para empréstimo
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
};

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Descrição",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, headCells } = props;

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
            sx={{
              color: "white",
              "&.Mui-checked": {
                color: "white",
              },
              "&.MuiCheckbox-indeterminate": {
                color: "white",
              },
              "&:hover": {
                color: "primary.main",
                backgroundColor: "white",
              },
              mr: 2,
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const LoanTable = (props) => {
  const [load, setLoad] = useState(false);

  const [idReservation, setIdReservation] = useState("");
  const [idLocation, setIdLocation] = useState("");
  const [description, setDescription] = useState("");
  const [userId, setUserId] = useState("");
  const [equipment, setEquipment] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [selected, setSelected] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("emprestavel")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        //const newResult = sortListByName(result);
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhum recurso para empréstimo cadastrado.
        </Typography>
      );
    } else {
      const rows = items;
      const rowsLength = rows.length;

      const handleClickLoan = (event) => {
        if (props.participant) {
          setIdReservation(props.idReservation);
          setUserId(props.participant.id);
          setDescription(props.description);
          setEquipment(selected);

          setLoad(true);
        } else if (props.occupant) {
          setIdLocation(props.idLocation);
          setUserId(props.occupant.id);
          setDescription(props.description);
          setEquipment(selected);

          setLoad(true);
        } else {
          const person =
            props.participant === null ? "participante" : "ocupante";

          alert(`Selecione um ${person}.`);
        }

        //props.doLoan();
      };

      //#region checkbox
      const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelected = rows.map((n) => n.id);
          setSelected(newSelected);
          return;
        }
        setSelected([]);
      };

      const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          );
        }
        setSelected(newSelected);
      };

      const isSelected = (id) => selected.indexOf(id) !== -1;
      //#endregion

      const pageCallback = (childData) => {
        setPage(childData);
      };

      const rowsPerPageCallback = (childData) => {
        setRowsPerPage(childData);
      };

      if (rowsLength === 0) {
        return (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Typography sx={{ color: "error.main" }}>
              Nenhum recurso para empréstimo cadastrado.
            </Typography>
          </Box>
        );
      } else {
        const cells = (
          rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
        ).map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <StyledTableRow
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <StyledTableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </StyledTableCell>
              <StyledTableCell id={labelId} width="100">
                {row.nome}
              </StyledTableCell>
              <StyledTableCell width="70">{row.descricao}</StyledTableCell>
            </StyledTableRow>
          );
        });

        return (
          <Box sx={{ mt: 3, mb: 2 }}>
            <Paper>
              <EnhancedTableToolbar
                numSelected={selected.length}
                handleClick={handleClickLoan}
              />
              <TableContainer>
                <Table aria-label="data table" size="small">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                    headCells={headCells}
                  />
                  <TableBody>{cells}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Pagination
              name="Recursos"
              length={rows.length}
              pageParentToChild={page}
              rowsPerPageParentToChild={rowsPerPage}
              pageCallback={pageCallback}
              rowsPerPageCallback={rowsPerPageCallback}
            />
            <CreateLoan
              load={load}
              idReservation={idReservation}
              idLocation={idLocation}
              equipment={equipment}
              userId={userId}
              description={description}
            />
          </Box>
        );
      }
    }
  }
};

export default LoanTable;
