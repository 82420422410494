import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";
import Pagination from "components/atoms/table/Pagination";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import { getDisplayDate } from "components/atoms/dateTime/GetFormattedDate";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteLocationOccupant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `local/${props.idLocation}/ocupante`;

      instance
        .delete(fullURLDelete, { data: props.selected })
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (result) {
            snackContext.setMessage(
              `Ocupante removido deste Local com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleClickDelete } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...{
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      }}
    >
      {numSelected > 0 ? (
        <>
          <InfoTooltip title="Excluir" placement="top">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}{" "}
            {numSelected === 1
              ? " ocupante selecionado"
              : "ocupantes selecionados"}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Ocupantes
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
};

const headCells = [
  {
    id: "userId",
    numeric: true,
    disablePadding: false,
    label: "CPF",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nome",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Tipo",
  },
  {
    id: "enter",
    numeric: false,
    disablePadding: false,
    label: "Entrada",
  },
  {
    id: "exit",
    numeric: false,
    disablePadding: false,
    label: "Saída",
  },
  {
    id: "expiration",
    numeric: false,
    disablePadding: false,
    label: "Vencimento",
  },
];

function EnhancedTableHead(props) {
  const { userRole, onSelectAllClick, numSelected, rowCount, headCells } =
    props;

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          {userRole > 4 && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "white",
                },
                "&:hover": {
                  color: "primary.main",
                  backgroundColor: "white",
                },
                mr: 2,
              }}
            />
          )}
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
        {userRole > 4 && (
          <StyledTableCell padding="none">Editar</StyledTableCell>
        )}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ParticipantsTable = (props) => {
  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [msgDelete, setMsgDelete] = useState("");

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const userRole = GetUserRole();

  const rows = props.rows;
  const rowsLength = rows.length;

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  ///Delete
  const handleClickDelete = (event) => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleBack = () => {
    setLoadDelete(false);
    setSelected([]);
    props.handleReloadTable();
  };

  //#region checkbox
  const createMsgDelete = (newSelected) => {
    let selectedMsg;

    rows.forEach((element) => {
      newSelected.forEach((sel) => {
        if (element.userId === sel) {
          selectedMsg = (
            <>
              {selectedMsg}
              <StyledTableRow>
                <StyledTableCell>{element.userId}</StyledTableCell>
                <StyledTableCell>
                  {element.nome ? element.nome : "(Não informado)"}
                </StyledTableCell>
                <StyledTableCell>{element.tipo}</StyledTableCell>
                <StyledTableCell>
                  {element.entrada
                    ? element.entrada.substring(0, 5)
                    : "(Não informada)"}
                </StyledTableCell>
                <StyledTableCell>
                  {element.saida
                    ? element.saida.substring(0, 5)
                    : "(Não informada)"}
                </StyledTableCell>
                <StyledTableCell>
                  {element.vencimento
                    ? getDisplayDate(element.vencimento)
                    : "(Não informado)"}
                </StyledTableCell>
              </StyledTableRow>
            </>
          );
        }
      });
    });

    setMsgDelete(selectedMsg);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.userId);
      setSelected(newSelected);
      createMsgDelete(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    createMsgDelete(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  //#endregion

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Este local não tem ocupantes.
      </Typography>
    );
  } else {
    const cellsHeadDelete = headCells.map((headCell) => (
      <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
    ));
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row, index) => {
      const isItemSelected = isSelected(row.userId);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <StyledTableRow
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.userId}
          selected={isItemSelected}
        >
          <StyledTableCell padding="checkbox">
            {userRole > 4 && (
              <Checkbox
                onClick={(event) => handleClick(event, row.userId)}
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            )}
          </StyledTableCell>
          <StyledTableCell>{row.userId}</StyledTableCell>
          <StyledTableCell>
            {row.nome ? row.nome : "(Não informado)"}
          </StyledTableCell>
          <StyledTableCell>{row.tipo}</StyledTableCell>
          <StyledTableCell>
            {row.entrada ? row.entrada.substring(0, 5) : "(Não informada)"}
          </StyledTableCell>
          <StyledTableCell>
            {row.saida ? row.saida.substring(0, 5) : "(Não informada)"}
          </StyledTableCell>
          <StyledTableCell>
            {row.vencimento
              ? getDisplayDate(row.vencimento)
              : "(Não informado)"}
          </StyledTableCell>
          {userRole > 4 && (
            <StyledTableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              width="50"
            >
              <InfoTooltip placement="top-start" title="Editar Ocupante">
                <IconButton id={row.userId} onClick={props.handleClickEdit}>
                  <EditIcon />
                </IconButton>
              </InfoTooltip>
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    });
    return (
      <Box sx={{ mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleClickDelete={handleClickDelete}
        />
        <TableContainer>
          <Table aria-label="data table" size="small">
            <EnhancedTableHead
              userRole={userRole}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          name="Ocupantes"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
        <Box id="boxDelete">
          <DeleteLocationOccupant
            loadDelete={loadDelete}
            idLocation={props.idLocation}
            selected={selected}
            handleClose={handleBack}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.error}
            title="Exclusão"
            open={openDeleteConfirmDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
          >
            <p>
              Você tem certeza que gostaria de excluir{" "}
              {selected.length === 1 ? "este ocupante:" : "estes ocupantes:"}{" "}
            </p>
            <TableContainer>
              <Table aria-label="data table" size="small">
                <TableHead>
                  <StyledTableRow>{cellsHeadDelete}</StyledTableRow>
                </TableHead>
                <TableBody>{msgDelete}</TableBody>
              </Table>
            </TableContainer>
          </MessageDialog>
        </Box>
      </Box>
    );
  }
};

const LocationOccupantTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const handleClickEdit = (event) => {
    const participantItems = items.filter(
      (item) => item.userId === event.currentTarget.id
    );
    props.clickUpdateCallback(participantItems);
  };

  const handleReloadTable = (event) => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    const fullURL = `local/${props.idLocation}/ocupante`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props.idLocation, props.reloadTable, reloadTable, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else
    return (
      <ParticipantsTable
        idLocation={props.idLocation}
        rows={items}
        handleClickEdit={handleClickEdit}
        handleReloadTable={handleReloadTable}
      />
    );
};

export default LocationOccupantTable;
