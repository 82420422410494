import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import DrawerPrimary from "components/molecules/menu/DrawerPrimary";
import MenuPrimary from "components/molecules/menu/MenuPrimary";
import { GetUserRole } from "api/definitions";

import LogoUff from "assets/logos/logoUff.png";

const Image = () => {
  return <img src={LogoUff} height="30" alt="logo" />;
};

const LoginMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickLogin = () => navigate("/login");

  return (
    <Button
      variant={location.pathname === "/login" ? "contained" : "outlined"}
      onClick={handleClickLogin}
      sx={{
        mr: 1,
        color: location.pathname === "/login" ? "primary.main" : "white",
        backgroundColor: location.pathname === "/login" && "white",
        "&:hover": {
          color: "primary.main",
          backgroundColor: "white",
        },
      }}
    >
      Fazer login
    </Button>
  );
};

const Menu = () => {
  const userRole = GetUserRole();

  if (userRole > 4) return <MenuPrimary />;
  else return <LoginMenu />;
};

const ButtonAppBar = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <DrawerPrimary />
            <Image />
            <Typography
              variant="h6"
              fontWeight="bold"
              component="div"
              sx={{ flexGrow: 1, ml: 1 }}
            >
              SisSala Campus {process.env.REACT_APP_CAMPUS_ENV}
            </Typography>
            <Box sx={{ alignItems: "right" }}>
              <Menu />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Outlet />
    </>
  );
};

export default ButtonAppBar;
