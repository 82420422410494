import React, { useState, useCallback, useMemo, createContext } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export const SnackContext = createContext(null);

const SnackProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [msg, setMsg] = useState("");

  const { vertical, horizontal } = {
    vertical: "top",
    horizontal: "center",
  };

  const setMessage = useCallback((pMsg, pSeverity) => {
    setMsg(pMsg);
    setSeverity(pSeverity);
    setOpen(true);
  }, []);

  const contextValue = useMemo(
    () => ({
      setMessage,
    }),
    [setMessage]
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <SnackContext.Provider value={contextValue}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {msg}
        </MuiAlert>
      </Snackbar>
      {children}
    </SnackContext.Provider>
  );
};

export default SnackProvider;
