import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectOccupant = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [occupant, setOccupant] = useState([]);
  const [errorOccupant, setErrorOccupant] = useState(null);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const handleChange = (event, newValue) => {
    setOccupant(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setIsLoaded(false);
    setOccupant(props.dataParentToChild);
    setErrorOccupant(props.errorParentToChild);
    setDisabledSelect(false);
    if (props.idLocation === 0) {
      setIsLoaded(true);
      setDisabledSelect(true);
    } else {
      const fullURL = `local/${props.idLocation}/ocupante`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const occupants = [];

          result.map((item) =>
            occupants.push({
              label: `${item.nome === "" ? item.userId : item.nome} ${item.entrada && item.saida ? "(A partir de " + item.entrada.substring(0, 2) + "h até " + item.saida.substring(0, 2) + "h)" : ""}`,
              id: item.userId,
            })
          );
          setItems(sortListByLabel(occupants));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          setError(msg);
        });
    }
  }, [props.idLocation, props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        disabled={disabledSelect}
        value={occupant}
        onChange={handleChange}
        disablePortal
        id="occupant"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado para este local"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorOccupant}
            label="Ocupante"
          />
        )}
      />
    );
  }
});

export default SelectOccupant;
