import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import AssignmentIcon from "@mui/icons-material/Assignment";

import PageContainer from "components/templates/PageContainer";
import ActivityCardList from "components/organisms/CardList/ActivityCardList";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import { GetUserRole } from "api/definitions";

const Fields = () => {
  const userRole = GetUserRole();
  const navigate = useNavigate();

  const handleClickNew = () => {
    navigate("/atividade-nova");
  };

  return (
    <>
      {userRole > 5 && (
        <Box
          id="boxData"
          sx={{ position: "absolute", top: "14%", right: "10%" }}
        >
          <InfoTooltip title="Nova atividade" placement="left">
            <Fab
              size="medium"
              color="primary"
              aria-label="nova atividade"
              onClick={handleClickNew}
            >
              <AddIcon />
            </Fab>
          </InfoTooltip>
        </Box>
      )}
      <Box id="boxData" sx={{ mt: 2 }}>
        <ActivityCardList />
      </Box>
    </>
  );
};

const ActivityList = () => {
  return (
    <PageContainer maxWidth="lg" title={"Atividades"} icon={<AssignmentIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default ActivityList;
