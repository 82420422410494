import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreatePerson = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const request = {
        nome: props.name,
        userId: props.idRequester,
      };

      instance
        .post("pessoa", request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível cadastrar esta pessoa",
              "error"
            );
          } else {
            snackContext.setMessage(`Pessoa salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = () => {
  const [load, setLoad] = useState(false);

  const [name, setName] = useState("");
  const [idRequester, setIdRequester] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorIdRequester, setErrorIdRequester] = useState(false);

  const refName = useRef(null);
  const refIdRequester = useRef(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const idRequesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorIdRequester) setErrorIdRequester(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setIdRequester(childData);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setName("");
    setIdRequester("");
    if (errorName) setErrorName(false);
    if (errorIdRequester) setErrorIdRequester(false);
  };

  const handleClose = (event) => {
    handleDeleteAll();
  };

  const handleClick = () => {
    if (!idRequester) {
      refIdRequester.current.focus();
      if (!errorIdRequester) setErrorIdRequester(true);
      alert("Por favor, preencha o CPF.");
    } else if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <VerifyPerson
          title="CPF"
          dataParentToChild={idRequester}
          parentCallback={idRequesterCallback}
          ref={refIdRequester}
          errorParentToChild={errorIdRequester}
        />
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreatePerson
          name={name}
          idRequester={idRequester}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const PersonNew = () => {
  return (
    <PageContainer maxWidth="lg" title={"Nova Pessoa"} icon={<AddBoxIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default PersonNew;
