import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import MenuIcon from "@mui/icons-material/Menu";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupIcon from "@mui/icons-material/Groups";

import { GetUserRole } from "api/definitions";
import TemporaryDrawer from "components/atoms/menu/TemporaryDrawer";

import LogoUff2 from "assets/logos/logoUff2.png";

const Image = () => {
  return <img src={LogoUff2} height="30" alt="logo" />;
};

const DrawerPrimary = () => {
  const navigate = useNavigate();
  const userRole = GetUserRole();
  const location = useLocation();

  const handleClickReservation = () => navigate("/");
  const handleClickBlock = () => navigate("/bloco");
  const handleClickResource = () => navigate("/recurso");
  const handleClickActivity = () => navigate("/atividade");
  const handleClickUnit = () => navigate("/unidade");
  const handleClickPerson = () => navigate("/pessoa");
  const handleClickUser = () => navigate("/usuario");

  const Menu = () => (
    <Box>
      <Box sx={{ display: "inline-flex", mb: 1, ml: 2 }}>
        <Image />
        <Typography
          variant="h6"
          fontWeight="bold"
          component="div"
          sx={{ flexGrow: 1, ml: 1 }}
        >
          SisSala Campus {process.env.REACT_APP_CAMPUS_ENV}
        </Typography>
      </Box>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleClickReservation}
            selected={
              location.pathname === "/" || location.pathname === "/reserva"
                ? true
                : false
            }
          >
            <ListItemIcon>
              <EventNoteIcon />
            </ListItemIcon>
            <ListItemText primary="Reservas" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleClickBlock}
            selected={location.pathname === "/bloco" ? true : false}
          >
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText primary="Blocos e Locais" />
          </ListItemButton>
        </ListItem>
        {userRole > 6 && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleClickResource}
              selected={location.pathname === "/recurso" ? true : false}
            >
              <ListItemIcon>
                <ChairAltIcon />
              </ListItemIcon>
              <ListItemText primary="Recursos" />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleClickActivity}
            selected={location.pathname === "/atividade" ? true : false}
          >
            <ListItemIcon>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="Atividades" />
          </ListItemButton>
        </ListItem>
        {userRole > 6 && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={handleClickPerson}
              selected={location.pathname === "/pessoa" ? true : false}
            >
              <ListItemIcon>
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText primary="Pessoas" />
            </ListItemButton>
          </ListItem>
        )}
        {userRole > 6 && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleClickUnit}
                selected={location.pathname === "/unidade" ? true : false}
              >
                <ListItemIcon>
                  <VerticalSplitIcon />
                </ListItemIcon>
                <ListItemText primary="Unidades e Divisões" />
              </ListItemButton>
            </ListItem>
            {userRole === "não está pronto" && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleClickUser}
                  selected={location.pathname === "/usuario" ? true : false}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Usuários" />
                </ListItemButton>
              </ListItem>
            )}
          </>
        )}
      </List>
    </Box>
  );

  return (
    <TemporaryDrawer anchor={"left"} icon={<MenuIcon />}>
      <Menu />
    </TemporaryDrawer>
  );
};

export default DrawerPrimary;
