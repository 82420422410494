import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AccessibleIcon from "@mui/icons-material/Accessible";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateResource = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const accessible = props.accessible ? "1" : "0";
      const request = {
        nome: props.name,
        pcd: accessible,
      };

      instance
        .post("recurso", request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Recurso salvo com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = () => {
  const [load, setLoad] = useState(false);

  const [name, setName] = useState("");
  const [checkedAccessible, setCheckedAccessible] = useState(false);

  const [errorName, setErrorName] = useState(false);

  const refName = useRef(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeAccessible = (event) => {
    if (load) setLoad(false);

    setCheckedAccessible(event.target.checked);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    setName("");
    if (errorName) setErrorName(false);
  };

  const handleClose = (event) => {
    handleDeleteAll();
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box sx={{ display: "flex" }}>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <AccessibleIcon />
          </Avatar>
          <InfoTooltip placement="right" title="Recurso PCD">
            <Checkbox
              color="primary"
              checked={checkedAccessible}
              onChange={handleChangeAccessible}
              inputProps={{
                "aria-label": "Recurso PCD",
              }}
            />
          </InfoTooltip>
        </Box>
        <TextField
          fullWidth
          margin="dense"
          inputProps={{ maxLength: 200 }}
          inputRef={refName}
          error={errorName}
          value={name}
          onChange={handleChangeName}
          id="name"
          label="Nome"
          name="name"
          autoComplete="off"
        />
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateResource
          name={name}
          accessible={checkedAccessible}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const ResourceNew = () => {
  return (
    <PageContainer maxWidth="lg" title={"Novo Recurso"} icon={<AddBoxIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default ResourceNew;
