import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import axios from "axios";

const baseURL =
  process.env.REACT_APP_API_ENV || "https://sissala-back.vr.uff.br/";
//const baseURL = process.env.REACT_APP_API_ENV || "https://sissala-eeimvr-back.vr.uff.br/";

var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const instance = axios.create({
  baseURL: baseURL,
});

instance.defaults.headers["Content-Type"] = "application/json";

export const GetUserRole = () => {
  const [cookies] = useCookies(["token"]);
  const [isLoggedIn, setIsLoggedIn] = useState(0);

  /**
  9 - Sysadmin
  8 - Administrador
  7 - Operador
  6 - Assistente
  5 - Auxiliar
  4 - Professor
  3 - Técnico
  2 - Terceirizado
  1 - Aluno
  */

  const level = cookies.level;
  let loggedIn = 0;
  switch (level) {
    case "Sysadmin":
      loggedIn = 9;
      break;

    case "Administrador":
      loggedIn = 8;
      break;

    case "Operador":
      loggedIn = 7;
      break;

    case "Assistente":
      loggedIn = 6;
      break;

    case "Auxiliar":
      loggedIn = 5;
      break;

    default:
      loggedIn = 0;
      break;
  }

  let token = cookies.token;
  if (token === undefined) token = "undefined";

  useEffect(() => {
    if (token !== "undefined") {
      setIsLoggedIn(loggedIn);
      const auth = instance.defaults.headers["Authorization"];
      if (!auth) instance.defaults.headers["Authorization"] = token;
      /**
      instance
        .get("user/token")
        .then(function (response) {
          const result = response.data;
          const resultToken = `Bearer ${result.token}`;
          if (token !== resultToken)
            instance.defaults.headers["Authorization"] = resultToken;
        })
        .catch(function (error) {
          const msg = getErrorMessage(error);
          console.log(msg);
        });
        */
    } else setIsLoggedIn(0);
  }, [token, loggedIn]);

  return isLoggedIn;
};

export const getErrorMessage = (error) => {
  let msg = "";
  let description = "";
  if (error.response) {
    if (error.response.status) {
      const status = error.response.status;
      if (error.response.data.error) {
        if (error.response.data.error.description) {
          description = error.response.data.error.description;
        }
      }
      if (status === 500 && description === "Expired token") {
        msg = "Sessão expirada. Necessário login para continuar.";
      } else if (error.response.data.msg) msg = error.response.data.msg;
    }
  } else if (error.request) {
    msg = "Sem resposta do servidor.";
  } else {
    msg = "Erro ao configurar a requisição. ";
    if (error.message) msg += error.message;
  }
  if (msg === "") {
    msg = "Ocorreu um erro.";
  }

  return msg;
};

export default instance;
