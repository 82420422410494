import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import {
  getFormattedDateURL,
  getFormattedTime,
} from "components/atoms/dateTime/GetFormattedDate";

const getRequest = (props) => {
  const dateReservationFormatted = getFormattedDateURL(props.dateReservation);
  const timeBeginFormatted = getFormattedTime(props.dateBegin);
  const timeEndFormatted = getFormattedTime(props.dateEnd);
  const dateBeginFormatted =
    dateReservationFormatted + " " + timeBeginFormatted;
  const dateEndFormatted = dateReservationFormatted + " " + timeEndFormatted;

  let repeat = null;
  if (props.checkedRepeat) {
    repeat = {
      type: props.repeat,
      weekdays: props.weekDays,
      jump: props.jump,
      stop: getFormattedDateURL(props.dateRepeatEnd),
    };
  }

  let request = null;
  if (!props.idActivity) {
    const idActivityType = props.activityType.id;
    let idDisciplina = null;
    let turma = null;
    let semestre = null;

    if (idActivityType === "2") {
      idDisciplina = props.discipline.id;
      turma = props.className;
      semestre = props.semester.label;
    }

    request = {
      idAtividadeTipo: idActivityType,
      idDisciplina,
      turma,
      semestre,
      nome: props.name,
      descricao: props.description,
      userIdCriador: "11472732782",
      userIdSolicitante: props.idRequester,
      participantes: props.participants,
      reserva: {
        idLocal: props.location.id,
        inicio: dateBeginFormatted,
        fim: dateEndFormatted,
        conflict: {
          internal: props.conflictInternal,
          external: props.conflictExternal,
        },
        repeat,
      },
    };
  } else {
    request = {
      idLocal: props.location.id,
      inicio: dateBeginFormatted,
      fim: dateEndFormatted,
      conflict: {
        internal: props.conflictInternal,
        external: props.conflictExternal,
      },
      repeat,
    };
  }

  return request;
};

const CreateReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [insertId, setInsertId] = useState(null);

  const navigate = useNavigate();
  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      let fullURL = "reserva";
      if (props.idActivity) fullURL = `atividade/${props.idActivity}/reserva`;

      const request = getRequest(props);

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro ao cadastrar a reserva.", "error");
          } else {
            setItems(result.reservas);
            if (result.atividade) {
              if (result.atividade.performed) {
                setInsertId(result.atividade.insertId);
              }
            }
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          if (error.response) {
            if (error.response.data) {
              setItems(error.response.data.reservas);
              if (error.response.data.msg) {
                snackContext.setMessage(error.response.data.msg, "error");
              }
            }
          }
          const msg = getErrorMessage(error);
          if (msg === "Sessão expirada. Necessário login para continuar.") {
            navigate("/login");
          }
        });
    }
  }, [props, snackContext, navigate]);

  if (props.load) {
    const newProps = {
      conflictInternal: props.conflictInternal,
      conflictExternal: props.conflictExternal,
      idActivity: insertId ? insertId : props.idActivity,
      activityName: props.activityName,
      location: props.location,
      block: props.block,
      dateReservation: props.dateReservation,
      dateBegin: props.dateBegin,
      dateEnd: props.dateEnd,
    };

    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <Navigate
          to="/reserva-resposta"
          state={{
            from: "create",
            props: newProps,
            items: items,
          }}
        />
      );
    }
  }
};

CreateReservation.propTypes = {
  load: PropTypes.bool,
  idActivity: PropTypes.string,
  dateReservation: PropTypes.instanceOf(Date),
  dateBegin: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),

  checkedRepeat: PropTypes.bool,
  weekDays: PropTypes.array,
  repeat: PropTypes.string,
  jump: PropTypes.number,
  dateRepeatEnd: PropTypes.instanceOf(Date),

  conflictInternal: PropTypes.string,
  conflictExternal: PropTypes.string,

  location: PropTypes.object,
};

export default CreateReservation;
