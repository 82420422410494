import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectDiscipline = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [discipline, setDiscipline] = useState([]);
  const [errorDiscipline, setErrorDiscipline] = useState(null);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const handleChange = (event, newValue) => {
    setDiscipline(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setIsLoaded(false);
    setDiscipline(props.dataParentToChild);
    setErrorDiscipline(props.errorParentToChild);
    setDisabledSelect(false);

    if (props.idDivision === 0) {
      setIsLoaded(true);
      setDisabledSelect(true);
    } else {
      instance
        .get(`divisao/${props.idDivision}/disciplina`)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const disciplines = [];
          result.map((item) =>
            disciplines.push({ label: item.nome, id: item.id })
          );
          setItems(sortListByLabel(disciplines));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          setError(msg);
        });
    }
  }, [props.idDivision, props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        disabled={disabledSelect}
        value={discipline}
        onChange={handleChange}
        disablePortal
        id="discipline"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhuma cadastrada"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorDiscipline}
            label="Disciplina"
          />
        )}
      />
    );
  }
});

export default SelectDiscipline;
