import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import Pagination from "components/atoms/table/Pagination";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";

const ResourceLocationTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.id) {
      const fullURL = `recurso/${props.id}/local`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else {
    const rows = items;
    const rowsLength = rows.length;

    const handleClickView = (event) => {
      navigate(`/local/${event.currentTarget.id}`);
    };

    const pageCallback = (childData) => {
      setPage(childData);
    };

    const rowsPerPageCallback = (childData) => {
      setRowsPerPage(childData);
    };
    if (rowsLength === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Este recurso não está em nenhum local.
        </Typography>
      );
    } else {
      const cells = (
        rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
      ).map((row) => (
        <StyledTableRow
          key={row.idLocal}
          id={row.idLocal}
          onClick={handleClickView}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <StyledTableCell>{row.bloco}</StyledTableCell>
          <StyledTableCell>{`${row.nome} (${row.numero})`}</StyledTableCell>
        </StyledTableRow>
      ));
      return (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Locais com este recurso
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="tabela de recursos">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bloco</StyledTableCell>
                  <StyledTableCell>Local</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>{cells}</TableBody>
            </Table>
          </TableContainer>
          <Pagination
            name="Locais"
            length={rows.length}
            pageParentToChild={page}
            rowsPerPageParentToChild={rowsPerPage}
            pageCallback={pageCallback}
            rowsPerPageCallback={rowsPerPageCallback}
          />
        </Box>
      );
    }
  }
};

export default ResourceLocationTable;
