import React from "react";
import { useParams } from "react-router-dom";

import DisciplinePage from "components/pages/ActivityDiscipline/Discipline";

const Discipline = () => {
  const { id } = useParams();

  return <DisciplinePage id={id} />;
};

export default Discipline;
