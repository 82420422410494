import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import getDay from "date-fns/getDay";

export const getConvertedDate = (date) => {
  const splitDate = date.split("/");
  const convertedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  return convertedDate;
};

export const getParseDate = (date) => {
  const parseDate = parseISO(date);
  return parseDate;
};

export const getDisplayDateTime = (date) => {
  const displayDateTime = String(format(parseISO(date), "dd/MM/yyyy -- HH:mm"));
  return displayDateTime;
};

export const getDisplayDate = (date) => {
  const displayDate = String(format(parseISO(date), "dd/MM/yyyy"));
  return displayDate;
};

export const getDisplayTime = (date) => {
  const displayTime = String(format(parseISO(date), "HH:mm"));
  return displayTime;
};

export const getDisplayTimeHour = (date) => {
  const displayTime = String(format(parseISO(date), "HH"));
  return displayTime;
};

export const getFormatDate = (date) => {
  const displayDate = String(format(date, "dd/MM/yyyy"));
  return displayDate;
};

export const getFormattedDateURL = (date) => {
  const formattedDate = String(format(date, "yyyy-MM-dd"));
  return formattedDate;
};

export const getFormattedTime = (date) => {
  const formattedTime = String(format(date, "HH:mm:ss"));
  return formattedTime;
};

export const getFormattedTimeHour = (date) => {
  const formattedTimeHour = String(format(date, "HH"));
  return formattedTimeHour;
};

export const getFormattedDateTime = (date) => {
  const formattedDate = String(format(date, "yyyy/MM/dd HH:mm:ss"));
  return formattedDate;
};

export const getMinTime = (min) => {
  if (min.length === 1) min = "0" + min;
  const minTime = parseISO("2023-12-04 " + min + ":00:00");
  return minTime;
};

export const getMaxTime = (max) => {
  const maxTime = parseISO("2023-12-04 " + max + ":00:00");
  return maxTime;
};

export const formatReservation = (reservation) => {
  const reservationBegin = getDisplayTimeHour(reservation.inicio);
  const reservationEnd = getDisplayTimeHour(reservation.fim);
  const colSpan = Number(reservationEnd) - Number(reservationBegin);
  const intervalHour = `${reservationBegin}h - ${reservationEnd}h`;
  const intervalDate = getDisplayDate(reservation.inicio);
  return [Number(reservationBegin), colSpan, intervalHour, intervalDate];
};

export const getDateWeek = (dateP) => {
  const dateParse = getParseDate(dateP);
  const dayOfWeekNumber = getDay(dateParse);
  let dayOfWeekString = "";
  switch (dayOfWeekNumber) {
    case 0:
      dayOfWeekString = "Domingo";
      break;

    case 1:
      dayOfWeekString = "Segunda-feira";
      break;

    case 2:
      dayOfWeekString = "Terça-feira";
      break;

    case 3:
      dayOfWeekString = "Quarta-feira";
      break;

    case 4:
      dayOfWeekString = "Quinta-feira";
      break;

    case 5:
      dayOfWeekString = "Sexta-feira";
      break;

    case 6:
      dayOfWeekString = "Sábado";
      break;

    default:
      break;
  }

  return dayOfWeekString;
};

export const getDisplayDateWeek = (dateBegin) => {
  const dateDisplay = getDisplayDate(dateBegin);
  const dayOfWeekString = getDateWeek(dateBegin);

  const returnString = `${dateDisplay} (${dayOfWeekString})`;

  return returnString;
};

export const getDisplayDateWeekTime = (dateBegin, dateEnd) => {
  const timeBeginDisplay = getDisplayTimeHour(dateBegin);
  const timeEndDisplay = getDisplayTimeHour(dateEnd);
  const returnTime = `${timeBeginDisplay}h a ${timeEndDisplay}h`;
  const displayDateWeek = getDisplayDateWeek(dateBegin);

  const returnString = `${displayDateWeek} - ${returnTime}`;

  return returnString;
};
