import React, { useState, useEffect, useContext } from "react";
import { differenceInDays } from "date-fns";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import ReservationActivityLocationTable from "components/molecules/table/ReservationActivityLocationTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import ButtonTimePicker from "components/atoms/dateTime/ButtonTimePicker";
import {
  getFormattedDateURL,
  getFormattedTimeHour,
  getFormattedTime,
  getMinTime,
  getMaxTime,
} from "components/atoms/dateTime/GetFormattedDate";

const sortListByDate = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.inicio.localeCompare(b.inicio);
  });
  return newList;
};

const SearchAllReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [from, setFrom] = useState("");
  const [successDelete, setSuccessDelete] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);
    const idActivity = props.idActivity;
    const idLocation = props.idLocation;
    let fullURL = "";
    if (idActivity) {
      fullURL = `atividade/${idActivity}/reserva/all`;
      setFrom("activity");
    } else if (idLocation) {
      fullURL = `local/${idLocation}/reserva/all`;
      setFrom("location");
    }

    if (fullURL !== "" && props.loadAll) {
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(sortListByDate(result.reservas));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext, successDelete]);

  //Delete
  const successDeleteCallback = (id) => {
    setSuccessDelete(!successDelete);
  };

  if (props.loadAll) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <Box sx={{ pl: 1, pr: 4 }}>
            <ReservationActivityLocationTable
              from={from}
              rows={items}
              successDeleteCallback={successDeleteCallback}
            />
          </Box>
        </>
      );
    }
  }
};

const FilterSearch = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [from, setFrom] = useState("");
  const [successDelete, setSuccessDelete] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);
    const idActivity = props.idActivity;
    let idLocation = props.idLocation;
    if (idActivity) {
      setFrom("activity");
      if (props.withoutLocation) idLocation = 0;
    } else if (idLocation) setFrom("location");

    if ((idActivity || idLocation) && props.load) {
      const dateBeginFormatted = getFormattedDateURL(props.dateBegin);
      const dateEndFormatted = getFormattedDateURL(props.dateEnd);
      const timeBeginFormatted = getFormattedTime(props.hourBegin);
      const timeEndFormatted = getFormattedTime(props.hourEnd);
      const match = props.match === "match" ? true : false;
      const closedStart = !props.closedStart;
      const closedEnd = !props.closedEnd;

      const request = {
        idAtividade: idActivity,
        idLocal: idLocation,
        weekdays: props.weekDays,
        hourStart: timeBeginFormatted,
        hourEnd: timeEndFormatted,
        dayStart: dateBeginFormatted,
        dayEnd: dateEndFormatted,
        match: match,
        closedStart: closedStart,
        closedEnd: closedEnd,
      };

      const fullURL = `reserva/filter`;
      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(sortListByDate(result.list));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext, successDelete]);

  //Delete
  const successDeleteCallback = (id) => {
    setSuccessDelete(!successDelete);
  };

  if (props.load) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <Box sx={{ pl: 1, pr: 4 }}>
            <ReservationActivityLocationTable
              from={from}
              rows={items}
              successDeleteCallback={successDeleteCallback}
            />
          </Box>
        </>
      );
    }
  }
};

const SearchReservation = (props) => {
  const dateNow = new Date();
  const hourForBegin = getMinTime("07");
  const hourForEnd = getMinTime("22");

  const [load, setLoad] = useState(false);
  const [loadAll, setLoadAll] = useState(false);

  const [weekDays, setWeekDays] = useState(() => [1, 2, 3, 4, 5]);
  const [dateBegin, setDateBegin] = useState(dateNow);
  const [dateEnd, setDateEnd] = useState(dateNow);
  const [hourBegin, setHourBegin] = useState(hourForBegin);
  const [hourEnd, setHourEnd] = useState(hourForEnd);

  const [checkedFilter, setCheckedFilter] = useState(true);
  const [checkedMatch, setCheckedMatch] = useState("limit");
  const [checkedHourBegin, setCheckedHourBegin] = useState(true);
  const [checkedHourEnd, setCheckedHourEnd] = useState(true);
  const [checkedWithoutLocation, setCheckedWithoutLocation] = useState(false);

  const handleChangeCheckFilter = () => {
    handleClickClean();
    setCheckedFilter(!checkedFilter);
  };

  const handleChangeCheckMatch = (event) => {
    const newValue = event.target.value;
    setCheckedMatch(newValue);
    load && setLoad(false);
  };

  const handleChangeCheckHourBegin = () => {
    setCheckedHourBegin(!checkedHourBegin);
    load && setLoad(false);
  };

  const handleChangeCheckHourEnd = () => {
    setCheckedHourEnd(!checkedHourEnd);
    load && setLoad(false);
  };

  const handleChangeCheckWithoutLocation = () => {
    setCheckedWithoutLocation(!checkedWithoutLocation);
    load && setLoad(false);
  };

  const handleClickSearch = (event) => {
    if (!checkedFilter) {
      setLoadAll(true);
    } else {
      if (weekDays.length === 0) {
        alert("Por favor, escolha pelo menos um dia da semana.");
      } else if (differenceInDays(dateBegin, dateEnd) > 0) {
        alert("A data fim não pode ser menor que a data início.");
      } else {
        setLoad(true);
      }
    }
  };

  const handleClickClean = (event) => {
    load && setLoad(false);
    loadAll && setLoadAll(false);
  };

  const handleChangeWeekDays = (event, newValue) => {
    setWeekDays(newValue);
    load && setLoad(false);
  };

  const handleChangeDateBegin = (newValue) => {
    setDateBegin(newValue);
    load && setLoad(false);
  };

  const handleChangeDateEnd = (newValue) => {
    setDateEnd(newValue);
    load && setLoad(false);
  };

  const handleChangeHourBegin = (newValue) => {
    setHourBegin(newValue);
    load && setLoad(false);
  };

  const handleChangeHourEnd = (newValue) => {
    setHourEnd(newValue);
    load && setLoad(false);
  };

  const getHourEnd = () => {
    const hour = String(Number(getFormattedTimeHour(hourBegin)) + 1);

    return hour;
  };

  return (
    <>
      <Box>
        <InfoTooltip title="Pesquisar" placement="top">
          <IconButton variant="contained" onClick={handleClickSearch}>
            <SearchIcon />
          </IconButton>
        </InfoTooltip>
        <Switch checked={checkedFilter} onChange={handleChangeCheckFilter} />
        Filtrar reservas
        {checkedFilter && (
          <Box sx={{ mt: 2 }}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} lg={5}>
                <Box sx={{ display: "inline-flex" }}>
                  <Typography sx={{ mt: 2, mr: 1 }}>
                    Faixa de horário:
                  </Typography>
                  <Box sx={{ mr: 2 }}>
                    <ButtonTimePicker
                      id={"hourBegin"}
                      views={["hours"]}
                      skipDisabled
                      minTime={getMinTime("07")}
                      maxTime={getMaxTime("21")}
                      value={hourBegin}
                      label={
                        hourBegin == null
                          ? "Início"
                          : `Início: ${getFormattedTimeHour(hourBegin)}`
                      }
                      onChange={handleChangeHourBegin}
                    />
                  </Box>

                  <ButtonTimePicker
                    disable
                    id={"hourEnd"}
                    views={["hours"]}
                    skipDisabled
                    minTime={hourBegin && getMinTime(getHourEnd())}
                    maxTime={getMaxTime("22")}
                    value={hourEnd}
                    label={
                      hourEnd == null
                        ? "Fim"
                        : `Fim: ${getFormattedTimeHour(hourEnd)}`
                    }
                    onChange={handleChangeHourEnd}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <ButtonDatePicker
                    sx={{ width: 150, mr: 3 }}
                    value={dateBegin}
                    onChange={handleChangeDateBegin}
                    id="dateBegin"
                    label="Data início"
                    name="dateBegin"
                  />
                  <ButtonDatePicker
                    sx={{ width: 150 }}
                    value={dateEnd}
                    disabled={!dateBegin}
                    onChange={handleChangeDateEnd}
                    id="dateEnd"
                    label="Data fim"
                    name="dateEnd"
                  />
                </Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={weekDays}
                    onChange={handleChangeWeekDays}
                    aria-label="weekDays"
                  >
                    <ToggleButton
                      value={0}
                      aria-label="0"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Domingo" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">D</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={1}
                      aria-label="1"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Segunda" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">S</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={2}
                      aria-label="2"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Terça" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">T</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={3}
                      aria-label="3"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Quarta" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">Q</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={4}
                      aria-label="4"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Quinta" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">Q</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={5}
                      aria-label="5"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Sexta" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">S</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      value={6}
                      aria-label="6"
                      sx={{ height: 50, p: 0 }}
                    >
                      <Tooltip title="Sábado" followCursor>
                        <Box sx={{ p: 2 }}>
                          <Typography variant="h6">S</Typography>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={12} lg={7}>
                {props.idActivity && (
                  <Box id="checkedWithoutLocation">
                    <Checkbox
                      checked={checkedWithoutLocation}
                      onChange={handleChangeCheckWithoutLocation}
                    />
                    Pesquisar Reservas sem Local
                  </Box>
                )}
                <Box>
                  <FormControl id="formMatch" sx={{ ml: 1 }}>
                    <RadioGroup
                      row
                      aria-labelledby="radio-match-group"
                      name="controlled-radio-match-group"
                      value={checkedMatch}
                      onChange={handleChangeCheckMatch}
                    >
                      <FormControlLabel
                        value="match"
                        control={<Radio />}
                        label="Pesquisar reservas com início e fim iguais aos da faixa de horário."
                      />
                      <FormControlLabel
                        value="limit"
                        control={<Radio />}
                        label="Pesquisar reservas contidas na faixa de horário."
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                {checkedMatch === "limit" && (
                  <Box id="checkedHours">
                    <Box id="checkedHourBegin">
                      <Checkbox
                        checked={checkedHourBegin}
                        onChange={handleChangeCheckHourBegin}
                      />
                      As reservas podem começar antes do início da faixa de
                      horário
                    </Box>
                    <Box id="checkedHourEnd">
                      <Checkbox
                        checked={checkedHourEnd}
                        onChange={handleChangeCheckHourEnd}
                      />
                      As reservas podem terminar depois do fim da faixa de
                      horário.
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>

      <Box sx={{ pl: 1, pr: 4 }}>
        <FilterSearch
          {...props}
          load={load}
          weekDays={weekDays}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          hourBegin={hourBegin}
          hourEnd={hourEnd}
          match={checkedMatch}
          closedStart={checkedHourBegin}
          closedEnd={checkedHourEnd}
          withoutLocation={checkedWithoutLocation}
        />
        <SearchAllReservation {...props} loadAll={loadAll} />
      </Box>
    </>
  );
};

export default SearchReservation;
