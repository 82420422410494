import React from "react";
import { useParams } from "react-router-dom";

import ReservationUpdatePage from "components/pages/ReservationEquipment/ReservationUpdate";

const ReservationUpdate = () => {
  const { id } = useParams();

  return <ReservationUpdatePage id={id} />;
};

export default ReservationUpdate;
