import { styled } from "@mui/material/styles";

import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: "#5ce1e6",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableAgendaRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledTableAgendaCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    border: "1px solid #dadde9",
  },
  [`&.bodyCell`]: {
    fontSize: 10,
    fontWeight: 700,
    textAlign: "center",
    color: theme.palette.common.white,
  },
  [`&.headCol`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: 14,
  },
}));

export const getCellActivityWidth = (colSpan) => {
  const widthSize = colSpan * 55;
  const widthReturn = widthSize + "px";
  return widthReturn;
};

export const getCellActivityBgcolor = (idActivityType) => {
  let bgColor = "";
  switch (idActivityType) {
    case "2":
      bgColor = "success.dark";
      break;
    case "3":
      bgColor = "secondary.dark";
      break;
    case "4":
      bgColor = "warning.dark";
      break;
    case "5":
      bgColor = "gray";
      break;

    default:
      bgColor = "info.main";
      break;
  }
  return bgColor;
};
