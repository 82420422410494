import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";

import { SnackContext } from "context/SnackProvider";
import instance from "api/definitions";
import {
  getFormattedDateURL,
  getFormattedTime,
} from "components/atoms/dateTime/GetFormattedDate";

const getRequest = (props) => {
  const dateReservationFormatted = getFormattedDateURL(props.dateReservation);
  const timeBeginFormatted = getFormattedTime(props.dateBegin);
  const timeEndFormatted = getFormattedTime(props.dateEnd);
  const dateBeginFormatted =
    dateReservationFormatted + " " + timeBeginFormatted;
  const dateEndFormatted = dateReservationFormatted + " " + timeEndFormatted;

  const request = {
    idLocal: props.location.id,
    inicio: dateBeginFormatted,
    fim: dateEndFormatted,
    conflict: {
      internal: props.conflictInternal,
      external: props.conflictExternal,
    },
    repeat: {
      weekdays: props.weekDays,
      stop: getFormattedDateURL(props.dateRepeatEnd),
    },
  };

  return request;
};

const UpdateReservationMany = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `reserva/${props.idReservation}`;

      const request = getRequest(props);

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro ao atualizar a reserva.", "error");
          } else {
            setItems(result.reservas);
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          if (error.response) {
            setItems(error.response.data.reservas);
          }
        });
    }
  }, [props, snackContext]);

  if (props.load) {
    const newProps = {
      conflictInternal: props.conflictInternal,
      conflictExternal: props.conflictExternal,
      idActivity: props.idActivity,
      activityName: props.activityName,
      location: props.location,
      block: props.block,
      dateReservation: props.dateReservation,
      dateBegin: props.dateBegin,
      dateEnd: props.dateEnd,
    };

    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <Navigate
          to="/reserva-resposta"
          state={{
            from: "update",
            props: newProps,
            items: items,
          }}
        />
      );
    }
  }
};

UpdateReservationMany.propTypes = {
  load: PropTypes.bool,
  location: PropTypes.object,
  dateReservation: PropTypes.instanceOf(Date),
  dateBegin: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),

  checkedRepeat: PropTypes.bool,
  weekDays: PropTypes.array,
  repeat: PropTypes.string,
  jump: PropTypes.number,
  dateRepeatEnd: PropTypes.instanceOf(Date),

  conflictInternal: PropTypes.string,
  conflictExternal: PropTypes.string,
};

export default UpdateReservationMany;
