import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SchoolIcon from "@mui/icons-material/School";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import DisciplineCard from "components/molecules/card/DisciplineCard";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import MessageDialog from "components/atoms/info/MessageDialog";

const DeleteDiscipline = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `disciplina/${props.idLocation}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir esta Disciplina.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Disciplina excluída com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, useRef and navigate
  const userRole = GetUserRole();

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const navigate = useNavigate();
  //#endregion

  const handleBack = () => {
    navigate(-1);
  };

  const handleClickEdit = (event) => {
    navigate(`/disciplina-atualiza/${event.currentTarget.id}`);
  };

  //#region handleDelete
  const handleDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleCloseSuccessDelete = (event) => {
    navigate("/unidade");
  };
  //#endregion

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 7 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-start" title="Editar">
            <IconButton onClick={handleClickEdit} id={props.id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="right" title="Excluir">
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <DisciplineCard id={props.id} />
      <Box id="deleteBox">
        <DeleteDiscipline
          loadDelete={loadDelete}
          idLocation={props.id}
          handleClose={handleCloseSuccessDelete}
        />
        <MessageDialog
          confirm
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
        >
          <Typography sx={{ color: "error.main" }}>
            Você tem certeza que gostaria de excluir esta Disciplina?
          </Typography>
        </MessageDialog>
      </Box>
    </>
  );
};

const Discipline = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Disciplina"} icon={<SchoolIcon />}>
      <Fields {...props} />
    </PageContainer>
  );
};

export default Discipline;
