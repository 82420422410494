import React, { useState, useEffect, useContext, useRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import SelectResource from "components/atoms/select/SelectResource";
import LocationResourceTable from "components/molecules/table/LocationResourceTable";

const AddResource = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `local/${props.idLocation}/recurso`;

      const request = {
        idRecurso: props.resource.id,
        quantidade: props.quantity,
        obs: props.observation,
      };

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Recurso salvo com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const LocationResource = (props) => {
  const [load, setLoad] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [disableResource, setDisableResource] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const [resource, setResource] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [observation, setObservation] = useState("");

  const [errorResource, setErrorResource] = useState(false);
  const [errorQuantity, setErrorQuantity] = useState(false);

  const refResource = useRef(null);
  const refQuantity = useRef(null);

  const userRole = GetUserRole();

  const handleShowForm = () => {
    !showForm && setShowForm(true);
  };

  const handleNotShowForm = () => {
    showForm && setShowForm(false);
    setDisableResource(false);
  };

  const handleClose = (event) => {
    load && setLoad(false);
    handleNotShowForm();
    setReloadTable(!reloadTable);
    setResource(null);
    setQuantity("");
    setObservation("");
    errorResource && setErrorResource(false);
    errorQuantity && setErrorQuantity(false);
  };

  const clickUpdateCallback = (childData) => {
    handleShowForm();
    setDisableResource(true);
    childData.forEach((element) => {
      const resourceArray = {
        id: element.idRecurso,
        label: element.nome,
      };
      setResource(resourceArray);
      setQuantity(element.quantidade);
      setObservation(element.obs);
    });
  };

  const resourceCallback = (childData) => {
    if (errorResource) setErrorResource(false);

    setResource(childData);
  };

  const handleChangeQuantity = (event) => {
    if (load) setLoad(false);
    if (errorQuantity) setErrorQuantity(false);
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setQuantity(newValue);
  };

  const handleChangeObservation = (event) => {
    if (load) setLoad(false);

    setObservation(event.target.value);
  };

  const handleClick = () => {
    if (!resource) {
      refResource.current.focus();
      if (!errorResource) setErrorResource(true);
      alert("Por favor, selecione um Recurso.");
    } else if (!quantity) {
      refQuantity.current.focus();
      if (!errorQuantity) setErrorQuantity(true);
      alert("Por favor, preencha o campo Quantidade.");
    } else {
      setLoad(true);
    }
  };

  const NewButtons = () => {
    const handleNew = () => {
      handleClose();
      handleShowForm();
    };

    return (
      <Box>
        {!showForm && (
          <Button variant="contained" onClick={handleNew}>
            Adicionar Recurso a este Local
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {userRole > 5 && <NewButtons />}
      {userRole > 5 && showForm && (
        <Box id="formLocationResource">
          <Box id="ControlButtons">
            <InfoTooltip placement="top-start" title="Voltar">
              <IconButton onClick={handleNotShowForm}>
                <ArrowBackIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-start" title="Salvar">
              <IconButton onClick={handleClick}>
                <SaveIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={9}>
              <SelectResource
                autoFocus={!disableResource}
                disabled={disableResource}
                dataParentToChild={resource}
                parentCallback={resourceCallback}
                ref={refResource}
                errorParentToChild={errorResource}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                autoFocus={disableResource}
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 4 }}
                inputRef={refQuantity}
                error={errorQuantity}
                value={quantity}
                onChange={handleChangeQuantity}
                id="quantity"
                label="Quantidade"
                name="quantity"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                value={observation}
                onChange={handleChangeObservation}
                id="observation"
                label="Observação"
                name="observation"
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box id="boxData" sx={{ mt: 2 }}>
        <LocationResourceTable
          idLocation={props.idLocation}
          clickUpdateCallback={clickUpdateCallback}
          reloadTable={reloadTable}
        />
        <AddResource
          idLocation={props.idLocation}
          resource={resource}
          quantity={quantity}
          observation={observation}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

export default LocationResource;
