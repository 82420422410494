import React from "react";

import Error from "components/pages/Error";
import PageNotFound from "draws/PageNotFound";

const error404 = () => (
  <Error
    image={<PageNotFound />}
    title="Página não encontrada"
    description=""
  />
);

export default error404;
