import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const ActivityCard = (props) => {
  const [changeFields, setChangeFields] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (props.activityType === "Ensalamento" && !props.list)
      setChangeFields(true);
  }, [props.list, props.activityType]);

  const handleClickView = useCallback(
    (event) => {
      navigate(`/atividade/${event.currentTarget.id}`);
    },
    [navigate]
  );

  return (
    <Card raised id={props.idActivity} sx={{ mb: 1, minWidth: 300 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {props.list ? (
              <Button onClick={handleClickView} id={props.idActivity}>
                <Typography gutterBottom variant="h6" component="div">
                  {props.name}
                </Typography>
              </Button>
            ) : (
              <Typography gutterBottom variant="h6" component="div">
                {props.name}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <strong>Tipo:</strong> {props.activityType}
          </Grid>
          <Grid item>
            <strong>Solicitante:</strong>{" "}
            {Number(props.userRequester)
              ? "Sem nome"
              : props.userRequester.toUpperCase()}
          </Grid>
          {!props.list && (
            <Grid item>
              <strong>Usuário:</strong> {props.userCreator}
            </Grid>
          )}
          {props.list && props.division && (
            <Grid item>
              <strong>Divisão:</strong> {props.division}
            </Grid>
          )}
          <Grid item>
            <strong>Participantes:</strong> {props.participants}
          </Grid>
          {!props.list && (
            <Grid item>
              <strong>Descrição:</strong>{" "}
              {props.description ? props.description : "(Não informada)"}
            </Grid>
          )}
        </Grid>
        {changeFields && (
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item>
                <strong>Unidade:</strong> {props.unit}
              </Grid>
              <Grid item>
                <strong>Divisão:</strong> {props.division}
              </Grid>
              <Grid item>
                <strong>Turma:</strong> {props.className}
              </Grid>
              <Grid item>
                <strong>Semestre:</strong> {props.semester}
              </Grid>
              <Grid item>
                <strong>Disciplina:</strong> {props.discipline}
              </Grid>
            </Grid>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

ActivityCard.defaultProps = {
  list: false,
  idActivity: undefined,
  activityType: undefined,
  name: undefined,
  description: undefined,
  userIdCreator: undefined,
  userIdRequester: undefined,
  userCreator: undefined,
  userRequester: undefined,
  participants: undefined,
  unit: undefined,
  division: undefined,
  discipline: undefined,
  semester: undefined,
  className: undefined,
};

ActivityCard.propTypes = {
  list: PropTypes.bool,
  idActivity: PropTypes.string,
  activityType: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  userIdCreator: PropTypes.string,
  userIdRequester: PropTypes.string,
  userCreator: PropTypes.string,
  userRequester: PropTypes.string,
  participants: PropTypes.string,
  unit: PropTypes.string,
  division: PropTypes.string,
  discipline: PropTypes.string,
  semester: PropTypes.string,
  className: PropTypes.string,
};

export default ActivityCard;
