import React from "react";
import { useParams } from "react-router-dom";

import LocationPageNew from "components/pages/BlockLocation/LocationNew";

const LocationNew = () => {
  const { id } = useParams();

  return <LocationPageNew id={id} />;
};

export default LocationNew;
