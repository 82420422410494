import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import Pagination from "components/atoms/table/Pagination";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteEquipment = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `emprestavel/${props.idEquipment}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível remover este Emprestável deste Local.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Emprestável removido deste Local com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const EquipmentTable = (props) => {
  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [idEquipment, setIdEquipment] = useState("");
  const [name, setName] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const navigate = useNavigate();

  const rows = props.rows;
  const rowsLength = rows.length;

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  const handleClickEdit = (event) => {
    navigate(`/emprestavel-atualiza/${event.currentTarget.id}`);
  };

  //Delete
  const handleClickDelete = (event) => {
    const arr = String(event.currentTarget.id).split("&");
    setIdEquipment(arr[0]);
    setName(arr[1]);
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleBack = () => {
    setLoadDelete(false);
    props.handleReloadTable();
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro ao carregar a tabela
      </Typography>
    );
  } else {
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row) => (
      <StyledTableRow key={row.id}>
        <StyledTableCell>{row.nome}</StyledTableCell>
        <StyledTableCell>
          {row.descricao ? row.descricao : "(Não informada)"}
        </StyledTableCell>
        <StyledTableCell>
          <IconButton
            onClick={handleClickEdit}
            id={row.id}
            sx={{
              bgcolor: "",
              "&:hover": {
                backgroundColor: "warning.light",
              },
            }}
          >
            <EditIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          {row.id !== "1" && (
            <IconButton
              onClick={handleClickDelete}
              id={row.id + "&" + row.nome}
              sx={{
                bgcolor: "",
                "&:hover": {
                  backgroundColor: "error.light",
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </StyledTableCell>
      </StyledTableRow>
    ));
    return (
      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="tabela de emprestaveis">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Nome</StyledTableCell>
                <StyledTableCell>Descrição</StyledTableCell>
                <StyledTableCell sx={{ width: 10 }}>Editar</StyledTableCell>
                <StyledTableCell sx={{ width: 10 }}>Excluir</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          name="Emprestáveis"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
        <Box id="boxDelete">
          <DeleteEquipment
            loadDelete={loadDelete}
            idEquipment={idEquipment}
            handleClose={handleBack}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.error}
            title="Exclusão"
            open={openDeleteConfirmDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            content={
              <>
                Você tem certeza que gostaria de remover este Emprestável?
                <br />
                {`Emprestável: ${name}`}
              </>
            }
          />
        </Box>
      </Box>
    );
  }
};

EquipmentTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default EquipmentTable;
