import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import CreateReservation from "components/atoms/reservation/CreateReservation";
import SwitchConflict from "components/molecules/switch/SwitchConflict";
import SwitchRepeat from "components/molecules/switch/SwitchRepeat";
import SelectActivityType from "components/atoms/select/SelectActivityType";
import SelectActivity from "components/atoms/select/SelectActivity";
import SelectBlock from "components/atoms/select/SelectBlock";
import SelectLocation from "components/atoms/select/SelectLocation";
import SelectUnit from "components/atoms/select/SelectUnit";
import SelectDivisionDiscipline from "components/atoms/select/SelectDivisionDiscipline";
import SelectDiscipline from "components/atoms/select/SelectDiscipline";
import SelectSemester from "components/atoms/select/SelectSemester";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import ButtonTimePicker from "components/atoms/dateTime/ButtonTimePicker";
import {
  getFormattedTimeHour,
  getParseDate,
  getMinTime,
  getMaxTime,
} from "components/atoms/dateTime/GetFormattedDate";

const Fields = (props) => {
  //#region useState, useRef and navigate
  const [load, setLoad] = useState(false);
  const [changeFields, setChangeFields] = useState(false);

  const [checkedConflict, setCheckedConflict] = useState(false);
  const [conflictInternal, setConflictInternal] = useState("manual");
  const [conflictExternal, setConflictExternal] = useState("manual");

  const [checkedRepeat, setCheckedRepeat] = useState(false);
  const [weekDays, setWeekDays] = useState(() => []);
  const [dateRepeatEnd, setDateRepeatEnd] = useState(null);
  const [repeat, setRepeat] = useState("daily");
  const [jump, setJump] = useState(1);

  const [block, setBlock] = useState(null);
  const [location, setLocation] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [errorBlock, setErrorBlock] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);

  const refBlock = useRef(null);
  const refLocation = useRef(null);

  const [disableNewActivity, setDisableNewActivity] = useState(false);
  const [activityRadio, setActivityRadio] = useState("new");
  const [activityType, setActivityType] = useState(null);
  const [idActivity, setIdActivity] = useState(null);
  const [activity, setActivity] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [participants, setParticipants] = useState("");
  const [idRequester, setIdRequester] = useState("");
  const [unit, setUnit] = useState(null);
  const [divisionDiscipline, setDivisionDiscipline] = useState(null);
  const [discipline, setDiscipline] = useState(null);
  const [semester, setSemester] = useState(null);
  const [className, setClassName] = useState("");

  const [errorActivityType, setErrorActivityType] = useState(false);
  const [errorActivity, setErrorActivity] = useState(false);
  const [errorParticipants, setErrorParticipants] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorIdRequester, setErrorIdRequester] = useState(false);
  const [errorUnit, setErrorUnit] = useState(false);
  const [errorDivisionDiscipline, setErrorDivisionDiscipline] = useState(false);
  const [errorDiscipline, setErrorDiscipline] = useState(false);
  const [errorSemester, setErrorSemester] = useState(false);
  const [errorClassName, setErrorClassName] = useState(false);

  const refActivityType = useRef(null);
  const refActivity = useRef(null);
  const refParticipants = useRef(null);
  const refName = useRef(null);
  const refIdRequester = useRef(null);
  const refUnit = useRef(null);
  const refDivisionDiscipline = useRef(null);
  const refDiscipline = useRef(null);
  const refSemester = useRef(null);
  const refClassName = useRef(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    if (props.name === "local") {
      setBlock({ label: props.block.nome, id: props.block.id });
      setLocation({
        label: `${props.location.numero} - ${props.location.nome}`,
        id: props.location.id,
      });
    } else {
      setDisableNewActivity(true);
      setActivityRadio("exist");
      setIdActivity(String(props.activity.id));
      setActivity({
        label: `${props.activity.nome} (${props.activity.id})`,
        id: props.activity.id,
      });
    }
    setDateReservation(getParseDate(props.date));
    setDateBegin(getMinTime(props.hour));
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const cleanLoad = () => {
    if (load) setLoad(false);
  };

  //#region Conflict
  const checkedConflictCallback = (childData) => {
    cleanLoad();
    setCheckedConflict(childData);
  };

  const conflictInternalCallback = (childData) => {
    cleanLoad();
    setConflictInternal(childData);
  };

  const conflictExternalCallback = (childData) => {
    cleanLoad();
    setConflictExternal(childData);
  };
  //#endregion

  //#region Repeat
  const checkedRepeatCallback = (childData) => {
    cleanLoad();
    setCheckedRepeat(childData);
  };

  const weekDaysCallback = (childData) => {
    cleanLoad();
    setWeekDays(childData);
  };

  const dateRepeatEndCallback = (childData) => {
    cleanLoad();
    setDateRepeatEnd(childData);
  };

  const repeatCallback = (childData) => {
    cleanLoad();
    setRepeat(childData);
  };

  const jumpCallback = (childData) => {
    if (load) setLoad(false);

    setJump(childData);
  };
  //#endregion

  //#region Reservation
  const blockCallback = (childData) => {
    cleanLoad();
    if (location) setLocation(null);
    if (errorBlock) setErrorBlock(false);

    setBlock(childData);
  };

  const locationCallback = (childData) => {
    cleanLoad();
    if (errorLocation) setErrorLocation(false);

    setLocation(childData);
  };

  const handleChangeDateReservation = (newValue) => {
    cleanLoad();
    setDateReservation(newValue);
  };

  const handleChangeDateBegin = (newValue) => {
    cleanLoad();
    setDateBegin(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    cleanLoad();
    setDateEnd(newValue);
  };

  const getDateEnd = () => {
    const hour = String(Number(getFormattedTimeHour(dateBegin)) + 1);

    return hour;
  };
  //#endregion

  //#region Activity
  const cleanActivityField = () => {
    if (errorActivity) setErrorActivity(false);

    setActivity(null);
    setIdActivity(null);
  };

  const handleActivityRadioChange = (event) => {
    cleanActivityField();
    setActivityRadio(event.target.value);
  };

  const idRequesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorIdRequester) setErrorIdRequester(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setIdRequester(childData);
  };

  const activityTypeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorActivityType) setErrorActivityType(false);

    if (childData) {
      if (childData.label === "Ensalamento") {
        !changeFields && setChangeFields(true);
      } else {
        changeFields && setChangeFields(false);
      }
    }

    setActivityType(childData);
  };

  const activityCallback = (childData) => {
    cleanLoad();
    if (errorActivity) setErrorActivity(false);

    setActivity(childData);
    setIdActivity(childData.id);
  };

  const unitCallback = (childData) => {
    if (load) setLoad(false);
    if (errorUnit) setErrorUnit(false);

    disciplineCallback(null);
    divisionDisciplineCallback(null);

    setUnit(childData);
  };

  const divisionDisciplineCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDivisionDiscipline) setErrorDivisionDiscipline(false);

    disciplineCallback(null);

    setDivisionDiscipline(childData);
  };

  const disciplineCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDiscipline) setErrorDiscipline(false);

    setDiscipline(childData);
  };

  const semesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorSemester) setErrorSemester(false);

    setSemester(childData);
  };

  const handleChangeParticipants = (event) => {
    cleanLoad();
    if (errorParticipants) setErrorParticipants(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") setParticipants(newValue);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeClassName = (event) => {
    if (load) setLoad(false);
    if (errorClassName) setErrorClassName(false);

    setClassName(event.target.value);
  };
  //#endregion

  const handleClick = () => {
    let errorLoad = false;

    //if activity exist
    if (activityRadio === "exist") {
      if (!activity) {
        errorLoad = true;
        refActivity.current.focus();
        !errorActivity && setErrorActivity(true);
        alert("Por favor, selecione uma atividade.");
      }
    } else {
      // if activity not exist
      if (!idRequester) {
        errorLoad = true;
        refIdRequester.current.focus();
        if (!errorIdRequester) setErrorIdRequester(true);
        alert("Por favor, preencha o CPF do Solicitante.");
      }
      if (!errorLoad && !activityType) {
        errorLoad = true;
        refActivityType.current.focus();
        if (!errorActivityType) setErrorActivityType(true);
        alert("Por favor, preencha um Tipo de Atividade.");
      }
      if (!errorLoad && !participants) {
        errorLoad = true;
        refParticipants.current.focus();
        if (!errorParticipants) setErrorParticipants(true);
        alert("Por favor, preencha o Número de Participantes.");
      }

      //If not "ensalamento" type
      if (!changeFields) {
        if (!errorLoad && !name) {
          errorLoad = true;
          refName.current.focus();
          if (!errorName) setErrorName(true);
          alert("Por favor, preencha o Nome da Atividade.");
        }
      } else {
        //if it is "ensalamento" type
        if (!errorLoad && !className) {
          errorLoad = true;
          refClassName.current.focus();
          if (!errorClassName) setErrorClassName(true);
          alert("Por favor, preencha o nome da Turma.");
        }
        if (!errorLoad && !unit) {
          errorLoad = true;
          refUnit.current.focus();
          if (!errorUnit) setErrorUnit(true);
          alert("Por favor, preencha uma Unidade.");
        }
        if (!errorLoad && !divisionDiscipline) {
          errorLoad = true;
          refDivisionDiscipline.current.focus();
          if (!errorDivisionDiscipline) setErrorDivisionDiscipline(true);
          alert("Por favor, preencha uma Divisão.");
        }
        if (!errorLoad && !discipline) {
          errorLoad = true;
          refDiscipline.current.focus();
          if (!errorDiscipline) setErrorDiscipline(true);
          alert("Por favor, preencha uma Disciplina.");
        }
        if (!errorLoad && !semester) {
          errorLoad = true;
          refSemester.current.focus();
          if (!errorSemester) setErrorSemester(true);
          alert("Por favor, preencha um Semestre.");
        }
      }
    }

    if (!errorLoad && !block) {
      errorLoad = true;
      refBlock.current.focus();
      !errorBlock && setErrorBlock(true);
      alert("Por favor, selecione um bloco.");
    }
    if (!errorLoad && !location) {
      errorLoad = true;
      refLocation.current.focus();
      !errorLocation && setErrorLocation(true);
      alert("Por favor, selecione um local.");
    }
    if (!errorLoad && !dateReservation) {
      errorLoad = true;
      alert("Por favor, selecione uma data.");
    }
    if (!errorLoad && !dateBegin) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Início.");
    }
    if (!errorLoad && !dateEnd) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Fim.");
    }
    if (!errorLoad && dateBegin >= dateEnd) {
      errorLoad = true;
      alert(
        "O horário de início não poder ser igual ou maior que o horário fim."
      );
    }

    if (checkedRepeat) {
      if (!errorLoad && repeat === "weekly" && weekDays.length === 0) {
        errorLoad = true;
        alert("Por favor, selecione pelo menos um dia da semana.");
      }
      if (!errorLoad && !dateRepeatEnd) {
        errorLoad = true;
        alert("Por favor, selecione uma Data final da Repetição.");
      }
      if (!errorLoad && dateReservation >= dateRepeatEnd) {
        errorLoad = true;
        alert(
          "A Data da Reserva não poder ser igual ou maior que a Data final da Repetição."
        );
      }
    }

    if (!errorLoad) {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="controlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box id="boxActivity">
          <FormControl sx={{ ml: 1 }}>
            <RadioGroup
              row
              aria-labelledby="radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={activityRadio}
              onChange={handleActivityRadioChange}
            >
              <FormControlLabel
                disabled={disableNewActivity}
                value="new"
                control={<Radio />}
                label="Nova Atividade"
              />
              <FormControlLabel
                value="exist"
                control={<Radio />}
                label="Atividade Existente"
              />
            </RadioGroup>
          </FormControl>
          {activityRadio === "exist" && (
            <Box id="selectActivityBox">
              <SelectActivity
                dataParentToChild={activity}
                parentCallback={activityCallback}
                ref={refActivity}
                errorParentToChild={errorActivity}
              />
            </Box>
          )}
          {activityRadio === "new" && (
            <Box id="createActivityBox">
              <Box sx={{ mb: 1 }}>
                <VerifyPerson
                  title="Solicitante"
                  dataParentToChild={idRequester}
                  parentCallback={idRequesterCallback}
                  ref={refIdRequester}
                  errorParentToChild={errorIdRequester}
                />
              </Box>
              <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item xs={7} sm={5} lg={3}>
                  <SelectActivityType
                    dataParentToChild={activityType}
                    parentCallback={activityTypeCallback}
                    ref={refActivityType}
                    errorParentToChild={errorActivityType}
                  />
                </Grid>
                <Grid item xs={5} sm={3} lg={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    inputRef={refParticipants}
                    error={errorParticipants}
                    value={participants}
                    onChange={handleChangeParticipants}
                    id="participants"
                    label="Número de Participantes"
                    name="participants"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {!changeFields && (
                    <TextField
                      fullWidth
                      margin="dense"
                      inputProps={{ maxLength: 200 }}
                      inputRef={refName}
                      error={errorName}
                      value={name}
                      onChange={handleChangeName}
                      id="name"
                      label="Nome da Atividade"
                      name="name"
                      autoComplete="off"
                    />
                  )}
                  {changeFields && (
                    <TextField
                      fullWidth
                      margin="dense"
                      inputProps={{ maxLength: 200 }}
                      inputRef={refClassName}
                      error={errorClassName}
                      value={className}
                      onChange={handleChangeClassName}
                      id="className"
                      label="Turma"
                      name="className"
                      autoComplete="off"
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    value={description}
                    onChange={handleChangeDescription}
                    id="description"
                    label="Descrição da Atividade"
                    name="description"
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              {changeFields && (
                <Grid container rowSpacing={0} columnSpacing={2}>
                  <Grid item xs={12} lg={3}>
                    <SelectUnit
                      dataParentToChild={unit}
                      parentCallback={unitCallback}
                      ref={refUnit}
                      errorParentToChild={errorUnit}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <SelectDivisionDiscipline
                      idUnit={unit ? unit.id : 0}
                      dataParentToChild={divisionDiscipline}
                      parentCallback={divisionDisciplineCallback}
                      ref={refDivisionDiscipline}
                      errorParentToChild={errorDivisionDiscipline}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <SelectDiscipline
                      idDivision={
                        divisionDiscipline ? divisionDiscipline.id : 0
                      }
                      dataParentToChild={discipline}
                      parentCallback={disciplineCallback}
                      ref={refDiscipline}
                      errorParentToChild={errorDiscipline}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <SelectSemester
                      dataParentToChild={semester}
                      parentCallback={semesterCallback}
                      ref={refSemester}
                      errorParentToChild={errorSemester}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </Box>
        <Box id="boxReservation">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <SelectBlock
                dataParentToChild={block}
                parentCallback={blockCallback}
                ref={refBlock}
                errorParentToChild={errorBlock}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectLocation
                idBlock={block ? block.id : 0}
                dataParentToChild={location}
                parentCallback={locationCallback}
                ref={refLocation}
                errorParentToChild={errorLocation}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={2}>
              <ButtonDatePicker
                value={dateReservation}
                onChange={handleChangeDateReservation}
                id="dateReservation"
                label="Data da Reserva"
                name="dateReservation"
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <ButtonTimePicker
                id={"dateBegin"}
                views={["hours"]}
                skipDisabled
                minTime={getMinTime("07")}
                maxTime={getMaxTime("21")}
                value={dateBegin}
                label={
                  dateBegin == null
                    ? "Hora Início"
                    : `Hora Início: ${getFormattedTimeHour(dateBegin)}`
                }
                onChange={handleChangeDateBegin}
              />
            </Grid>
            <Grid item xs={6} sm={3} lg={2}>
              <ButtonTimePicker
                disable
                id={"dateEnd"}
                views={["hours"]}
                disabled={!dateBegin}
                skipDisabled
                minTime={dateBegin && getMinTime(getDateEnd())}
                maxTime={getMaxTime("22")}
                value={dateEnd}
                label={
                  dateEnd == null
                    ? "Hora Fim"
                    : `Hora Fim: ${getFormattedTimeHour(dateEnd)}`
                }
                onChange={handleChangeDateEnd}
              />
            </Grid>
          </Grid>
        </Box>
        <Box id="boxConfig" sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} lg={6}>
              <SwitchRepeat
                checkedParentToChild={checkedRepeat}
                weekDaysParentToChild={weekDays}
                repeatParentToChild={repeat}
                jumpParentToChild={jump}
                dateRepeatEndParentToChild={dateRepeatEnd}
                dateReservationParentToChild={dateReservation}
                checkedParentCallback={checkedRepeatCallback}
                weekDaysParentCallback={weekDaysCallback}
                repeatParentCallback={repeatCallback}
                jumpParentCallback={jumpCallback}
                dateRepeatEndParentCallback={dateRepeatEndCallback}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <SwitchConflict
                checkedParentToChild={checkedConflict}
                internalParentToChild={conflictInternal}
                externalParentToChild={conflictExternal}
                checkedParentCallback={checkedConflictCallback}
                conflictInternalParentCallback={conflictInternalCallback}
                conflictExternalParentCallback={conflictExternalCallback}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateReservation
          idActivity={idActivity}
          activityName={name ? name : activity && activity.label}
          jump={jump}
          checkedRepeat={checkedRepeat}
          weekDays={weekDays}
          repeat={repeat}
          dateRepeatEnd={dateRepeatEnd}
          conflictInternal={conflictInternal}
          conflictExternal={conflictExternal}
          location={location}
          block={block}
          dateReservation={dateReservation}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          activityType={activityType}
          name={name}
          description={description}
          participants={participants}
          idRequester={idRequester}
          discipline={discipline}
          semester={semester}
          className={className}
          load={load}
        />
      </Box>
    </>
  );
};

const GetBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.location) {
      const fullURL = `bloco/${props.location.idBloco}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} block={items} />;
};

const GetLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `local/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetBlock {...props} location={items} />;
};

const GetActivity = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = "atividade/" + props.id;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} activity={items} />;
};

const ReservationNew = (props) => {
  const title = props.name === "local" ? "este Local" : "esta Atividade";
  return (
    <PageContainer
      maxWidth="lg"
      title={`Nova Reserva para ${title}`}
      icon={<AddBoxIcon />}
    >
      {props.name === "local" ? (
        <GetLocation {...props} />
      ) : (
        <GetActivity {...props} />
      )}
    </PageContainer>
  );
};

export default ReservationNew;
