import React from "react";
import { useParams } from "react-router-dom";

import PersonPage from "components/pages/UserPerson/Person";

const Person = () => {
  const { userId } = useParams();

  return <PersonPage userId={userId} />;
};

export default Person;
