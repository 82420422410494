import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ChairAltIcon from "@mui/icons-material/ChairAlt";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import ResourceTable from "components/molecules/table/ResourceTable";
import EquipmentTable from "components/molecules/table/EquipmentTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import TabPanel from "components/atoms/table/TabPanel";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const ResourceTableList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("recurso")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhum recurso cadastrado.
        </Typography>
      );
    } else {
      return <ResourceTable rows={items} />;
    }
  }
};

const EquipmentTableList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const handleReloadTable = (event) => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("emprestavel")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, reloadTable, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhum emprestável cadastrado.
        </Typography>
      );
    } else {
      return (
        <EquipmentTable rows={items} handleReloadTable={handleReloadTable} />
      );
    }
  }
};

const Fields = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const navigate = useNavigate();
  const userRole = GetUserRole();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleClickNewResource = () => {
    navigate("/recurso-novo");
  };

  const handleClickNewEquipment = () => {
    navigate("/emprestavel-novo");
  };

  return (
    <>
      <Box id="boxData" sx={{ mt: 2 }}>
        <Tabs value={currentTab} onChange={handleChangeTab}>
          <Tab label={"Recursos fixos do Local"} />
          <Tab label={"Recursos para pegar na portaria"} />
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          {userRole > 5 && (
            <Box id="boxData">
              <InfoTooltip title="Novo recurso" placement="left">
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="novo recurso fixo"
                  onClick={handleClickNewResource}
                >
                  Novo recurso fixo do Local
                </Button>
              </InfoTooltip>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <ResourceTableList />
          </Box>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {userRole > 5 && (
            <Box id="boxData">
              <InfoTooltip title="Novo emprestável" placement="left">
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="novo recurso portaria"
                  onClick={handleClickNewEquipment}
                >
                  Novo recurso para pegar na portaria
                </Button>
              </InfoTooltip>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <EquipmentTableList />
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

const ResourceList = () => {
  return (
    <PageContainer maxWidth="lg" title={"Recursos"} icon={<ChairAltIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default ResourceList;
