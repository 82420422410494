import React, { useState, useRef } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import SelectOccupant from "components/atoms/select/SelectOccupant";
import LoanTable from "components/molecules/table/LoanTable";
import EquipmentLoanTable from "components/molecules/table/EquipmentLoanTable";

const LocationLoan = (props) => {
  const [description, setDescription] = useState("");
  const [occupant, setOccupant] = useState(null);
  const [errorOccupant, setErrorOccupant] = useState(false);
  const refOccupant = useRef(null);

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const occupantCallback = (childData) => {
    if (errorOccupant) setErrorOccupant(false);

    setOccupant(childData);
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <EquipmentLoanTable
          idLocation={props.idLocation}
          return={true}
          load={true}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectOccupant
              idLocation={props.idLocation}
              dataParentToChild={occupant}
              parentCallback={occupantCallback}
              ref={refOccupant}
              errorParentToChild={errorOccupant}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="descricao"
              label="Descrição"
              name="descricao"
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <LoanTable
          idLocation={props.idLocation}
          occupant={occupant}
          description={description}
        />
      </Box>
    </>
  );
};

export default LocationLoan;
