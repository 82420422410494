import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import { ActivityTypeContext } from "context/CommonProvider";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import SelectUnit from "components/atoms/select/SelectUnit";
import SelectDivisionDiscipline from "components/atoms/select/SelectDivisionDiscipline";
import SelectDiscipline from "components/atoms/select/SelectDiscipline";
import SelectSemester from "components/atoms/select/SelectSemester";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ModifyActivity = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);
      const fullURL = `atividade/${props.idActivity}`;

      let request = null;
      if (props.activityType === "Ensalamento") {
        request = {
          idDisciplina: props.discipline.id,
          turma: props.className,
          semestre: props.semester.label,
          descricao: props.description,
          userIdSolicitante: props.idRequester,
          participantes: props.participants,
        };
      } else {
        request = {
          nome: props.name,
          descricao: props.description,
          userIdSolicitante: props.idRequester,
          participantes: props.participants,
        };
      }

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível atualizar esta atividade",
              "error"
            );
          } else {
            snackContext.setMessage(`Atualização salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const [load, setLoad] = useState(false);

  const [idActivity, setIdActivity] = useState("");
  const [activityType, setActivityType] = useState(null);
  const [name, setName] = useState("");
  const [idRequester, setIdRequester] = useState("");
  const [description, setDescription] = useState("");
  const [participants, setParticipants] = useState("");
  const [unit, setUnit] = useState(null);
  const [divisionDepartment, setDivisionDepartment] = useState(null);
  const [discipline, setDiscipline] = useState(null);
  const [semester, setSemester] = useState(null);
  const [className, setClassName] = useState("");

  const [errorParticipants, setErrorParticipants] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorIdRequester, setErrorIdRequester] = useState(false);
  const [errorUnit, setErrorUnit] = useState(false);
  const [errorDivisionDepartment, setErrorDivisionDepartment] = useState(false);
  const [errorDiscipline, setErrorDiscipline] = useState(false);
  const [errorSemester, setErrorSemester] = useState(false);
  const [errorClassName, setErrorClassName] = useState(false);

  const refParticipants = useRef(null);
  const refName = useRef(null);
  const refIdRequester = useRef(null);
  const refUnit = useRef(null);
  const refDivisionDepartment = useRef(null);
  const refDiscipline = useRef(null);
  const refSemester = useRef(null);
  const refClassName = useRef(null);

  const navigate = useNavigate();
  const activityTypeContext = useContext(ActivityTypeContext);

  useEffect(() => {
    const items = props.items;
    const idActivityType = items.idAtividadeTipo;
    const activityType = activityTypeContext[idActivityType];

    setIdActivity(String(items.id));
    setActivityType(activityType);
    setName(String(items.nome));
    setIdRequester(String(items.userIdSolicitante));
    setDescription(String(items.descricao));
    setParticipants(String(items.participantes));

    if (idActivityType === 2) {
      const subclass = items.subclass;
      const sUnit = {
        id: subclass.idUnidade,
        label: subclass.unidade,
      };
      const sDivision = {
        id: subclass.idDivisao,
        label: subclass.divisao,
      };
      const sDiscipline = {
        id: subclass.idDisciplina,
        label: subclass.disciplina,
      };
      const sSemester = {
        id: subclass.semestre,
        label: subclass.semestre,
      };
      setClassName(subclass.turma);
      setUnit(sUnit);
      setDivisionDepartment(sDivision);
      setDiscipline(sDiscipline);
      setSemester(sSemester);
    }
  }, [props, activityTypeContext]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = () => {
    handleBack();
  };

  const idRequesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorIdRequester) setErrorIdRequester(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setIdRequester(childData);
  };

  const handleChangeParticipants = (event) => {
    if (load) setLoad(false);
    if (errorParticipants) setErrorParticipants(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") setParticipants(newValue);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeClassName = (event) => {
    if (load) setLoad(false);
    if (errorClassName) setErrorClassName(false);

    setClassName(event.target.value);
  };

  const unitCallback = (childData) => {
    if (load) setLoad(false);
    if (errorUnit) setErrorUnit(false);

    disciplineCallback(null);
    divisionDepartmentCallback(null);

    setUnit(childData);
  };

  const divisionDepartmentCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDivisionDepartment) setErrorDivisionDepartment(false);

    disciplineCallback(null);

    setDivisionDepartment(childData);
  };

  const disciplineCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDiscipline) setErrorDiscipline(false);

    setDiscipline(childData);
  };

  const semesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorSemester) setErrorSemester(false);

    setSemester(childData);
  };

  const handleClick = () => {
    if (!idRequester) {
      refIdRequester.current.focus();
      if (!errorIdRequester) setErrorIdRequester(true);
      alert("Por favor, preencha o CPF do Solicitante.");
    } else if (!participants) {
      refParticipants.current.focus();
      if (!errorParticipants) setErrorParticipants(true);
      alert("Por favor, preencha os Participantes.");
    } else if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (activityType === "Ensalamento") {
      if (!className) {
        refClassName.current.focus();
        if (!errorClassName) setErrorClassName(true);
        alert("Por favor, preencha o nome da Turma.");
      } else if (!unit) {
        refUnit.current.focus();
        if (!errorUnit) setErrorUnit(true);
        alert("Por favor, preencha uma Unidade.");
      } else if (!divisionDepartment) {
        refDivisionDepartment.current.focus();
        if (!errorDivisionDepartment) setErrorDivisionDepartment(true);
        alert("Por favor, preencha uma Divisão.");
      } else if (!discipline) {
        refDiscipline.current.focus();
        if (!errorDiscipline) setErrorDiscipline(true);
        alert("Por favor, preencha uma Disciplina.");
      } else if (!semester) {
        refSemester.current.focus();
        if (!errorSemester) setErrorSemester(true);
        alert("Por favor, preencha um Semestre.");
      } else {
        setLoad(true);
      }
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Typography sx={{ m: 1 }}>
          <strong>Tipo de Atividade:</strong> {String(activityType)}
        </Typography>
        {activityType === "Ensalamento" && (
          <Typography sx={{ m: 1 }}>
            <strong>Nome da Atividade:</strong> {String(name)}
          </Typography>
        )}
        <Box sx={{ mb: 1 }}>
          <VerifyPerson
            title="Solicitante"
            dataParentToChild={idRequester}
            parentCallback={idRequesterCallback}
            ref={refIdRequester}
            errorParentToChild={errorIdRequester}
          />
        </Box>
        <Grid container rowSpacing={0} columnSpacing={2}>
          {activityType !== "Ensalamento" && (
            <Grid item xs={9} md={12} lg={10}>
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                inputRef={refName}
                error={errorName}
                value={name}
                onChange={handleChangeName}
                id="name"
                label="Nome da Atividade"
                name="name"
                autoComplete="off"
              />
            </Grid>
          )}
          <Grid item xs={3} md={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refParticipants}
              error={errorParticipants}
              value={participants}
              onChange={handleChangeParticipants}
              id="participants"
              label="Participantes"
              name="participants"
              autoComplete="off"
            />
          </Grid>
          {activityType === "Ensalamento" && (
            <Grid item xs={12} lg={2}>
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                inputRef={refClassName}
                error={errorClassName}
                value={className}
                onChange={handleChangeClassName}
                id="className"
                label="Turma"
                name="className"
                autoComplete="off"
              />
            </Grid>
          )}
          <Grid item xs={12} lg={activityType === "Ensalamento" ? 8 : 12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição da Atividade"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
        {activityType === "Ensalamento" && (
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} lg={3}>
              <SelectUnit
                dataParentToChild={unit}
                parentCallback={unitCallback}
                ref={refUnit}
                errorParentToChild={errorUnit}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectDivisionDiscipline
                idUnit={unit ? unit.id : 0}
                dataParentToChild={divisionDepartment}
                parentCallback={divisionDepartmentCallback}
                ref={refDivisionDepartment}
                errorParentToChild={errorDivisionDepartment}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectDiscipline
                idDivision={divisionDepartment ? divisionDepartment.id : 0}
                dataParentToChild={discipline}
                parentCallback={disciplineCallback}
                ref={refDiscipline}
                errorParentToChild={errorDiscipline}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectSemester
                dataParentToChild={semester}
                parentCallback={semesterCallback}
                ref={refSemester}
                errorParentToChild={errorSemester}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyActivity
          idActivity={idActivity}
          activityType={activityType}
          name={name}
          idRequester={idRequester}
          description={description}
          participants={participants}
          discipline={discipline}
          semester={semester}
          className={className}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const ActivityItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `atividade/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          setItems(result);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const ActivityUpdate = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Atualizar Atividade"}
      icon={<EditIcon />}
    >
      <ActivityItems {...props} />
    </PageContainer>
  );
};

export default ActivityUpdate;
