import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectEquipment = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [errorEquipment, setErrorEquipment] = useState(null);

  const handleChange = (event, newValue) => {
    setEquipment(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setEquipment(props.dataParentToChild);
    setErrorEquipment(props.errorParentToChild);

    instance
      .get("emprestavel")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const equipments = [];
        result.map((item) =>
          equipments.push({ label: item.nome, id: item.id })
        );
        setItems(sortListByLabel(equipments));
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        setError(msg);
      });
  }, [props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        value={equipment}
        onChange={handleChange}
        disablePortal
        id="equipment"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorEquipment}
            label="Emprestável"
          />
        )}
      />
    );
  }
});

export default SelectEquipment;
