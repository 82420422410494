import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";

import PaginationActions from "components/atoms/table/PaginationActions";

const Pagination = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.pageCallback(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPage);
    props.rowsPerPageCallback(rowsPage);
    setPage(0);
    props.pageCallback(0);
  };

  useEffect(() => {
    setPage(props.pageParentToChild);
    setRowsPerPage(props.rowsPerPageParentToChild);
  }, [props.pageParentToChild, props.rowsPerPageParentToChild]);

  return (
    <Table sx={{ display: "inline", alignItems: "left" }}>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[50, { label: "Todos", value: -1 }]}
            colSpan={3}
            count={props.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={`${props.name} por página:`}
            labelDisplayedRows={function defaultLabelDisplayedRows({
              from,
              to,
              count,
            }) {
              return `${from}–${to} de ${
                count !== -1 ? count : `mais do que ${to}`
              }`;
            }}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={PaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

Pagination.defaultProps = {
  length: 0,
  pageParentToChild: 0,
  rowsPerPageParentToChild: 0,
  pageCallback: () => {},
  rowsPerPageCallback: () => {},
};

Pagination.propTypes = {
  name: PropTypes.string.isRequired,
  length: PropTypes.number,
  pageParentToChild: PropTypes.number,
  rowsPerPageParentToChild: PropTypes.number,
  pageCallback: PropTypes.func,
  rowsPerPageCallback: PropTypes.func,
};

export default Pagination;
