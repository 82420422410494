import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import LogoutIcon from "@mui/icons-material/Logout";

const MenuAccount = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["username"]);

  const navigate = useNavigate();

  const username = cookies.username ? cookies.username : "Nome não encontrado";
  const photo = localStorage.getItem("photo");

  const GetPhoto = useCallback(
    (props) => {
      return (
        <Avatar
          src={photo}
          alt={username}
          sx={{ width: props.profile && 56, height: props.profile && 56 }}
        />
      );
    },
    [photo, username]
  );

  const handleClickLogin = () => {
    navigate("/login");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        onClick={handleClick}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{
          mr: 2,
          width: 40,
          height: 40,
          "&:hover": {
            color: "primary.main",
            backgroundColor: "white",
          },
        }}
      >
        <GetPhoto />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GetPhoto profile />
        </Box>
        <Typography sx={{ m: 2, textAlign: "center" }}>{username}</Typography>
        <MenuItem sx={{ m: 1 }} onClick={handleClickLogin}>
          <LogoutIcon />
          <Typography sx={{ ml: 1 }}> Sair</Typography>
        </MenuItem>
      </Popover>
    </div>
  );
};

export default MenuAccount;
