import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LinearProgress from "@mui/material/LinearProgress";
import Zoom from "@mui/material/Zoom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";

import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PersonIcon from "@mui/icons-material/Person";

import InfoTooltip from "components/atoms/info/InfoTooltip";
import HelpTooltip from "components/atoms/info/HelpTooltip";
import PageContainer from "components/templates/PageContainer";

import {
  getParseDate,
  getDisplayDate,
  getDisplayTimeHour,
  getFormattedTimeHour,
  getFormattedDateURL,
  getFormattedTime,
  getDisplayDateWeekTime,
} from "components/atoms/dateTime/GetFormattedDate";

import instance from "api/definitions";

const getReservationSuccess = (newResultReservations, conflictInternal) => {
  let reservations = [];
  let key = 0;
  newResultReservations.forEach((element) => {
    let display = null;

    if (element.performed) {
      display = (
        <ListItem disablePadding key={key++}>
          <ListItemIcon>
            <DoneIcon color="success" />
          </ListItemIcon>
          <ListItemText
            primary={getDisplayDateWeekTime(
              element.reserva.inicio,
              element.reserva.fim
            )}
          />
        </ListItem>
      );
    } else if (conflictInternal !== "manual") {
      if (element.conflict) {
        const internal = element.conflict.internal.length;
        const external = element.conflict.external.length;

        if (internal) {
          display = (
            <ListItem disablePadding key={key++}>
              <ListItemIcon>
                <CloseIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={getDisplayDateWeekTime(
                  element.reserva.inicio,
                  element.reserva.fim
                )}
              />
            </ListItem>
          );
        }
        if (external) {
          display = (
            <ListItem disablePadding key={key++}>
              <ListItemIcon>
                <CloseIcon color="error" />
              </ListItemIcon>
              <ListItemText
                primary={getDisplayDateWeekTime(
                  element.reserva.inicio,
                  element.reserva.fim
                )}
              />
            </ListItem>
          );
        }
      } else {
        display = (
          <ListItem disablePadding key={key++}>
            <ListItemIcon>
              <CloseIcon color="error" />
            </ListItemIcon>
            <ListItemText
              primary={getDisplayDateWeekTime(
                element.reserva.inicio,
                element.reserva.fim
              )}
            />
          </ListItem>
        );
      }
    } else {
      display = (
        <ListItem disablePadding key={key++}>
          <ListItemIcon>
            <CloseIcon color="error" />
          </ListItemIcon>
          <ListItemText
            primary={getDisplayDateWeekTime(
              element.reserva.inicio,
              element.reserva.fim
            )}
          />
          {element.msg && <ListItemText secondary={`. ${element.msg}`} />}
          {element.conflict && (
            <ListItemText secondary={`. Conflito encontrado.`} />
          )}
        </ListItem>
      );
    }
    reservations.push(display);
  });
  if (key === 0) {
    const display = (
      <ListItem disablePadding key={key++}>
        <ListItemText primary={"Nenhuma reserva foi cadastrada."} />
      </ListItem>
    );
    reservations.push(display);
  }

  return reservations;
};

const getActivityConflictDisplay = (element) => {
  let locationName = element.local;
  locationName = locationName
    ? `${element.local} (${element.numero}) - ${element.bloco}`
    : "Sem local";
  return (
    <>
      <Box sx={{ display: "flex", mb: 1 }}>
        <AssignmentIcon />
        <Typography
          sx={{ ml: 1 }}
        >{`${element.atividade} (${element.idAtividade})`}</Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <AccountBalanceIcon />
        <Typography sx={{ ml: 1 }}>{locationName}</Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <EventIcon />
        <Typography
          sx={{ ml: 1 }}
        >{`${getDisplayDate(element.inicio)}`}</Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <AccessTimeIcon />
        <Typography sx={{ ml: 1 }}>
          {`${getDisplayTimeHour(element.inicio)}h a ${getDisplayTimeHour(element.fim)}h`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: 1 }}>
        <PersonIcon />
        <Typography sx={{ ml: 1 }}>{element.solicitante}</Typography>
      </Box>
    </>
  );
};

const getReservationConflict = (newResultReservations) => {
  let reservations = [];
  newResultReservations.forEach((element) => {
    if (element.conflict) {
      const internal = element.conflict.internal.length;
      const external = element.conflict.external.length;

      let reason = [];
      if (external) {
        element.conflict.external.forEach((element) => {
          const conflictDisplay = (
            <Box sx={{ p: 2, color: "white", bgcolor: "error.main" }}>
              {getActivityConflictDisplay(element)}
            </Box>
          );
          reason.push(conflictDisplay);
        });
      }
      if (internal) {
        element.conflict.internal.forEach((element) => {
          const conflictDisplay = (
            <Box sx={{ p: 2, color: "white", bgcolor: "warning.main" }}>
              {getActivityConflictDisplay(element)}
            </Box>
          );
          reason.push(conflictDisplay);
        });
      }
      if (internal > 0 || external > 0) {
        reservations.push({
          idReservation: String(element.reserva.idReserva),
          display: getDisplayDateWeekTime(
            element.reserva.inicio,
            element.reserva.fim
          ),
          date: getParseDate(element.reserva.inicio),
          reason: reason,
        });
      }
    }
  });
  return reservations;
};

const getReservationConflictText = (reservationConflict) => {
  if (reservationConflict.length > 0) {
    const element = reservationConflict[0];
    let index = 0;
    const reasonItem = element.reason.map((item) => {
      return (
        <Box key={index++} sx={{ mb: 2 }}>
          {item}
        </Box>
      );
    });

    return <Box>{reasonItem}</Box>;
  }
};

const getReservationLogText = (reservationConflict) => {
  let text = [];
  let key = 0;
  if (reservationConflict.length > 0) {
    reservationConflict.forEach((element) => {
      if (element[0]) {
        const done = element[1];
        const display = (
          <ListItem disablePadding key={key++}>
            <ListItemIcon>
              {done ? (
                <DoneIcon color="success" />
              ) : (
                <CloseIcon color="error" />
              )}
            </ListItemIcon>
            <ListItemText primary={element[0].display} />
          </ListItem>
        );
        text.push(display);
      }
    });
  }
  return text;
};

const ReservationConflict = () => {
  const [from, setFrom] = useState("");
  const [checked, setChecked] = useState(true);
  const [finish, setFinish] = useState(false);

  const [idActivity, setIdActivity] = useState(null);
  const [activityName, setActivityName] = useState(null);
  const [block, setBlock] = useState(null);
  const [location, setLocation] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [hourBegin, setHourBegin] = useState(null);
  const [hourEnd, setHourEnd] = useState(null);
  const [dateReservationConflict, setDateReservationConflict] = useState(null);
  const [dateReservationLog, setDateReservationLog] = useState([]);

  const [conflictTurn, setConflictTurn] = useState(1);
  const [conflictTotal, setConflictTotal] = useState(0);

  const [performed, setPerformed] = useState(null);
  const [notPerformed, setNotPerformed] = useState(null);
  const [logPerformed, setLogPerformed] = useState(null);

  let { state } = useLocation();
  const navigate = useNavigate();

  const handleFinish = useCallback(() => {
    navigate(-2);
  }, [navigate]);

  useEffect(() => {
    const props = state.props;
    const stateFrom = state.from;
    const stateItems = state.items;
    if (props && stateFrom && stateItems) {
      setFrom(String(stateFrom));
      if (
        props.conflictInternal === "ignore" ||
        props.conflictInternal === "abort" ||
        props.conflictInternal === "overwrite"
      ) {
        setFinish(true);
      }
      if (!props.idActivity) {
        setFinish(true);
      }
      setIdActivity(String(props.idActivity));
      setActivityName(props.activityName);
      setBlock(props.block);
      setLocation(props.location);
      setDateBegin(props.dateBegin);
      setDateEnd(props.dateEnd);
      setHourBegin(getFormattedTimeHour(props.dateBegin));
      setHourEnd(getFormattedTimeHour(props.dateEnd));

      const reservationConflict = getReservationConflict(stateItems);
      if (reservationConflict.length > 0) {
        setDateReservationConflict(reservationConflict);
        setDateReservation(reservationConflict[0]);
        setConflictTotal(reservationConflict.length);
      } else {
        setFinish(true);
      }

      const performedText = getReservationSuccess(
        stateItems,
        props.conflictInternal
      );
      setPerformed(performedText);
      const notPerformedText = getReservationConflictText(reservationConflict);
      setNotPerformed(notPerformedText);
    } else {
      handleFinish();
    }
  }, [state, handleFinish]);

  const handleConflictTurn = useCallback(() => {
    let turn = conflictTurn;
    ++turn;
    setConflictTurn(turn);
  }, [conflictTurn]);

  const handleClose = useCallback(
    (done) => {
      handleConflictTurn();
      const reservationLess = dateReservationConflict;
      const dateShift = reservationLess.shift();
      setDateReservationConflict(reservationLess);

      const returnDone = done === 1 ? true : false;
      let dateLog = dateReservationLog;
      dateLog.push([dateShift, returnDone]);
      setDateReservationLog(dateLog);
      const logText = getReservationLogText(dateLog);
      setLogPerformed(logText);

      const notPerformedText = getReservationConflictText(reservationLess);
      setNotPerformed(notPerformedText);
      if (reservationLess.length > 0) {
        setDateReservation(reservationLess[0]);
      } else {
        setFinish(true);
      }
    },
    [dateReservationConflict, dateReservationLog, handleConflictTurn]
  );

  const getRequest = useCallback(() => {
    const dateReservationFormatted = getFormattedDateURL(dateReservation.date);
    const timeBeginFormatted = getFormattedTime(dateBegin);
    const timeEndFormatted = getFormattedTime(dateEnd);
    const dateBeginFormatted =
      dateReservationFormatted + " " + timeBeginFormatted;
    const dateEndFormatted = dateReservationFormatted + " " + timeEndFormatted;

    const request = {
      idLocal: location.id,
      inicio: dateBeginFormatted,
      fim: dateEndFormatted,
      conflict: {
        internal: "overwrite",
        external: "overwrite",
      },
    };

    return request;
  }, [dateReservation, dateBegin, dateEnd, location]);

  const createReservationOne = useCallback(async () => {
    const fullURL = `atividade/${idActivity}/reserva`;
    const request = getRequest();

    instance
      .post(fullURL, request)
      .then(function (response) {
        handleClose(1);
      })
      .catch(function (error) {
        handleClose(0);
      });
  }, [idActivity, handleClose, getRequest]);

  const updateReservationOne = useCallback(async () => {
    const fullURL = `reserva/${dateReservation.idReservation}`;
    const request = getRequest();

    instance
      .put(fullURL, request)
      .then(function (response) {
        handleClose(1);
      })
      .catch(function (error) {
        handleClose(0);
      });
  }, [dateReservation, handleClose, getRequest]);

  const handleZoom = () => {
    setChecked(false);
    setTimeout(() => {
      setChecked(true);
    }, 500);
  };

  const handleClick = (event) => {
    handleZoom();
    if (event.target.id === "buttonIgnore") {
      handleClose();
    } else {
      if (dateReservationConflict.length > 0) {
        if (from === "create") createReservationOne();
        else updateReservationOne();
      }
    }
  };

  return (
    <PageContainer
      maxWidth="lg"
      title={from === "create" ? "Novas reservas" : "Atualização de reservas"}
      icon={<AddBoxIcon />}
    >
      <Box id="controlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleFinish}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Zoom in={checked}>
        <Box sx={{ display: finish && "none", textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Resolução de conflitos ({`${conflictTurn}/${conflictTotal}`})
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(conflictTurn / conflictTotal) * 100}
            sx={{ mb: 1 }}
          />
        </Box>
      </Zoom>

      <Box sx={{ display: finish && "none", mt: 2 }}>
        <Zoom in={checked}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item lg={6}>
              <Box sx={{ mt: 1, mb: 2, textAlign: "center" }}>
                <HelpTooltip placement="top-start">
                  <List>
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ color: "primary.main" }}>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Reserva a ser salva"
                        secondary="Reserva que para ser salva precisa substituir as reservas existentes que estão em conflito."
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ color: "primary.main" }}>
                        <CheckBoxOutlineBlankIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="IGNORAR ESTA RESERVA"
                        secondary="Não salva esta reserva e mantém as reservas existentes."
                      />
                    </ListItem>
                  </List>
                </HelpTooltip>
                <Button
                  id="buttonIgnore"
                  onClick={handleClick}
                  type="submit"
                  variant="outlined"
                >
                  Ignorar esta Reserva
                </Button>
              </Box>
              <Box sx={{ p: 2, color: "white", bgcolor: "primary.main" }}>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AssignmentIcon />
                  {idActivity ? (
                    <Typography sx={{ ml: 1 }}>{activityName}</Typography>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ ml: 1, color: "error.main" }}
                    >{` A atividade - ${activityName} - não foi cadastrada.`}</Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccountBalanceIcon />
                  <Typography sx={{ ml: 1 }}>
                    {`${location && location.label} - ${block && block.label}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <EventIcon />
                  <Typography sx={{ ml: 1 }}>
                    {dateReservationConflict &&
                      dateReservationConflict.length > 0 &&
                      `${dateReservationConflict[0].display} `}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccessTimeIcon />
                  <Typography sx={{ ml: 1 }}>
                    {`${hourBegin}h a ${hourEnd}h`}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6}>
              <Box sx={{ mt: 1, mb: 2, textAlign: "center" }}>
                <HelpTooltip placement="top-end">
                  <List>
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ color: "error.main" }}>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Conflito externo"
                        secondary="Já existe reserva(s) de outra atividade neste local e horário."
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon sx={{ color: "warning.main" }}>
                        <AssignmentIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Conflito interno"
                        secondary="Já existe reserva(s) desta atividade neste mesmo horário."
                      />
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemIcon>
                        <CheckBoxOutlineBlankIcon
                          sx={{ color: "error.main" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="SUBSTITUIR RESERVA(S) EXISTENTE(S)"
                        secondary="Salva a reserva, porém apaga a(s) reserva(s) existente(s)."
                      />
                    </ListItem>
                  </List>
                </HelpTooltip>
                <Button
                  id="buttonOver"
                  onClick={handleClick}
                  variant="outlined"
                  type="submit"
                  sx={{
                    mr: 2,
                    color: "error.main",
                    borderColor: "error.main",
                    "&:hover": {
                      color: "error.dark",
                      borderColor: "error.dark",
                    },
                  }}
                >
                  Substituir Reserva(s) Existente(s)
                </Button>
              </Box>
              <Box>{notPerformed}</Box>
            </Grid>
          </Grid>
        </Zoom>
      </Box>
      <Box
        sx={{
          display: finish ? "flex" : "none",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ m: 4 }}>
          <Box sx={{ border: 1, p: 2 }}>
            <Typography variant="h6">Reservas</Typography>
            <List>{performed}</List>
          </Box>

          <Box
            sx={{
              display: !logPerformed && "none",
              border: 1,
              p: 2,
            }}
          >
            <Typography variant="h6">
              Após gerenciamento manual de conflitos
            </Typography>
            <List>{logPerformed}</List>
          </Box>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ReservationConflict;
