import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";
import Paper from "@mui/material/Paper";

const DisciplineTable = (props) => {
  const navigate = useNavigate();

  const rows = props.rows;
  const rowsLength = rows.length;

  const handleClickView = (event) => {
    navigate(`/disciplina/${event.currentTarget.id}`);
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro ao carregar a tabela
      </Typography>
    );
  } else {
    const cells = rows.map((row) => (
      <StyledTableRow
        key={row.id}
        id={row.id}
        onClick={handleClickView}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <StyledTableCell>{row.nome}</StyledTableCell>
        <StyledTableCell>{row.codigo}</StyledTableCell>
        <StyledTableCell>{row.cargaHoraria}</StyledTableCell>
      </StyledTableRow>
    ));
    return (
      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="data table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Disciplina</StyledTableCell>
                <StyledTableCell>Código</StyledTableCell>
                <StyledTableCell>CH</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
};

DisciplineTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default DisciplineTable;
