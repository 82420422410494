import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import ReservationTable from "components/molecules/table/ReservationTable";

import {
  formatReservation,
  getFormattedDateURL,
} from "components/atoms/dateTime/GetFormattedDate";

const BlockReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const doReloadTable = () => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setIsLoaded(false);
    if (props.id) {
      const dateReservation = props.dateReservation;
      let dateReservationURL = "";

      if (dateReservation && String(dateReservation) !== "Invalid Date") {
        dateReservationURL = "/" + getFormattedDateURL(dateReservation);
      }
      const fullURL = `bloco/${props.id}/reservas${dateReservationURL}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (result) {
            const resultIndexes = Object.keys(result);
            let tableRow = [];

            resultIndexes.forEach((resultRow) => {
              const resultValues = Object.values(result[resultRow]);

              resultValues.forEach((resultValue) => {
                let cellsRow = [];
                let cellsIndex = [];
                let cells = [];

                resultValue.reservas.forEach((reservation) => {
                  const reservationFormatted = formatReservation(reservation);
                  cellsIndex.push(reservationFormatted[0]);
                  cells[reservationFormatted[0]] = {
                    idReservation: reservation.id,
                    requester: reservation.solicitante,
                    blockLocation: reservation.blocoLocal,
                    idActivity: reservation.idAtividade,
                    idActivityType: reservation.idAtividadeTipo,
                    activityName: reservation.atividade,
                    participants: reservation.participantes,
                    colSpan: reservationFormatted[1],
                    intervalHour: reservationFormatted[2],
                    intervalDate: reservationFormatted[3],
                  };
                });

                for (let index = 7; index < 22; index++) {
                  if (cellsIndex.includes(index)) {
                    cellsRow.push({
                      key: index,
                      index: index,
                      resultValueId: resultValue.id,
                      activity: cells[index],
                    });
                    const gap = cells[index].colSpan - 1;
                    if (gap > 0) index += gap;
                  } else {
                    cellsRow.push({
                      key: index,
                      id: `${resultValue.id}&${index}`,
                    });
                  }
                }

                tableRow.push({
                  nome: resultValue.nome,
                  numero: resultValue.numero,
                  reservas: cellsRow,
                  id: resultValue.id,
                });
              });
            });
            setItems(tableRow);
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          let msg = getErrorMessage(error);
          if (msg === "Erro 404. ") msg = "Bloco não encontrado.";
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext, reloadTable]);

  if (props.id) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      if (items.length === 0) {
        return (
          <Typography sx={{ color: "error.main" }}>
            Nenhum local cadastrado para este bloco.
          </Typography>
        );
      } else {
        return (
          <ReservationTable
            {...props}
            from="block"
            items={items}
            doReloadTable={doReloadTable}
          />
        );
      }
    }
  }
};

BlockReservation.defaultProps = {
  id: "",
  dateReservation: new Date(),
};

BlockReservation.propTypes = {
  id: PropTypes.string,
  dateReservation: PropTypes.instanceOf(Date),
};

export default BlockReservation;
