import GlobalStyles from "@mui/material/GlobalStyles";

const GlobalStyle = () => {
  return (
    <GlobalStyles
      styles={{
        svg: { width: "100%", height: "auto" },
      }}
    />
  );
};

export default GlobalStyle;
