import React, { useState, useEffect, useCallback, createContext } from "react";

import instance from "api/definitions";

export const AccessContext = createContext(null);
export const ActivityTypeContext = createContext(null);
export const DivisionContext = createContext(null);
export const LocationTypeContext = createContext(null);
export const OccupantTypeContext = createContext(null);
export const ParticipantTypeContext = createContext(null);

const CommonProvider = ({ children }) => {
  const [access, setAccess] = useState([]);
  const [activityType, setActivityType] = useState([]);
  const [division, setDivision] = useState([]);
  const [locationType, setLocationType] = useState([]);
  const [occupantType, setOccupantType] = useState([]);
  const [participantType, setParticipantType] = useState([]);

  const fetchCommonAccess = useCallback(async () => {
    instance
      .get("common/acesso")
      .then(function (response) {
        const result = response.data;
        setAccess(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCommonActivityType = useCallback(async () => {
    instance
      .get("common/atividade")
      .then(function (response) {
        const result = response.data;
        setActivityType(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCommonDivision = useCallback(async () => {
    instance
      .get("common/divisao")
      .then(function (response) {
        const result = response.data;
        setDivision(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCommonLocationType = useCallback(async () => {
    instance
      .get("common/local")
      .then(function (response) {
        const result = response.data;
        setLocationType(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCommonOccupantType = useCallback(async () => {
    instance
      .get("common/ocupante")
      .then(function (response) {
        const result = response.data;
        setOccupantType(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const fetchCommonParticipantType = useCallback(async () => {
    instance
      .get("common/participante")
      .then(function (response) {
        const result = response.data;
        setParticipantType(result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchCommonAccess();
    fetchCommonActivityType();
    fetchCommonDivision();
    fetchCommonLocationType();
    fetchCommonOccupantType();
    fetchCommonParticipantType();
  }, [
    fetchCommonAccess,
    fetchCommonActivityType,
    fetchCommonDivision,
    fetchCommonLocationType,
    fetchCommonOccupantType,
    fetchCommonParticipantType,
  ]);

  return (
    <AccessContext.Provider value={access}>
      <ActivityTypeContext.Provider value={activityType}>
        <DivisionContext.Provider value={division}>
          <LocationTypeContext.Provider value={locationType}>
            <OccupantTypeContext.Provider value={occupantType}>
              <ParticipantTypeContext.Provider value={participantType}>
                {children}
              </ParticipantTypeContext.Provider>
            </OccupantTypeContext.Provider>
          </LocationTypeContext.Provider>
        </DivisionContext.Provider>
      </ActivityTypeContext.Provider>
    </AccessContext.Provider>
  );
};

export default CommonProvider;
