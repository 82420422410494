import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import DivisionTable from "components/molecules/table/DivisionTable";
import TabPanel from "components/atoms/table/TabPanel";

const sortListByNome = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const UnitDivision = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const snackContext = useContext(SnackContext);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (props.id) {
      const fullURL = `unidade/${props.id}/divisao`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const index = Object.keys(result);
          let divisionTypeLabel = "";
          let arrayLocation = [];
          let arrayLabel = [];

          index.forEach((element) => {
            divisionTypeLabel = element;
            const values = Object.values(result[element]);
            arrayLocation = [];
            if (values.length > 0) {
              values.forEach((element) => {
                arrayLocation.push({
                  idDivisao: element.id,
                  nome: element.nome,
                  uorg: element.uorg,
                  idDivisaoTipo: element.idDivisaoTipo,
                  idUnidade: element.idUnidade,
                  id: element.id,
                  descricao: element.descricao,
                  divisionType: divisionTypeLabel,
                });
              });
            } else {
              arrayLocation.push({
                divisionType: divisionTypeLabel,
              });
            }

            arrayLabel.push(sortListByNome(arrayLocation));
          });
          setItems(arrayLabel);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.id) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      const manyTab = items.map((item) => (
        <Tab key={item[0].divisionType} label={item[0].divisionType} />
      ));
      let i = -1;
      const manyTabPanel = items.map((item) => {
        i++;
        const dTable =
          item[0].id !== undefined ? (
            <DivisionTable rows={item} />
          ) : (
            <Typography sx={{ color: "error.main" }}>
              Nenhum cadastrado.
            </Typography>
          );
        return (
          <TabPanel key={item[0].divisionType} value={currentTab} index={i}>
            {dTable}
          </TabPanel>
        );
      });
      return (
        <Box sx={{ pt: 2, pl: 1, pr: 4 }}>
          <Tabs value={currentTab} onChange={handleChange}>
            {manyTab}
          </Tabs>
          {manyTabPanel}
        </Box>
      );
    }
  }
};

UnitDivision.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UnitDivision;
