import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectUnit = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [unit, setUnit] = useState([]);
  const [errorUnit, setErrorUnit] = useState(null);

  const handleChange = (event, newValue) => {
    setUnit(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setUnit(props.dataParentToChild);
    setErrorUnit(props.errorParentToChild);

    instance
      .get("unidade")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const units = [];
        result.map((item) => units.push({ label: item.nome, id: item.id }));
        setItems(sortListByLabel(units));
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        setError(msg);
      });
  }, [props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        value={unit}
        onChange={handleChange}
        disablePortal
        id="unit"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhuma cadastrada"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorUnit}
            label="Unidade"
          />
        )}
      />
    );
  }
});

export default SelectUnit;
