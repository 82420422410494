import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import HelpTooltip from "components/atoms/info/HelpTooltip";

const SwitchConflict = (props) => {
  const [checkedConflict, setCheckedConflict] = useState(false);

  const [conflictInternal, setConflictInternal] = useState("manual");
  const [conflictExternal, setConflictExternal] = useState("manual");

  useEffect(() => {
    setCheckedConflict(props.checkedParentToChild);
    setConflictInternal(props.internalParentToChild);
    setConflictExternal(props.externalParentToChild);
  }, [props]);

  const handleChangeCheckConflict = (event) => {
    const newValue = event.target.checked;
    setCheckedConflict(newValue);
    props.checkedParentCallback(newValue);
    if (newValue) {
      setConflictInternal("ignore");
      props.conflictInternalParentCallback("ignore");
      setConflictExternal("ignore");
      props.conflictExternalParentCallback("ignore");
    } else {
      setConflictInternal("manual");
      props.conflictInternalParentCallback("manual");
      setConflictExternal("manual");
      props.conflictExternalParentCallback("manual");
    }
  };

  const handleInternalChange = (event) => {
    const newValue = event.target.value;
    setConflictInternal(newValue);
    props.conflictInternalParentCallback(newValue);
  };

  const handleExternalChange = (event) => {
    const newValue = event.target.value;
    setConflictExternal(newValue);
    props.conflictExternalParentCallback(newValue);
  };

  return (
    <>
      <Switch checked={checkedConflict} onChange={handleChangeCheckConflict} />
      Gerenciar conflitos automaticamente:
      {checkedConflict && (
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "primary.main",
            pl: 2,
            pr: 2,
            pb: 2,
          }}
        >
          <HelpTooltip placement="top-end">
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <RadioButtonCheckedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Ignorar"
                  secondary="Não cadastra as reservas com conflito, porém cadastra as outras
              reservas que não tiverem conflito."
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <RadioButtonCheckedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Cancelar"
                  secondary="Não cadastra nenhuma reserva se houver alguma reserva em conflito."
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <RadioButtonCheckedIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Substituir"
                  secondary="Apaga as reservas em conflito e as substitui com as
              novas reservas."
                />
              </ListItem>
            </List>
          </HelpTooltip>
          <Box>
            <FormControl id="formConflictInternal" sx={{ ml: 1 }}>
              <FormLabel id="radio-conflictInternal-group-label">
                Se esta atividade tiver reservas neste mesmo horário
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="radio-conflictInternal-group"
                name="controlled-radio-conflictInternal-group"
                value={conflictInternal}
                onChange={handleInternalChange}
              >
                <FormControlLabel
                  value="ignore"
                  control={<Radio />}
                  label="Ignorar"
                />
                <FormControlLabel
                  value="abort"
                  control={<Radio />}
                  label="Cancelar"
                />
                <FormControlLabel
                  value="overwrite"
                  control={<Radio />}
                  label="Substituir"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box>
            <FormControl id="formConflictExternal" sx={{ ml: 1 }}>
              <FormLabel id="radio-conflictExternal-group-label">
                Se outra atividade tiver reserva neste local neste mesmo horário
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="radio-conflictExternal-group"
                name="controlled-radio-conflictExternal-group"
                value={conflictExternal}
                onChange={handleExternalChange}
              >
                <FormControlLabel
                  value="ignore"
                  control={<Radio />}
                  label="Ignorar"
                />
                <FormControlLabel
                  value="abort"
                  control={<Radio />}
                  label="Cancelar"
                />
                <FormControlLabel
                  value="overwrite"
                  control={<Radio />}
                  label="Substituir"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
    </>
  );
};

SwitchConflict.defaultProps = {
  checkedParentToChild: false,
  internalParentToChild: "ignore",
  externalParentToChild: "ignore",
  checkedParentCallback: () => {},
  conflictInternalParentCallback: () => {},
  conflictExternalParentCallback: () => {},
};

SwitchConflict.propTypes = {
  checkedParentToChild: PropTypes.bool,
  internalParentToChild: PropTypes.string,
  externalParentToChild: PropTypes.string,
  checkedParentCallback: PropTypes.func,
  conflictInternalParentCallback: PropTypes.func,
  conflictExternalParentCallback: PropTypes.func,
};

export default SwitchConflict;
