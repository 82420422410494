import React, { useState, useEffect, forwardRef } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import HelpTooltip from "components/atoms/info/HelpTooltip";

const SelectParticipantId = forwardRef((props, ref) => {
  const [participantId, setParticipantId] = useState([]);
  const [errorParticipantId, setErrorParticipantId] = useState(null);

  const handleChange = (event, newValue) => {
    setParticipantId(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setParticipantId(props.dataParentToChild);
    setErrorParticipantId(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  return (
    <Grid container rowSpacing={0} columnSpacing={2}>
      <Grid item xs={0.5} sx={{ mt: 3, textAlign: "center" }}>
        <HelpTooltip placement="top-end">
          <Typography variant="h6">Participantes</Typography>
          <Typography>
            Digite o CPF e pressione a tecla [Enter] para cada participante do
            tipo autorizado que deseja cadastrar.
          </Typography>
        </HelpTooltip>
      </Grid>
      <Grid item xs={11.5}>
        <Stack>
          <Autocomplete
            multiple
            freeSolo={participantId.length > 10 ? false : true}
            value={participantId}
            onChange={handleChange}
            options={[]}
            disablePortal
            id="participant"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                autoFocus
                margin="normal"
                inputRef={ref}
                error={errorParticipantId}
                label="Participantes Autorizados (CPFs)"
              />
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
});

export default SelectParticipantId;
