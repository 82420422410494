import React, { useState, useEffect, useContext, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const GetPersonName = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const navigate = useNavigate();
  const snackContext = useContext(SnackContext);

  const load = props.load;
  const cpf = props.cpf;

  useEffect(() => {
    if (load) {
      setIsLoaded(false);

      let fullURL = "user";

      const request = { userId: [cpf] };

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result) {
            snackContext.setMessage("Erro ao buscar o Nome.", "error");
          } else {
            setItems(result);
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
          if (msg === "Sessão expirada. Necessário login para continuar.") {
            navigate("/login");
          }
        });
    }
  }, [load, cpf, snackContext, navigate]);

  if (load) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      const uId = Object.keys(items);
      if (uId.length > 0) {
        const value = Object.values(items);
        return (
          <Typography>
            <strong>Nome: </strong>
            {value}
          </Typography>
        );
      } else {
        return <Typography color="error">Não encontrado.</Typography>;
      }
    }
  }
};

GetPersonName.propTypes = {
  load: PropTypes.bool,
  cpf: PropTypes.string,
};

const VerifyPerson = forwardRef((props, ref) => {
  const [loadPerson, setLoadPerson] = useState(false);
  const [idPerson, setIdPerson] = useState("");
  const [errorIdPerson, setErrorIdPerson] = useState(false);

  useEffect(() => {
    setIdPerson(props.dataParentToChild);
    setErrorIdPerson(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  const handleChange = (event) => {
    setLoadPerson(false);
    const newValue = event.target.value.trim();
    setIdPerson(newValue);

    props.parentCallback(newValue);
  };

  const handleVerifyPerson = () => {
    if (!idPerson) {
      ref.current.focus();
      if (!errorIdPerson) setErrorIdPerson(true);
      alert("Por favor, preencha o campo CPF.");
    } else {
      setLoadPerson(true);
    }
  };

  return (
    <Box sx={{ border: 1, borderRadius: 2, borderColor: "primary.main", p: 1 }}>
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item>
          <Box sx={{ mt: 3 }}>
            <Typography fontWeight="700">{props.title}</Typography>
          </Box>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            margin="dense"
            inputProps={{ maxLength: 20 }}
            inputRef={ref}
            error={errorIdPerson}
            value={idPerson}
            onChange={handleChange}
            id="idPerson"
            label="CPF"
            name="idPerson"
            autoComplete="off"
          />
        </Grid>
        <Grid item>
          {loadPerson ? (
            <Box sx={{ mt: 3 }}>
              <GetPersonName load={loadPerson} cpf={idPerson} />
            </Box>
          ) : (
            <Box sx={{ mt: 1 }}>
              <InfoTooltip placement="right" title="Buscar Nome">
                <IconButton size="large" onClick={handleVerifyPerson}>
                  <PersonSearchIcon sx={{ width: 28, height: 28 }} />
                </IconButton>
              </InfoTooltip>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export default VerifyPerson;
