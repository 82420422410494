import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import { DivisionContext } from "context/CommonProvider";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ModifyDivision = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `divisao/${props.idDivision}`;

      const request = {
        nome: props.name,
        descricao: props.description,
        uorg: props.acronym,
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível atualizar esta divisão",
              "error"
            );
          } else if (result.affected === 0) {
            snackContext.setMessage(
              "Nada foi modificado. Modifique alguma informação ou clique em voltar.",
              "warning"
            );
          } else {
            snackContext.setMessage(`Atualização salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, useRef and navigate
  const [load, setLoad] = useState(false);

  const [idDivision, setIdDivision] = useState("");
  const [divisionType, setDivisionType] = useState(null);
  const [unit, setUnit] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [acronym, setAcronym] = useState("");

  const [errorName, setErrorName] = useState(false);

  const refName = useRef(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const items = props.items;
    setUnit(items.unidade);
    setIdDivision(String(items.id));
    setDivisionType(items.divisionType);
    setName(String(items.nome));
    setDescription(String(items.descricao));
    setAcronym(String(items.uorg));
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  //#region handleChange
  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeAcronym = (event) => {
    if (load) setLoad(false);

    setAcronym(event.target.value);
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <Box>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box sx={{ m: 1 }}>
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item>
              <strong>Tipo de divisão:</strong> {divisionType}
            </Grid>
            <Grid item>
              <strong>Unidade:</strong> {unit}
            </Grid>
          </Grid>
        </Box>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 10 }}
              value={acronym}
              onChange={handleChangeAcronym}
              id="acronym"
              label="UORG"
              name="acronym"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={9} lg={10}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyDivision
          idDivision={idDivision}
          name={name}
          description={description}
          acronym={acronym}
          load={load}
          handleClose={handleBack}
        />
      </Box>
    </Box>
  );
};

const GetUnit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const division = props.items;
    const fullURL = `unidade/${division.idUnidade}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          const newResult = {
            id: division.id,
            unidade: result.nome,
            divisionType: division.divisionType,
            nome: division.nome,
            descricao: division.descricao,
            uorg: division.uorg,
          };
          setItems(newResult);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const DivisionItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);
  const divisionTypeContext = useContext(DivisionContext);

  useEffect(() => {
    const fullURL = `divisao/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          const divisionType = divisionTypeContext[result.idDivisaoTipo];
          const newResult = {
            id: result.id,
            idUnidade: result.idUnidade,
            divisionType: divisionType,
            nome: result.nome,
            descricao: result.descricao,
            uorg: result.uorg,
          };
          setItems(newResult);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, divisionTypeContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetUnit items={items} />;
};

const DivisionUpdate = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Atualizar Divisão"}
      icon={<EditIcon />}
    >
      <DivisionItems {...props} />
    </PageContainer>
  );
};

export default DivisionUpdate;
