import React, { useState, useEffect, useContext, forwardRef } from "react";

import { ParticipantTypeContext } from "context/CommonProvider";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectParticipantType = forwardRef((props, ref) => {
  const [participantType, setParticipantType] = useState(null);
  const [errorParticipantType, setErrorParticipantType] = useState(null);

  const handleChange = (event, newValue) => {
    setParticipantType(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setParticipantType(props.dataParentToChild);
    setErrorParticipantType(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  const participantTypeContext = useContext(ParticipantTypeContext);
  const items = [];
  for (var [key, value] of Object.entries(participantTypeContext)) {
    items.push({ label: value, id: key });
  }

  return (
    <>
      <Autocomplete
        disableClearable
        value={participantType}
        onChange={handleChange}
        disablePortal
        id="ParticipantType"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorParticipantType}
            label="Tipo de Participante"
          />
        )}
      />
    </>
  );
});

export default SelectParticipantType;
