import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";

import { SnackContext } from "context/SnackProvider";
import instance from "api/definitions";
import {
  getFormattedDateURL,
  getFormattedTime,
} from "components/atoms/dateTime/GetFormattedDate";

const getRequest = (props) => {
  const dateReservationFormatted = getFormattedDateURL(props.dateReservation);
  const timeBeginFormatted = getFormattedTime(props.dateBegin);
  const timeEndFormatted = getFormattedTime(props.dateEnd);
  const dateBeginFormatted =
    dateReservationFormatted + " " + timeBeginFormatted;
  const dateEndFormatted = dateReservationFormatted + " " + timeEndFormatted;

  const request = {
    idLocal: props.location.id,
    inicio: dateBeginFormatted,
    fim: dateEndFormatted,
    conflict: {
      internal: "overwrite",
      external: "overwrite",
    },
  };

  return request;
};

const UpdateReservationSingle = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `reserva/${props.idReservation}`;
      const request = getRequest(props);

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Reserva não foi atualizada.", "error");
          } else {
            snackContext.setMessage(
              "Reserva atualizada com sucesso.",
              "success"
            );
          }
          props.handleClose(1);
        })
        .catch(function (error) {
          setIsLoaded(true);
          snackContext.setMessage("Reserva não foi atualizada.", "error");
          props.handleClose(0);
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

UpdateReservationSingle.propTypes = {
  load: PropTypes.bool,
  idReservation: PropTypes.string,
  dateReservation: PropTypes.instanceOf(Date),
  dateBegin: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),

  location: PropTypes.object,
  handleClose: PropTypes.func,
};

export default UpdateReservationSingle;
