import React from "react";
import { useParams } from "react-router-dom";

import ReservationPage from "components/pages/ReservationEquipment/Reservation";

const Reservation = () => {
  const { id } = useParams();

  return <ReservationPage id={id} />;
};

export default Reservation;
