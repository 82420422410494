import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const MessageDialog = (props) => {
  const [sOpen, setOpen] = useState(false);

  const isControlled = props.open !== undefined;
  const open = isControlled ? props.open : sOpen;

  useEffect(() => {
    setOpen(true);
  }, []);

  const ActionButtons = () => {
    if (props.confirm) {
      return (
        <DialogActions>
          <Button id="dialog-confirm" onClick={handleConfirm}>
            Sim
          </Button>
          <Button id="dialog-no" onClick={handleClose}>
            Não
          </Button>
        </DialogActions>
      );
    }
  };

  const CloseButton = () => {
    if (!props.confirm) {
      return (
        <DialogActions sx={{ position: "absolute", top: "0%", right: "0%" }}>
          <IconButton id="dialog-close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
      );
    }
  };

  const handleClose = (event) => {
    setOpen(false);
    props.onClose();
  };

  const handleConfirm = (event) => {
    props.onConfirm();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") props.confirm ? handleConfirm() : handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onKeyDown={handleKeyDown}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{ bgcolor: props.variant }}
    >
      <CloseButton />
      <DialogTitle id="dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          {props.content}
        </DialogContentText>
        {props.children}
      </DialogContent>

      <ActionButtons />
    </Dialog>
  );
};

export const MessageDialogVariants = {
  default: "default",
  success: "success.light",
  warning: "warning.light",
  error: "error.light",
};

MessageDialog.defaultProps = {
  variant: "default",
  title: "Mensagem",
  content: undefined,
  children: undefined,
  open: undefined,
  confirm: undefined,
  onClose: () => {},
  onConfirm: () => {},
};

MessageDialog.propTypes = {
  variant: PropTypes.oneOf(Object.values(MessageDialogVariants)),
  title: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  open: PropTypes.bool,
  confirm: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default MessageDialog;
