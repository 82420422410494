import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import { getDisplayTimeHour } from "components/atoms/dateTime/GetFormattedDate";

const UpdateReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `reserva/${props.idReservation}`;

      const dateReservationFormatted = props.dateReservation;
      const dateBeginFormatted =
        dateReservationFormatted + " " + props.reservation.timeBegin;
      const dateEndFormatted =
        dateReservationFormatted + " " + props.reservation.timeEnd;

      const request = {
        idLocal: props.idLocation,
        inicio: dateBeginFormatted,
        fim: dateEndFormatted,
        conflict: {
          internal: props.conflictInternal,
          external: props.conflictExternal,
        },
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;

          if (!result.performed) {
            const conflict = result.reservas[0].conflict;
            const pMsg =
              "Não foi possível criar esta reserva, pois houve um conflito de horário. ";
            const msg = [
              `${pMsg} Esta atividade já tem uma reserva neste horário.`,
              `${pMsg} Este local já está ocupado neste horário.`,
              "Erro. Não foi possível criar esta reserva.",
            ];
            if (conflict) {
              const internal = conflict.internal.length;
              const external = conflict.external.length;
              if (internal > 0) {
                snackContext.setMessage(msg[0], "error");
              } else if (external > 0) {
                snackContext.setMessage(msg[1], "error");
              }
            } else {
              snackContext.setMessage(msg[2], "error");
            }
          } else {
            snackContext.setMessage("Reserva alterada com sucesso.", "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          let msg = getErrorMessage(error);
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.reservas) {
                if (error.response.data.reservas[0].msg) {
                  msg += error.response.data.reservas[0].msg;
                }
              }
            }
          }

          snackContext.setMessage(msg, "error");
          props.handleClose();
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const UpdateReservationDrop = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    setItems([]);
    if (props.load) {
      const hourBegin =
        props.hourBegin.length === 1 ? `0${props.hourBegin}` : props.hourBegin;
      const timeBegin = `${hourBegin}:00:00`;

      const fullURL = `reserva/${props.idReservation}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);

          const result = response.data;
          const inicio = getDisplayTimeHour(result.reserva.inicio);
          const fim = getDisplayTimeHour(result.reserva.fim);
          const hourDif = Number(fim) - Number(inicio);
          const hourPlus = Number(props.hourBegin) + hourDif;
          let hourEnd = String(hourPlus);
          hourEnd = hourEnd.length === 1 ? `0${hourEnd}` : hourEnd;

          const timeEnd = `${hourEnd}:00:00`;
          const newResult = {
            idActivity: result.atividade.id,
            timeBegin: timeBegin,
            timeEnd: timeEnd,
          };
          setItems(newResult);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return <UpdateReservation {...props} reservation={items} />;
    }
  }
};

UpdateReservationDrop.propTypes = {
  load: PropTypes.bool,
  idReservation: PropTypes.string,
  idLocation: PropTypes.string,
  dateReservation: PropTypes.string,
  hourBegin: PropTypes.string,
  handleClose: PropTypes.func,
};

export default UpdateReservationDrop;
