import React, { useState, useEffect, useContext, forwardRef } from "react";

import { LocationTypeContext } from "context/CommonProvider";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectLocationType = forwardRef((props, ref) => {
  const [locationType, setLocationType] = useState(null);
  const [errorLocationType, setErrorLocationType] = useState(null);

  const handleChange = (event, newValue) => {
    setLocationType(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setLocationType(props.dataParentToChild);
    setErrorLocationType(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  const locationTypeContext = useContext(LocationTypeContext);
  const items = [];
  for (var [key, value] of Object.entries(locationTypeContext)) {
    items.push({ label: value, id: key });
  }

  return (
    <Autocomplete
      disableClearable
      value={locationType}
      onChange={handleChange}
      disablePortal
      id="locationType"
      options={items}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) =>
        value === undefined ||
        value === "" ||
        option.id === value.id ||
        option.nome === value.nome
      }
      noOptionsText={"Nenhum cadastrado"}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          inputRef={ref}
          error={errorLocationType}
          label="Tipo de Local"
        />
      )}
    />
  );
});

export default SelectLocationType;
