import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

const BlockFloor = (props) => {
  return (
    <Typography
      sx={{
        width: 100,
        border: 2,
        bgcolor: "blue",
        color: "white",
        textAlign: "center",
      }}
    >
      {props.floor}º andar
    </Typography>
  );
};

const BlockUnderground = (props) => {
  return (
    <Typography
      sx={{
        width: 100,
        border: 2,
        bgcolor: "brown",
        color: "white",
        textAlign: "center",
      }}
    >
      {props.underground}º subsolo
    </Typography>
  );
};

export const LocationPavement = (props) => {
  const pavement = props.pavement;
  let label = "";
  let bgColor = "";
  if (pavement === 0) {
    label = "Térreo";
    bgColor = "black";
  } else if (pavement > 0) {
    label = `${pavement}º andar`;
    bgColor = "blue";
  } else {
    label = `${pavement}º subsolo`;
    bgColor = "brown";
  }
  return (
    <Typography
      sx={{
        width: 150,
        border: 2,
        bgcolor: bgColor,
        color: "white",
        textAlign: "center",
      }}
    >
      {label}
    </Typography>
  );
};

LocationPavement.defaultProps = {
  pavement: 0,
};

LocationPavement.propTypes = {
  pavement: PropTypes.number,
};

const BlockPavements = (props) => {
  let floor = props.floor + 1;
  let underground = props.underground + 1;
  const floors = [];
  const under = [];
  for (let index = 1; index < floor; index++) {
    floors.push(index);
  }
  floors.reverse();
  for (let index = 1; index < underground; index++) {
    under.push(index);
  }
  return (
    <>
      {floors.map((item) => (
        <BlockFloor key={item} floor={item} />
      ))}
      <Typography
        sx={{
          width: 100,
          border: 2,
          bgcolor: "black",
          color: "white",
          textAlign: "center",
        }}
      >
        Térreo
      </Typography>
      {under.map((item) => (
        <BlockUnderground key={item} underground={item} />
      ))}
    </>
  );
};

BlockPavements.defaultProps = {
  floor: 0,
  underground: 0,
};

BlockPavements.propTypes = {
  floor: PropTypes.number,
  underground: PropTypes.number,
};

export default BlockPavements;
