import React from "react";
import { useParams } from "react-router-dom";

import DivisionPage from "components/pages/UnitDivision/Division";

const Division = () => {
  const { id } = useParams();

  return <DivisionPage id={id} />;
};

export default Division;
