import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";
import Paper from "@mui/material/Paper";
import Pagination from "components/atoms/table/Pagination";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";
import {
  getDisplayDateTime,
  getFormattedDateURL,
} from "components/atoms/dateTime/GetFormattedDate";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ReturnLoan = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadSolve) {
      const fullURLDelete = `emprestimo/${props.idLoan}/return`;
      const description = props.description ? props.description : "devolver";
      const request = { descricao: description };
      instance
        .put(fullURLDelete, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível devolver este empréstimo.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Empréstimo devolvido com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadSolve && !isLoaded) return <CircularProgress />;
};

const ActualTable = (props) => {
  const [loadSolve, setLoadSolve] = useState(false);
  const [openSolveConfirmDialog, setOpenSolveConfirmDialog] = useState(false);

  const [idLoan, setIdLoan] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionReturn, setDescriptionReturn] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const rows = props.rows;
  const rowsLength = rows.length;

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  //Solve
  const handleClickSolve = (event) => {
    const arr = String(event.currentTarget.id).split("&");
    setIdLoan(arr[0]);
    setDescription(arr[1]);
    setOpenSolveConfirmDialog(true);
  };
  const handleCloseSolveDialog = () => {
    setOpenSolveConfirmDialog(false);
    setDescriptionReturn("");
  };
  const handleConfirmSolve = () => {
    setOpenSolveConfirmDialog(false);
    setLoadSolve(true);
  };
  const handleBackSolve = () => {
    setLoadSolve(false);
    setDescriptionReturn("");
    props.handleReloadTable();
  };

  const handleChangeDescriptionReturn = (event) => {
    setDescriptionReturn(event.target.value);
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main", textAlign: "center" }}>
        Não há empréstimos.
      </Typography>
    );
  } else {
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row) => (
      <StyledTableRow key={row.idEmprestimo} id={row.idEmprestimo}>
        <StyledTableCell>{row.item}</StyledTableCell>
        <StyledTableCell>
          {row.descricao ? row.descricao : "(Não informada)"}
        </StyledTableCell>
        <StyledTableCell>{getDisplayDateTime(row.saida)}</StyledTableCell>
        <StyledTableCell>{row.concedente}</StyledTableCell>
        <StyledTableCell>{row.recebedor}</StyledTableCell>
        <StyledTableCell>
          {row.devolucao ? (
            row.devolvido
          ) : (
            <Typography sx={{ textAlign: "center" }}>
              <InfoTooltip placement="left" title="Devolver">
                <IconButton
                  onClick={handleClickSolve}
                  id={row.idEmprestimo + "&" + row.item}
                >
                  <CheckCircleIcon />
                </IconButton>
              </InfoTooltip>
            </Typography>
          )}
        </StyledTableCell>
      </StyledTableRow>
    ));
    return (
      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="data table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Item</StyledTableCell>
                <StyledTableCell>Descrição</StyledTableCell>
                <StyledTableCell>Saída</StyledTableCell>
                <StyledTableCell>Concedente</StyledTableCell>
                <StyledTableCell>Recebedor</StyledTableCell>
                <StyledTableCell>Devolução</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          name="Empréstimos"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
        <Box id="boxSolve">
          <ReturnLoan
            loadSolve={loadSolve}
            idLoan={idLoan}
            description={descriptionReturn}
            handleClose={handleBackSolve}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.warning}
            title="Solucionar"
            open={openSolveConfirmDialog}
            onClose={handleCloseSolveDialog}
            onConfirm={handleConfirmSolve}
          >
            Você tem certeza que gostaria de devolver este empréstimo?
            <br />
            {`Empréstimo: ${description}`}
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={descriptionReturn}
              onChange={handleChangeDescriptionReturn}
              id="descricao"
              label="Descrição"
              name="descricao"
              autoComplete="off"
            />
          </MessageDialog>
        </Box>
      </Box>
    );
  }
};

const EquipmentLoanTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const handleReloadTable = (event) => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setIsLoaded(false);
    setItems([]);
    if (props.load) {
      let fullURL = `emprestimo/search`;
      const idReserva = props.idReservation ? props.idReservation : null;
      const idLocal = props.idLocation ? Number(props.idLocation) : null;
      const idEmprestavel = props.idEmprestavel ? props.idEmprestavel : null;
      const userId = props.userId ? props.userId : null;
      const userIdConcedente = props.userIdConcedente
        ? props.userIdConcedente
        : null;
      const userIdDevolucao = props.userIdDevolucao
        ? props.userIdDevolucao
        : null;

      let saida = null;
      let saida2 = null;
      if (props.checkedFilterExit && props.dateLoanBegin && props.dateLoanEnd) {
        saida = getFormattedDateURL(props.dateLoanBegin);
        saida2 = getFormattedDateURL(props.dateLoanEnd);
      }
      let devolucao = null;
      let devolucao2 = null;
      if (
        props.checkedFilterReturned &&
        props.dateReturnedBegin &&
        props.dateReturnedEnd
      ) {
        devolucao = getFormattedDateURL(props.dateReturnedBegin);
        devolucao2 = getFormattedDateURL(props.dateReturnedEnd);
      }

      const request = {
        idReserva,
        idLocal,
        idEmprestavel,
        userId,
        userIdConcedente,
        userIdDevolucao,
        saida,
        saida2,
        devolucao,
        devolucao2,
        current: props.return,
      };
      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, reloadTable, snackContext]);

  if (props.load) {
    if (!isLoaded) return <CircularProgress />;
    else
      return <ActualTable rows={items} handleReloadTable={handleReloadTable} />;
  }
};

export default EquipmentLoanTable;
