import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import EventBusyIcon from "@mui/icons-material/EventBusy";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const DrawerReservationLocation = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [drawer, setDrawer] = useState(false);

  const snackContext = useContext(SnackContext);
  const navigate = useNavigate();

  const openDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(true);
  };

  const closeDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(false);
  };

  useEffect(() => {
    const fullURL = `/reserva/void`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const resultValues = Object.values(result);
        const itemsValues = [];

        resultValues.forEach((resultRow) => {
          itemsValues.push({
            activity: `${resultRow.nome}`,
            length: resultRow.reservas.length,
            idActivity: resultRow.idAtividade,
          });
        });

        setItems(itemsValues);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [snackContext, drawer]);

  if (!isLoaded) return <CircularProgress />;
  else {
    const listItems = [];
    items.map((item) =>
      listItems.push(
        <ListItem disablePadding key={item.idActivity}>
          <ListItemButton
            onClick={() =>
              navigate(`/atividade/${item.idActivity}/reserva-sem-local`)
            }
          >
            <Badge badgeContent={item.length} color="error">
              <ListItemText primary={item.activity} sx={{ width: 200 }} />
            </Badge>
          </ListItemButton>
        </ListItem>
      )
    );

    return (
      <Box>
        <InfoTooltip placement="bottom-end" title="Reservas sem local">
          <IconButton
            onClick={openDrawer}
            sx={{
              color: "inherit",
              "&:hover": {
                color: "primary.main",
                backgroundColor: "white",
              },
            }}
          >
            {items.length > 0 ? (
              <Badge badgeContent={items.length} color="error">
                <EventBusyIcon />
              </Badge>
            ) : (
              <CalendarTodayIcon />
            )}
          </IconButton>
        </InfoTooltip>
        <Drawer
          anchor={"right"}
          open={drawer}
          onClose={closeDrawer}
          sx={{ position: "absolute", top: "20" }}
        >
          <Box>
            <IconButton onClick={closeDrawer} sx={{ m: 1 }}>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
          <Box sx={{ width: 250 }} role="presentation">
            <Box>
              <Typography variant="h6" sx={{ ml: 2 }}>
                Reservas sem local
              </Typography>
              <List>{listItems}</List>
            </Box>
          </Box>
        </Drawer>
      </Box>
    );
  }
};

export default DrawerReservationLocation;
