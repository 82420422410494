import React from "react";
import { useParams } from "react-router-dom";

import ResourceUpdatePage from "components/pages/ResourceOccurrence/ResourceUpdate";

const ResourceUpdate = () => {
  const { id } = useParams();

  return <ResourceUpdatePage id={id} />;
};

export default ResourceUpdate;
