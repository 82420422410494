import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";

import PageContainer from "components/templates/PageContainer";
import UnitCardList from "components/organisms/CardList/UnitCardList";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import { GetUserRole } from "api/definitions";

const Fields = () => {
  const navigate = useNavigate();
  const userRole = GetUserRole();

  const handleClickNew = () => {
    navigate("/unidade-nova");
  };

  return (
    <>
      <Box id="boxData" sx={{ mt: 2 }}>
        <UnitCardList />
      </Box>
      {userRole > 7 && (
        <Box
          id="boxData"
          sx={{ position: "absolute", top: "14%", right: "10%" }}
        >
          <InfoTooltip title="Nova unidade" placement="left">
            <Fab
              size="medium"
              color="primary"
              aria-label="nova unidade"
              onClick={handleClickNew}
            >
              <AddIcon />
            </Fab>
          </InfoTooltip>
        </Box>
      )}
    </>
  );
};

const Unit = () => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Unidades e Divisões"}
      icon={<VerticalSplitIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default Unit;
