import React, { useState, useRef } from "react";

import EventNoteIcon from "@mui/icons-material/EventNote";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import PageContainer from "components/templates/PageContainer";
import BlockReservation from "components/organisms/Reservation/BlockReservation";
import SelectBlock from "components/atoms/select/SelectBlock";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import {
  getFormattedDateURL,
  getParseDate,
} from "components/atoms/dateTime/GetFormattedDate";

const TableCaption = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          width: "100px",
        }}
      >
        Tipo de atividade:{" "}
      </Typography>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          color: "white",
          bgcolor: "info.main",
        }}
      >
        Comum
      </Typography>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          color: "white",
          bgcolor: "success.dark",
        }}
      >
        Ensalamento
      </Typography>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          color: "white",
          bgcolor: "secondary.dark",
        }}
      >
        Monitoria
      </Typography>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          color: "white",
          bgcolor: "warning.dark",
        }}
      >
        Bloqueio
      </Typography>
      <Typography
        sx={{
          p: 1,
          fontSize: 10,
          fontWeight: "700",
          color: "white",
          bgcolor: "gray",
        }}
      >
        Manutenção
      </Typography>
    </Box>
  );
};

const initialBlock = () => {
  const blockStorage = localStorage.getItem("reservationCalendarBlock");
  if (blockStorage) {
    const arr = blockStorage.split("&");
    const blockItem = { label: arr[1], id: arr[0] };
    return blockItem;
  } else return null;
};

const initialDateReservation = () => {
  let dateItem = new Date();
  const dateStorage = localStorage.getItem(
    "reservationCalendarDateReservation"
  );
  if (dateStorage) {
    dateItem = getParseDate(dateStorage);
  }
  return dateItem;
};

const ReservationCalendar = () => {
  const [block, setBlock] = useState(initialBlock);
  const [dateReservation, setDateReservation] = useState(
    initialDateReservation
  );

  const [errorBlock, setErrorBlock] = useState(false);

  const refBlock = useRef(null);

  const blockCallback = (childData) => {
    if (errorBlock) setErrorBlock(false);

    setBlock(childData);
    const id = childData && childData.id;
    const label = childData && childData.label;
    const idLabel = id + "&" + label;
    localStorage.setItem("reservationCalendarBlock", String(idLabel));
  };

  const handleChangeDateReservation = (newValue) => {
    setDateReservation(newValue);
    localStorage.setItem(
      "reservationCalendarDateReservation",
      getFormattedDateURL(newValue)
    );
  };

  return (
    <PageContainer maxWidth="lg" title={"Reservas"} icon={<EventNoteIcon />}>
      <Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6} sm={5} md={4} lg={3}>
            <SelectBlock
              dataParentToChild={block}
              parentCallback={blockCallback}
              ref={refBlock}
              errorParentToChild={errorBlock}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={2}>
            <ButtonDatePicker
              value={dateReservation}
              onChange={handleChangeDateReservation}
              id="dateReservation"
              label="Data das Reservas"
              name="dateReservation"
              sx={{ mt: 1 }}
            />
          </Grid>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <TableCaption />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 2 }}>
        <BlockReservation
          id={block && block.id}
          dateReservation={dateReservation}
        />
      </Box>
    </PageContainer>
  );
};

export default ReservationCalendar;
