import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { SnackContext } from "context/SnackProvider";
import { DivisionContext } from "context/CommonProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import DivisionDiscipline from "components/organisms/DivisionDiscipline";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const Fields = (props) => {
  const [unit, setUnit] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [divisionType, setDivisionType] = useState(null);
  const [acronym, setAcronym] = useState("");

  const userRole = GetUserRole();
  const navigate = useNavigate();

  useEffect(() => {
    const items = props.items;

    setUnit(items.unidade);
    setName(String(items.nome));
    setDivisionType(items.divisionType);
    setDescription(String(items.descricao));
    setAcronym(String(items.uorg));
  }, [props]);

  const handleClickNew = (event) => {
    navigate(`/divisao/${props.items.id}/disciplina-nova`);
  };

  return (
    <Card raised sx={{ mb: 1, minWidth: 300 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={11}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item>
                <strong>Nome:</strong> {name}
              </Grid>
              <Grid item>
                <strong>UORG:</strong> {acronym}
              </Grid>
              <Grid item>
                <strong>Tipo de divisão:</strong> {divisionType}
              </Grid>
              <Grid item>
                <strong>Unidade:</strong> {unit}
              </Grid>
              <Grid item>
                <strong>Descrição:</strong>{" "}
                {description ? description : "(Não informada)"}
              </Grid>
            </Grid>
          </Grid>
          {(divisionType === "Departamento" ||
            divisionType === "Coordenação") &&
            userRole > 7 && (
              <Grid item xs={1}>
                <InfoTooltip title="Nova disciplina" placement="top">
                  <IconButton
                    id={props.id}
                    color="primary"
                    aria-label="nova disciplina"
                    onClick={handleClickNew}
                    sx={{
                      color: "white",
                      bgcolor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.dark",
                      },
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </InfoTooltip>
              </Grid>
            )}
        </Grid>

        {(divisionType === "Departamento" ||
          divisionType === "Coordenação") && (
          <Box sx={{ mt: 1 }}>
            <DivisionDiscipline id={props.id} />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const GetUnit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const division = props.items;
    const fullURL = `unidade/${division.idUnidade}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          const newResult = {
            id: division.id,
            unidade: result.nome,
            divisionType: division.divisionType,
            nome: division.nome,
            descricao: division.descricao,
            uorg: division.uorg,
          };
          setItems(newResult);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} items={items} />;
};

const DivisionCard = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);
  const divisionTypeContext = useContext(DivisionContext);

  useEffect(() => {
    const fullURL = `divisao/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          const divisionType = divisionTypeContext[result.idDivisaoTipo];
          const newResult = {
            id: result.id,
            idUnidade: result.idUnidade,
            divisionType: divisionType,
            nome: result.nome,
            descricao: result.descricao,
            uorg: result.uorg,
          };

          setItems(newResult);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, divisionTypeContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetUnit {...props} items={items} />;
};

DivisionCard.defaultProps = {
  idDivision: 0,
};

DivisionCard.propTypes = {
  idDivision: PropTypes.number,
};

export default DivisionCard;
