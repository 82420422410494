import React from "react";
import { useParams } from "react-router-dom";

import ActivityUpdatePage from "components/pages/ActivityDiscipline/ActivityUpdate";

const ActivityUpdate = () => {
  const { id } = useParams();

  return <ActivityUpdatePage id={id} />;
};

export default ActivityUpdate;
