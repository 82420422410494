import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import PageContainer from "components/templates/PageContainer";

const Error = (props) => {
  const navigate = useNavigate();

  const image = props.image;
  const title = props.title;
  const description = props.description;

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <PageContainer maxWidth="xs" title={title} icon={<ReportProblemIcon />}>
      <Tooltip title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Box sx={{ width: 250 }}>{image}</Box>
      <Typography component="h1" variant="h5">
        {description}
      </Typography>
    </PageContainer>
  );
};

Error.defaultProps = {
  image: undefined,
  title: "",
  description: "",
};

Error.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Error;
