import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import BlockCard from "components/molecules/card/BlockCard";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const BlockCardList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("bloco")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhum bloco cadastrado.
        </Typography>
      );
    } else {
      const cards = items.map((item) => (
        <BlockCard
          key={item.id}
          list={true}
          idBlock={item.id}
          name={item.nome}
          description={item.descricao}
          floor={Number(item.andares)}
          underground={Number(item.subsolo)}
        />
      ));
      return <>{cards}</>;
    }
  }
};

export default BlockCardList;
