import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InfoIcon from "@mui/icons-material/Info";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";

import { GetUserRole } from "api/definitions";
import {
  StyledTableAgendaCell,
  StyledTableAgendaRow,
  getCellActivityBgcolor,
} from "components/atoms/table/StyledTable";
import {
  getFormattedDateURL,
  getConvertedDate,
} from "components/atoms/dateTime/GetFormattedDate";
import UpdateReservationDrop from "components/atoms/reservation/UpdateReservationDrop";
import MessageDialog from "components/atoms/info/MessageDialog";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import HelpTooltip from "components/atoms/info/HelpTooltip";

const ActualTable = (props) => {
  const navigate = useNavigate();

  const rows = props.rows;
  const rowsLength = rows.length;

  const handleCellLocationClick = (event) => {
    const arr = event.target.id.split("&");
    navigate(`/local/${arr[0]}/emprestimos`);
  };

  if (rowsLength !== 0) {
    const cells = rows.map((row) => (
      <StyledTableAgendaRow key={row.id}>
        <InfoTooltip placement="left" title={row.nome}>
          <StyledTableAgendaCell
            className="headCol"
            variant="head"
            id={row.id}
            onClick={
              row.numero !== "Domingo" &&
              row.numero !== "Segunda" &&
              row.numero !== "Terça" &&
              row.numero !== "Quarta" &&
              row.numero !== "Quinta" &&
              row.numero !== "Sexta" &&
              row.numero !== "Sábado"
                ? handleCellLocationClick
                : null
            }
            sx={{
              left: 0,
              height: "45px",
              p: 1,
              "&:hover": {
                cursor:
                  row.numero !== "Domingo" &&
                  row.numero !== "Segunda" &&
                  row.numero !== "Terça" &&
                  row.numero !== "Quarta" &&
                  row.numero !== "Quinta" &&
                  row.numero !== "Sexta" &&
                  row.numero !== "Sábado" &&
                  "pointer",
              },
            }}
          >
            {row.numero}
          </StyledTableAgendaCell>
        </InfoTooltip>
        {row.reservas.map((element) => element)}
      </StyledTableAgendaRow>
    ));
    return (
      <Box id="calendario" sx={{ mb: 2 }}>
        <Table
          stickyHeader
          aria-label="data table"
          padding="none"
          sx={{ minWidth: "1090px" }}
        >
          <TableHead>
            <StyledTableAgendaRow>
              <TableCell sx={{ width: "40px" }}></TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    07
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    0
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    8
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    0
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    9
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    0
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    1
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    2
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    3
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    4
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    5
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    6
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    7
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    8
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    1
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    9
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    2
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    0
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    2
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell sx={{ width: "70px" }}>
                <Grid
                  container
                  rowSpacing={0}
                  columnSpacing={0}
                  sx={{ width: "70px" }}
                >
                  <Grid item xs={6} sx={{ textAlign: "left" }}>
                    1
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: "right" }}>
                    22
                  </Grid>
                </Grid>
              </TableCell>
            </StyledTableAgendaRow>
          </TableHead>
          <TableBody>{cells}</TableBody>
        </Table>
      </Box>
    );
  }
};

ActualTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

const getCellWidth = (colSpan) => {
  const widthSize = colSpan * 70;
  const widthReturn = widthSize + "px";
  return widthReturn;
};

const ReservationTable = (props) => {
  const [openDropDialog, setOpenDropDialog] = useState(false);
  const [openDropDeny, setOpenDropDeny] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [dragIdReservation, setDragIdReservation] = useState("");
  const [dragIdLocation, setDragIdLocation] = useState("");
  const [dragHourBegin, setDragHourBegin] = useState("");
  const [dragDateReservation, setDragDateReservation] = useState("");

  const userRole = GetUserRole();
  const navigate = useNavigate();

  const handleCloseDropDialog = () => {
    setOpenDropDialog(false);
  };

  const handleCloseDropOneDialog = () => {
    setOpenDropDialog(false);
    setLoadUpdate(true);
  };

  const handleCloseDropManyDialog = (event) => {
    setOpenDropDialog(false);
    let dateReservation = props.dateReservation;
    if (!dateReservation) {
      dateReservation = dragDateReservation;
    } else {
      dateReservation = getFormattedDateURL(props.dateReservation);
    }

    if (dateReservation && String(dateReservation) !== "Invalid Date") {
      const dateReservationURL = dateReservation;
      navigate(
        `/reserva-atualiza/${dragIdReservation}/${dragIdLocation}/${dateReservationURL}/${dragHourBegin}`
      );
    }
  };

  const dropButtons = (
    <Box>
      <Box>
        <Box sx={{ mt: 1 }}>
          <Button
            variant="contained"
            onClick={handleCloseDropOneDialog}
            sx={{
              bgcolor: "error.main",
              "&:hover": {
                bgcolor: "error.main",
              },
            }}
          >
            Esta Reserva
          </Button>
          <HelpTooltip placement="right">
            <Typography>
              Se houver reserva(s) em conflito, serão excluídas.
            </Typography>
          </HelpTooltip>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Button variant="contained" onClick={handleCloseDropManyDialog}>
            Esta Reserva e de outros dias
          </Button>
          <HelpTooltip placement="right">
            <Typography>
              Em seguida, poderá escolher a repetição e o que fazer com os
              conflitos.
            </Typography>
          </HelpTooltip>
        </Box>
      </Box>
    </Box>
  );

  const handleAllowDrop = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.target.style.backgroundColor = "#5ce1e6";
  };

  const handleDragLeave = (event) => {
    event.target.style.backgroundColor = "";
  };

  const handleDragDrop = (event) => {
    event.target.style.backgroundColor = "";
    const thisId = event.dataTransfer.getData("text");
    if (thisId) {
      const idColSpan = thisId.split("&");
      const thisIdReservation = idColSpan[0];
      const thisColSpan = idColSpan[1];
      const thisIdLocation = idColSpan[2];
      if (thisColSpan) {
        setDragIdReservation(thisIdReservation);
        const arr = event.target.id.split("&");
        const hourPlus = Number(arr[1]) + Number(thisColSpan);
        if (hourPlus > 22) {
          setOpenDropDeny(true);
        } else {
          if (arr.length === 2) {
            setDragIdLocation(arr[0]);
            setDragHourBegin(arr[1]);
            setOpenDropDialog(true);
          }
          if (arr.length === 3) {
            if (props.from === "location") {
              setDragIdLocation(arr[0]);
            } else {
              setDragIdLocation(thisIdLocation);
            }
            setDragDateReservation(arr[2]);
            setDragHourBegin(arr[1]);
            setOpenDropDialog(true);
          }
        }
      }
    }
  };

  const handleCloseUpdate = () => {
    setDragIdReservation("");
    setDragIdLocation("");
    setDragHourBegin("");
    setLoadUpdate(false);
    props.doReloadTable();
  };

  const handleCellActivityClick = (event) => {
    navigate(`/atividade/${event.currentTarget.id}`);
  };

  const handleCellLocationClick = (event) => {
    navigate(`/local/${event.currentTarget.id}`);
  };

  const handleCellVoidClick = (event) => {
    if (userRole > 5) {
      const arr = String(event.currentTarget.id).split("&");
      if (arr.length === 2) {
        const dateReservation = props.dateReservation;

        if (dateReservation && String(dateReservation) !== "Invalid Date") {
          const dateReservationURL = getFormattedDateURL(dateReservation);
          navigate(
            "/local/" +
              arr[0] +
              "/" +
              dateReservationURL +
              "/" +
              arr[1] +
              "/reserva"
          );
        }
      } else if (arr.length === 3) {
        if (props.from === "location") {
          navigate(`/local/${arr[0]}/${arr[2]}/${arr[1]}/reserva`);
        } else {
          navigate(`/atividade/${arr[0]}/${arr[2]}/${arr[1]}/reserva`);
        }
      }
    }
  };

  const GetActivityCell = (props) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [show, setShow] = useState(false);

    let nameDisplay = props.activity.activityName
      ? props.activity.activityName
      : props.activity.locationName;

    let nameSecondDisplay = props.activity.activityName
      ? Number(props.activity.requester)
        ? "Sem nome"
        : props.activity.requester.toUpperCase()
      : props.activity.blockName;

    if (nameDisplay === "null (null)") {
      nameDisplay = "Sem Local";
      nameSecondDisplay = "Local a definir";
    }

    const handleDragStart = (event) => {
      event.dataTransfer.setData("text", event.target.id);
    };

    const handleShow = () => {
      !show && setShow(true);
    };

    const handleNotShow = () => {
      props.doReloadTable();
    };

    const getCellGridHour = () => {
      let hour = props.index;
      let cellGrid = [];
      for (let index = 0; index < props.activity.colSpan; index++) {
        const hourDisplay = hour < 10 ? "0" + hour : hour;
        let id = "";
        const intervalDate = getConvertedDate(props.activity.intervalDate);
        if (props.from === "block") {
          id = `${props.resultValueId}&${hour}`;
        } else if (props.from === "activity") {
          id = `${props.activity.idLocation}&${hour}&${intervalDate}`;
        } else {
          id = `${props.resultValueId}&${hour}&${intervalDate}`;
        }
        cellGrid.push(
          <Box
            key={index}
            id={id}
            onDragOver={handleAllowDrop}
            onDrop={handleDragDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            width="100%"
            height="50px"
            sx={{
              pt: 2,
              border: 1,
              borderStyle: "dashed",
              borderColor: "white",
            }}
          >
            {hourDisplay}
          </Box>
        );
        hour++;
      }
      return cellGrid;
    };

    const handleOpenReservation = () => {
      navigate(`/reserva/${props.activity.idReservation}`);
    };

    const handleOpenDialog = () => {
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const participants = props.activity.participants;
    let participantsKey = "";
    if (participants) {
      participantsKey = participants.map((participant) => (
        <Box key={participant}>{participant.toUpperCase()}</Box>
      ));
    }

    const infoTitle = (
      <Typography>
        <Grid container rowSpacing={0} columnSpacing={0}>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <InfoTooltip
              placement="left"
              title={props.from === "activity" ? "Local" : "Atividade"}
            >
              {props.from === "activity" ? (
                nameDisplay !== "Sem Local" ? (
                  <IconButton
                    id={props.activity.idLocation}
                    onClick={handleCellLocationClick}
                  >
                    <AccountBalanceIcon />
                  </IconButton>
                ) : (
                  <AccountBalanceIcon />
                )
              ) : (
                <IconButton
                  id={props.activity.idActivity}
                  onClick={handleCellActivityClick}
                >
                  <AssignmentIcon />
                </IconButton>
              )}
            </InfoTooltip>
          </Grid>
          <Grid
            item
            xs={10}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {nameDisplay}
          </Grid>
          {props.from === "block" && (
            <>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <InfoTooltip placement="left" title={"Bloco/Local"}>
                  <AccountBalanceIcon />
                </InfoTooltip>
              </Grid>
              <Grid item xs={10}>
                {props.activity.blockLocation}
              </Grid>
            </>
          )}
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <InfoTooltip placement="left" title={"Data"}>
              <EventIcon />
            </InfoTooltip>
          </Grid>
          <Grid item xs={4}>
            {props.activity.intervalDate}
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            <InfoTooltip placement="left" title={"Horário"}>
              <AccessTimeIcon />
            </InfoTooltip>
          </Grid>
          <Grid item xs={4}>
            {props.activity.intervalHour}
          </Grid>
          {props.from !== "activity" && (
            <>
              <Grid item xs={2} sx={{ textAlign: "center" }}>
                <InfoTooltip placement="left" title={"Solicitante"}>
                  <PersonIcon fontSize="small" />
                </InfoTooltip>
              </Grid>
              <Grid item xs={10}>
                {props.activity.requester.toUpperCase()}
              </Grid>
            </>
          )}
        </Grid>
      </Typography>
    );

    const infoButtons = (
      <Box>
        <Typography variant="h6" sx={{ textAlign: "center" }}>
          Reserva
        </Typography>

        {infoTitle}
        {props.from !== "activity" && (
          <Grid container rowSpacing={0} columnSpacing={0}>
            <Grid item xs={2} sx={{ textAlign: "center" }}>
              <InfoTooltip placement="left" title={"Chave"}>
                <KeyIcon />
              </InfoTooltip>
            </Grid>
            <Grid item xs={10}>
              {participantsKey}
            </Grid>
          </Grid>
        )}
      </Box>
    );

    return (
      <>
        <StyledTableAgendaCell
          className="bodyCell"
          key={props.index}
          id={props.activity.idReservation}
          colSpan={props.activity.colSpan}
          onDragEnter={handleShow}
          onDragEnd={handleNotShow}
          sx={{
            bgcolor: getCellActivityBgcolor(props.activity.idActivityType),
          }}
        >
          <Box onDragEnter={handleShow}>
            {show && <Box sx={{ display: "flex" }}>{getCellGridHour()}</Box>}
            <Box id={props.activity.idReservation}>
              <Box id="icons">
                {userRole > 5 && (
                  <Grid
                    container
                    spacing={0}
                    columns={
                      props.activity.colSpan === 1 ? 2 : props.activity.colSpan
                    }
                  >
                    {nameDisplay !== "Sem Local" && (
                      <Grid item xs={1} sx={{ textAlign: "center" }}>
                        <Box
                          id={`${props.activity.idReservation}&${props.activity.colSpan}&${props.activity.idLocation}`}
                          draggable={userRole > 5 ? true : false}
                          onDragStart={handleDragStart}
                          sx={{
                            width: "100%",
                            color: "white",
                            "&:hover": {
                              cursor: "grab",
                              bgcolor: "black",
                            },
                          }}
                        >
                          <DragIndicatorIcon fontSize="inherit" />
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={1} sx={{ textAlign: "center" }}>
                      <InfoTooltip
                        key={props.index}
                        placement="top"
                        title={infoTitle}
                      >
                        <Box
                          onClick={handleOpenReservation}
                          sx={{
                            cursor: "pointer",
                            width: "100%",
                            "&:hover": {
                              bgcolor: "black",
                            },
                          }}
                        >
                          <InfoIcon fontSize="inherit" />
                        </Box>
                      </InfoTooltip>
                    </Grid>
                  </Grid>
                )}
              </Box>
              {userRole > 5 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      noWrap={true}
                      sx={{
                        width: getCellWidth(props.activity.colSpan),
                        fontSize: "inherit",
                        fontWeight: "inherit",
                      }}
                    >
                      {nameDisplay}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      noWrap={true}
                      sx={{
                        width: getCellWidth(props.activity.colSpan),
                        fontSize: "inherit",
                        fontWeight: "inherit",
                      }}
                    >
                      {nameSecondDisplay}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <InfoTooltip
                  key={props.index}
                  placement="top"
                  title={infoTitle}
                >
                  <Box
                    onClick={
                      userRole === 5 ? handleOpenReservation : handleOpenDialog
                    }
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        noWrap={true}
                        sx={{
                          width: getCellWidth(props.activity.colSpan),
                          fontSize: "inherit",
                          fontWeight: "inherit",
                        }}
                      >
                        {nameDisplay}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        noWrap={true}
                        sx={{
                          width: getCellWidth(props.activity.colSpan),
                          fontSize: "inherit",
                          fontWeight: "inherit",
                        }}
                      >
                        {nameSecondDisplay}
                      </Typography>
                    </Box>
                  </Box>
                </InfoTooltip>
              )}
            </Box>
          </Box>
        </StyledTableAgendaCell>

        <MessageDialog title="" open={openDialog} onClose={handleCloseDialog}>
          {infoButtons}
        </MessageDialog>
      </>
    );
  };

  const tableItems = props.items;
  let rows = [];
  tableItems.forEach((tableItem) => {
    const tableRows = tableItem.reservas;
    let cellsRow = [];
    tableRows.forEach((tableRow) => {
      if (tableRow.activity !== undefined) {
        cellsRow.push(
          <GetActivityCell
            key={tableRow.key}
            index={tableRow.key}
            resultValueId={tableRow.resultValueId}
            activity={tableRow.activity}
            from={props.from}
            doReloadTable={props.doReloadTable}
          />
        );
      } else {
        cellsRow.push(
          <StyledTableAgendaCell
            key={tableRow.key}
            id={tableRow.id}
            onDragOver={handleAllowDrop}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDragDrop}
            onClick={handleCellVoidClick}
            sx={{
              "&:hover": {
                cursor: userRole > 5 && "pointer",
              },
            }}
          ></StyledTableAgendaCell>
        );
      }
    });

    rows.push({
      nome: tableItem.nome,
      numero: tableItem.numero,
      reservas: cellsRow,
      id: tableItem.id,
    });
  });

  return (
    <Box sx={{ mb: 2 }}>
      <MessageDialog
        title="Atualizar Reserva?"
        open={openDropDialog}
        onClose={handleCloseDropDialog}
      >
        {dropButtons}
      </MessageDialog>
      <MessageDialog
        title="Atualização inválida!"
        open={openDropDeny}
        onClose={() => {
          setOpenDropDeny(false);
        }}
      >
        O fim da reserva não pode ser superior a 22h.
      </MessageDialog>
      <UpdateReservationDrop
        idReservation={dragIdReservation}
        idLocation={dragIdLocation}
        dateReservation={
          props.dateReservation
            ? getFormattedDateURL(props.dateReservation)
            : dragDateReservation
        }
        hourBegin={dragHourBegin}
        conflictInternal={"overwrite"}
        conflictExternal={"overwrite"}
        load={loadUpdate}
        handleClose={handleCloseUpdate}
      />
      <ActualTable rows={rows} />
    </Box>
  );
};

export default ReservationTable;
