import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ModifyDiscipline = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `disciplina/${props.idDiscipline}`;

      const request = {
        nome: props.name,
        descricao: props.description,
        codigo: props.disciplineId,
        cargaHoraria: props.disciplineDuration,
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível atualizar esta disciplina",
              "error"
            );
          } else if (result.affected === 0) {
            snackContext.setMessage(
              "Nada foi modificado. Modifique alguma informação ou clique em voltar.",
              "warning"
            );
          } else {
            snackContext.setMessage(`Atualização salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, useRef and navigate
  const [load, setLoad] = useState(false);

  const [idDiscipline, setIdDiscipline] = useState("");
  const [division, setDivision] = useState("");
  const [name, setName] = useState("");
  const [disciplineId, setDisciplineId] = useState("");
  const [disciplineDuration, setDisciplineDuration] = useState("");
  const [description, setDescription] = useState("");

  const [errorName, setErrorName] = useState(false);
  const [errorDisciplineId, setErrorDisciplineId] = useState(false);
  const [errorDisciplineDuration, setErrorDisciplineDuration] = useState(false);

  const refName = useRef(null);
  const refDisciplineId = useRef(null);
  const refDisciplineDuration = useRef(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const items = props.items;
    const discipline = props.discipline;

    setDivision(items.nome);
    setIdDiscipline(String(discipline.id));
    setName(String(discipline.nome));
    setDescription(String(discipline.descricao));
    setDisciplineId(String(discipline.codigo));
    setDisciplineDuration(String(discipline.cargaHoraria));
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  //#region handleChange
  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDisciplineId = (event) => {
    if (load) setLoad(false);
    if (errorDisciplineId) setErrorDisciplineId(false);
    setDisciplineId(event.target.value);
  };

  const handleChangeDisciplineDuration = (event) => {
    if (load) setLoad(false);
    if (errorDisciplineDuration) setErrorDisciplineDuration(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") setDisciplineDuration(newValue);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (!disciplineDuration) {
      refDisciplineDuration.current.focus();
      if (!errorDisciplineDuration) setErrorDisciplineDuration(true);
      alert("Por favor, preencha a Carga Horária da Disciplina.");
    } else {
      setLoad(true);
    }
  };

  return (
    <Box>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box sx={{ m: 1 }}>
          <strong>Divisão:</strong> {division}
        </Box>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 10 }}
              inputRef={refDisciplineId}
              error={errorDisciplineId}
              value={disciplineId}
              onChange={handleChangeDisciplineId}
              id="disciplineId"
              label="Código da Disciplina"
              name="disciplineId"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 3 }}
              inputRef={refDisciplineDuration}
              error={errorDisciplineDuration}
              value={disciplineDuration}
              onChange={handleChangeDisciplineDuration}
              id="disciplineDuration"
              label="Carga Horária"
              name="disciplineDuration"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyDiscipline
          idDiscipline={idDiscipline}
          name={name}
          description={description}
          disciplineId={disciplineId}
          disciplineDuration={disciplineDuration}
          load={load}
          handleClose={handleBack}
        />
      </Box>
    </Box>
  );
};

const GetDivision = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `divisao/${props.discipline.idDivisao}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} items={items} />;
};

const DisciplineItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `disciplina/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetDivision discipline={items} />;
};

const DisciplineUpdate = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Atualizar Disciplina"}
      icon={<EditIcon />}
    >
      <DisciplineItems {...props} />
    </PageContainer>
  );
};

export default DisciplineUpdate;
