import React, { useState, useEffect, useContext, forwardRef } from "react";

import { ActivityTypeContext } from "context/CommonProvider";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectActivityType = forwardRef((props, ref) => {
  const [activityType, setActivityType] = useState(null);
  const [errorActivityType, setErrorActivityType] = useState(null);

  const handleChange = (event, newValue) => {
    setActivityType(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setActivityType(props.dataParentToChild);
    setErrorActivityType(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  const activityTypeContext = useContext(ActivityTypeContext);
  const items = [];
  for (var [key, value] of Object.entries(activityTypeContext)) {
    items.push({ label: value, id: key });
  }

  return (
    <>
      <Autocomplete
        disableClearable
        value={activityType}
        onChange={handleChange}
        disablePortal
        id="activityType"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorActivityType}
            label="Tipo de Atividade"
          />
        )}
      />
    </>
  );
});

export default SelectActivityType;
