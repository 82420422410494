import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";

import { SnackContext } from "context/SnackProvider";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";
import { formatReservation } from "components/atoms/dateTime/GetFormattedDate";
import SelectParticipantKey from "components/atoms/select/SelectParticipantKey";
import LoanTable from "components/molecules/table/LoanTable";
import EquipmentLoanTable from "components/molecules/table/EquipmentLoanTable";

const DeleteReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `reserva/${props.id}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir esta reserva",
              "error"
            );
          } else {
            snackContext.setMessage("Reserva excluída com sucesso", "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [description, setDescription] = useState("");
  const [participant, setParticipant] = useState(null);
  const [errorParticipant, setErrorParticipant] = useState(false);
  const refParticipant = useRef(null);

  const userRole = GetUserRole();
  const navigate = useNavigate();

  const result = props.reservation;
  const reservation = result.reserva;
  const activity = result.atividade;
  const location = result.local;

  const reservationFormatted = formatReservation(reservation);
  const intervalHour = reservationFormatted[2];
  const intervalDate = reservationFormatted[3];

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const participantCallback = (childData) => {
    if (errorParticipant) setErrorParticipant(false);

    setParticipant(childData);
  };

  const handleClickEdit = (event) => {
    navigate("/reserva-atualiza/" + event.currentTarget.id);
  };

  //Delete
  const handleClickDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };

  const handleCellActivityClick = (event) => {
    navigate(`/atividade/${event.currentTarget.id}`);
  };

  const handleCellLocationClick = (event) => {
    navigate(`/local/${event.currentTarget.id}`);
  };

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 5 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-end" title="Editar">
            <IconButton onClick={handleClickEdit} id={reservation.id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="top-end" title="Excluir">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <Paper elevation={3} sx={{ p: 1 }}>
        <Box>
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box>
                <InfoTooltip placement="top" title={"Atividade"}>
                  <IconButton
                    size="small"
                    id={activity.id}
                    onClick={handleCellActivityClick}
                  >
                    <AssignmentIcon fontSize="small" />
                  </IconButton>
                </InfoTooltip>
                {activity.nome}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoTooltip placement="top" title={"Data"}>
                  <EventIcon fontSize="small" />
                </InfoTooltip>
                {intervalDate}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoTooltip placement="top" title={"Horário"}>
                  <AccessTimeIcon fontSize="small" />
                </InfoTooltip>
                {intervalHour}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {location.nome ? (
                  <InfoTooltip placement="top" title={"Local"}>
                    <IconButton
                      size="small"
                      id={location.id}
                      onClick={handleCellLocationClick}
                    >
                      <AccountBalanceIcon fontSize="small" />
                    </IconButton>
                  </InfoTooltip>
                ) : (
                  <InfoTooltip placement="top" title={"Local"}>
                    <Box sx={{ ml: 0.7, mr: 0.7 }}>
                      <AccountBalanceIcon fontSize="small" />
                    </Box>
                  </InfoTooltip>
                )}
                {location.nome
                  ? `${location.numero} - ${location.nome}`
                  : "Sem local"}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoTooltip placement="top" title={"Solicitante"}>
                  <PersonIcon fontSize="small" />
                </InfoTooltip>
                {activity.nomeSolicitante}
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <InfoTooltip placement="top" title={"Participantes"}>
                  <GroupIcon fontSize="small" />
                </InfoTooltip>
                {activity.participantes}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={{ mt: 2 }}>
        <EquipmentLoanTable
          idReservation={reservation.id}
          return={true}
          load={true}
        />
      </Box>
      <Box sx={{ mt: 1 }}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectParticipantKey
              idActivity={activity.id}
              dataParentToChild={participant}
              parentCallback={participantCallback}
              ref={refParticipant}
              errorParentToChild={errorParticipant}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="descricao"
              label="Descrição"
              name="descricao"
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <LoanTable
          idReservation={reservation.id}
          participant={participant}
          description={description}
        />
      </Box>
      <Box id="boxDelete">
        <DeleteReservation
          loadDelete={loadDelete}
          id={reservation.id}
          handleClose={handleBack}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={<>Você tem certeza que gostaria de excluir esta reserva?</>}
        />
      </Box>
    </>
  );
};

const GetReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `reserva/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return <Fields {...props} reservation={items} />;
  }
};

const Reservation = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Reserva"}
      icon={<AccountBalanceIcon />}
    >
      <GetReservation {...props} />
    </PageContainer>
  );
};

Reservation.defaultProps = {
  idReservation: undefined,
};

Reservation.propTypes = {
  idReservation: PropTypes.string,
};

export default Reservation;
