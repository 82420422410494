import React from "react";
import { useParams } from "react-router-dom";

import UnitPage from "components/pages/UnitDivision/Unit";

const Unit = () => {
  const { id } = useParams();

  return <UnitPage id={id} />;
};

export default Unit;
