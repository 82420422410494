import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "components/pages/Login";
import ButtonAppBar from "components/organisms/container/ButtonAppBar";

import ReservationCalendar from "components/pages/ReservationEquipment/ReservationCalendar";

import BlockList from "components/pages/BlockLocation/BlockList";
import BlockNew from "components/pages/BlockLocation/BlockNew";
import BlockUpdate from "routes/BlockLocation/blockUpdate";
import Block from "routes/BlockLocation/block";

import LocationNew from "routes/BlockLocation/locationNew";
import LocationUpdate from "routes/BlockLocation/locationUpdate";
import Location from "routes/BlockLocation/location";

import ResourceList from "components/pages/ResourceOccurrence/ResourceList";
import ResourceNew from "components/pages/ResourceOccurrence/ResourceNew";
import ResourceUpdate from "routes/ResourceOccurrence/resourceUpdate";
import Resource from "routes/ResourceOccurrence/resource";

import ActivityList from "components/pages/ActivityDiscipline/ActivityList";
import ActivityNew from "components/pages/ActivityDiscipline/ActivityNew";
import ActivityUpdate from "routes/ActivityDiscipline/activityUpdate";
import Activity from "routes/ActivityDiscipline/activity";

import UnitList from "components/pages/UnitDivision/UnitList";
import UnitNew from "components/pages/UnitDivision/UnitNew";
import UnitUpdate from "routes/UnitDivision/unitUpdate";
import Unit from "routes/UnitDivision/unit";

import DivisionNew from "routes/UnitDivision/divisionNew";
import DivisionUpdate from "routes/UnitDivision/divisionUpdate";
import Division from "routes/UnitDivision/division";

import DisciplineNew from "routes/ActivityDiscipline/disciplineNew";
import DisciplineUpdate from "routes/ActivityDiscipline/disciplineUpdate";
import Discipline from "routes/ActivityDiscipline/discipline";

import Reservation from "routes/ReservationEquipment/reservation";
import ReservationNew from "routes/ReservationEquipment/reservationNew";
import ReservationUpdate from "routes/ReservationEquipment/reservationUpdate";
import ReservationUpdateDrop from "routes/ReservationEquipment/reservationUpdateDrop";
import ReservationConflict from "components/pages/ReservationEquipment/ReservationConflict";

import EquipmentList from "components/pages/ReservationEquipment/EquipmentList";
import EquipmentNew from "components/pages/ReservationEquipment/EquipmentNew";
import EquipmentUpdate from "routes/ReservationEquipment/equipmentUpdate";
import Loan from "components/pages/ReservationEquipment/Loan";

import PersonList from "components/pages/UserPerson/PersonList";
import PersonNew from "components/pages/UserPerson/PersonNew";
import PersonUpdate from "routes/UserPerson/personUpdate";
import Person from "routes/UserPerson/person";

import UserList from "components/pages/UserPerson/UserList";
import UserNew from "components/pages/UserPerson/UserNew";
import Error404 from "routes/error404";
import OldSystem from "components/pages/OldSystem";
import OldLogin from "components/pages/OldLogin";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ButtonAppBar />}>
        <Route index element={<ReservationCalendar />} />
        <Route path="/reserva" element={<ReservationCalendar />} />
        <Route path="/login.php" element={<OldLogin />} />
        <Route path="/horario.php" element={<OldSystem />} />

        <Route path="/login" element={<Login />} />

        <Route path="/bloco" element={<BlockList />} />
        <Route path="/bloco-novo" element={<BlockNew />} />
        <Route path="/bloco-atualiza/:id" element={<BlockUpdate />} />
        <Route path="/bloco/:id" element={<Block />} />
        <Route path="/bloco/:id/local-novo" element={<LocationNew />} />
        <Route path="/local/:id" element={<Location />} />
        <Route path="/local/:id/:tab" element={<Location />} />
        <Route path="/local-atualiza/:id" element={<LocationUpdate />} />

        <Route path="/recurso" element={<ResourceList />} />
        <Route path="/recurso-novo" element={<ResourceNew />} />
        <Route path="/recurso-atualiza/:id" element={<ResourceUpdate />} />
        <Route path="/recurso/:id" element={<Resource />} />

        <Route path="/atividade" element={<ActivityList />} />
        <Route path="/atividade-nova" element={<ActivityNew />} />
        <Route path="/atividade-atualiza/:id" element={<ActivityUpdate />} />
        <Route path="/atividade/:id" element={<Activity />} />
        <Route path="/atividade/:id/:tab" element={<Activity />} />

        <Route path="/reserva/:id" element={<Reservation />} />
        <Route
          path="/:name/:id/:date/:hour/reserva"
          element={<ReservationNew />}
        />
        <Route path="/reserva-atualiza/:id" element={<ReservationUpdate />} />
        <Route
          path="/reserva-atualiza/:id/:idLocation/:date/:hour"
          element={<ReservationUpdateDrop />}
        />
        <Route path="/reserva-resposta" element={<ReservationConflict />} />

        <Route path="/emprestavel" element={<EquipmentList />} />
        <Route path="/emprestavel-novo" element={<EquipmentNew />} />
        <Route path="/emprestavel-atualiza/:id" element={<EquipmentUpdate />} />
        <Route path="/emprestimos" element={<Loan />} />

        <Route path="/unidade" element={<UnitList />} />
        <Route path="/unidade-nova" element={<UnitNew />} />
        <Route path="/unidade-atualiza/:id" element={<UnitUpdate />} />
        <Route path="/unidade/:id" element={<Unit />} />

        <Route path="/unidade/:id/divisao-nova" element={<DivisionNew />} />
        <Route path="/divisao-atualiza/:id" element={<DivisionUpdate />} />
        <Route path="/divisao/:id" element={<Division />} />

        <Route
          path="/divisao/:id/disciplina-nova"
          element={<DisciplineNew />}
        />
        <Route path="/disciplina-atualiza/:id" element={<DisciplineUpdate />} />
        <Route path="/disciplina/:id" element={<Discipline />} />

        <Route path="/pessoa" element={<PersonList />} />
        <Route path="/pessoa-nova" element={<PersonNew />} />
        <Route path="/pessoa-atualiza/:userId" element={<PersonUpdate />} />
        <Route path="/pessoa/:userId" element={<Person />} />

        <Route path="/usuario" element={<UserList />} />
        <Route path="/usuario-novo" element={<UserNew />} />
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
