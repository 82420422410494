import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import DisciplineTable from "components/molecules/table/DisciplineTable";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const DivisionDiscipline = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `divisao/${props.id}/disciplina`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(sortListByName(result));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.id) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      if (items.length > 0) {
        return <DisciplineTable rows={items} />;
      } else {
        return (
          <Typography sx={{ color: "error.main" }}>
            Nenhuma disciplina cadastrada.
          </Typography>
        );
      }
    }
  }
};

DivisionDiscipline.propTypes = {
  id: PropTypes.string,
};

export default DivisionDiscipline;
