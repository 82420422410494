import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { addDays } from "date-fns";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import HelpTooltip from "components/atoms/info/HelpTooltip";

const SwitchRepeat = (props) => {
  const [checkedRepeat, setCheckedRepeat] = useState(false);
  const [weekDays, setWeekDays] = useState(() => []);
  const [dateRepeatEnd, setDateRepeatEnd] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);
  const [repeat, setRepeat] = useState("daily");
  const [jump, setJump] = useState(1);

  useEffect(() => {
    setCheckedRepeat(props.checkedParentToChild);
    setWeekDays(props.weekDaysParentToChild);
    setRepeat(props.repeatParentToChild);
    setJump(props.jumpParentToChild);
    setDateRepeatEnd(props.dateRepeatEndParentToChild);
    setDateReservation(props.dateReservationParentToChild);
  }, [props]);

  const handleChangeCheckConflict = (event) => {
    const newValue = event.target.checked;
    setCheckedRepeat(newValue);
    props.checkedParentCallback(newValue);
  };

  const handleChangeWeekDays = (event, newValue) => {
    setWeekDays(newValue);
    props.weekDaysParentCallback(newValue);
  };

  const handleChangeRepeat = (event) => {
    const newValue = event.target.value;
    setRepeat(newValue);
    props.repeatParentCallback(newValue);
  };

  function incrementJump() {
    const newJump = (jump) => jump + 1;
    setJump(newJump);
    props.jumpParentCallback(newJump);
  }

  function decrementJump() {
    const newJump = (jump) => jump - 1;
    if (jump > 1) {
      setJump(newJump);
      props.jumpParentCallback(newJump);
    }
  }

  const handleChangeDateRepeatEnd = (newValue) => {
    setDateRepeatEnd(newValue);
    props.dateRepeatEndParentCallback(newValue);
  };

  let jumpText = "";
  if (jump === 1) {
    if (repeat === "daily") jumpText = "Todo dia ";
    else if (repeat === "weekly") jumpText = "Toda semana ";
    else jumpText = "Todo mês ";
  } else {
    jumpText = `A cada ${jump} `;
    if (repeat === "daily") jumpText += "dias ";
    else if (repeat === "weekly") jumpText += "semanas ";
    else jumpText += "meses ";
  }

  return (
    <>
      <Switch checked={checkedRepeat} onChange={handleChangeCheckConflict} />
      Selecionar Repetição de Reservas
      {checkedRepeat && (
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "primary.main",
            pl: 2,
            pr: 2,
            pb: 2,
          }}
        >
          <HelpTooltip placement="top-end">
            <Typography>Dúvidas?</Typography>
          </HelpTooltip>
          <Box>
            <FormControl id="formRepeat" sx={{ ml: 1 }}>
              <RadioGroup
                row
                aria-labelledby="radio-group"
                name="controlled-radio-group"
                value={repeat}
                onChange={handleChangeRepeat}
              >
                <FormControlLabel
                  value="daily"
                  control={<Radio />}
                  label="Diária"
                />
                <FormControlLabel
                  value="weekly"
                  control={<Radio />}
                  label="Semanal"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio />}
                  label="Mensal"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {repeat === "weekly" && (
            <Box sx={{ mt: 2 }}>
              <ToggleButtonGroup
                color="primary"
                value={weekDays}
                onChange={handleChangeWeekDays}
                aria-label="weekDays"
              >
                <ToggleButton
                  value={0}
                  aria-label="0"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Domingo" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">D</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={1}
                  aria-label="1"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Segunda" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">S</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={2}
                  aria-label="2"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Terça" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">T</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={3}
                  aria-label="3"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Quarta" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">Q</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={4}
                  aria-label="4"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Quinta" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">Q</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={5}
                  aria-label="5"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Sexta" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">S</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value={6}
                  aria-label="6"
                  sx={{ height: 50, p: 0 }}
                >
                  <Tooltip title="Sábado" followCursor>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6">S</Typography>
                    </Box>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid item xs={12} md={4} lg={6}>
                <Box
                  id="boxJump"
                  sx={{
                    pt: 1,
                    pb: 1,
                    pr: 1,
                    pl: 2,
                    border: 1,
                    borderColor: "gray",
                    borderRadius: 2,
                    display: "inline-flex",
                  }}
                >
                  <Typography sx={{ pt: 1 }}>{jumpText}</Typography>
                  <IconButton onClick={decrementJump}>
                    <RemoveCircleIcon />
                  </IconButton>
                  <IconButton onClick={incrementJump}>
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={6}>
                <ButtonDatePicker
                  value={dateRepeatEnd}
                  onChange={handleChangeDateRepeatEnd}
                  minDate={addDays(dateReservation, 1)}
                  id="dateRepeatEnd"
                  label="Data final da Repetição"
                  name="dateRepeatEnd"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

SwitchRepeat.defaultProps = {
  checkedParentToChild: false,
  weekDaysParentToChild: [],
  repeatParentToChild: "daily",
  jumpParentToChild: 1,
  dateRepeatEndParentToChild: undefined,
  dateReservationParentToChild: undefined,
  checkedParentCallback: () => {},
  weekDaysParentCallback: () => {},
  repeatParentCallback: () => {},
  dateRepeatEndParentCallback: () => {},
};

SwitchRepeat.propTypes = {
  checkedParentToChild: PropTypes.bool,
  weekDays: PropTypes.array,
  repeat: PropTypes.string,
  jump: PropTypes.number,
  dateRepeatEndParentToChild: PropTypes.instanceOf(Date),
  dateReservationParentToChild: PropTypes.instanceOf(Date),
  checkedParentCallback: PropTypes.func,
  weekDaysParentCallback: PropTypes.func,
  repeatParentCallback: PropTypes.func,
  dateRepeatEndParentCallback: PropTypes.func,
};

export default SwitchRepeat;
