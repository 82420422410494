import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";

import { CookiesProvider } from "react-cookie";
import { addDays } from "date-fns";

import GlobalStyle from "styles/GlobalStyle";
import ThemeProvider from "styles/ThemeProvider";
import CommonProvider from "context/CommonProvider";
import SnackProvider from "context/SnackProvider";

function App() {
  const dateToday = new Date();
  const dateExpires = addDays(dateToday, 1);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <CookiesProvider
        defaultSetOptions={{
          path: "/",
          expires: dateExpires,
          sameSite: "Strict",
        }}
      >
        <GlobalStyle />
        <ThemeProvider>
          <CommonProvider>
            <SnackProvider>
              <Router>
                <Routes />
              </Router>
            </SnackProvider>
          </CommonProvider>
        </ThemeProvider>
      </CookiesProvider>
    </LocalizationProvider>
  );
}

export default App;
