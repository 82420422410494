import React, { useState, useEffect, useContext } from "react";
import { differenceInDays } from "date-fns";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ToggleButton from "@mui/material/ToggleButton";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import EventNoteIcon from "@mui/icons-material/EventNote";
import SchoolIcon from "@mui/icons-material/School";

import SelectActivityType from "components/atoms/select/SelectActivityType";
import SelectUnit from "components/atoms/select/SelectUnit";
import SelectDivisionDiscipline from "components/atoms/select/SelectDivisionDiscipline";
import SelectDiscipline from "components/atoms/select/SelectDiscipline";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage, GetUserRole } from "api/definitions";
import Pagination from "components/atoms/table/Pagination";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import ActivityCard from "components/molecules/card/ActivityCard";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import { ActivityTypeContext } from "context/CommonProvider";
import { getFormattedDateURL } from "components/atoms/dateTime/GetFormattedDate";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const CardList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const snackContext = useContext(SnackContext);
  const activityTypeContext = useContext(ActivityTypeContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    const idActivityType = props.activityType && props.activityType.id;

    const idUnit = props.unit && props.unit.id;
    const idDivision = props.divisionDiscipline && props.divisionDiscipline.id;
    const idDiscipline = props.discipline && props.discipline.id;

    const userIdSolicitante = props.idRequester ? props.idRequester : null;
    const userIdCriador = props.userCreator ? props.userCreator : null;

    let periodoReserva1 = null;
    let periodoReserva2 = null;
    let dataCriacao1 = null;
    let dataCriacao2 = null;
    if (props.checkedReservation && props.dateBegin && props.dateEnd) {
      periodoReserva1 = getFormattedDateURL(props.dateBegin);
      periodoReserva2 = getFormattedDateURL(props.dateEnd);
    }
    if (
      props.checkedRegistered &&
      props.dateRegisteredBegin &&
      props.dateRegisteredEnd
    ) {
      dataCriacao1 = getFormattedDateURL(props.dateRegisteredBegin);
      dataCriacao2 = getFormattedDateURL(props.dateRegisteredEnd);
    }

    const request = {
      nome: props.name,
      userIdCriador,
      userIdSolicitante,
      idAtividadeTipo: idActivityType,
      periodoReserva1,
      periodoReserva2,
      dataCriacao1,
      dataCriacao2,
      idUnidade: idUnit,
      idDivisao: idDivision,
      idDisciplina: idDiscipline,
    };

    instance
      .post("atividade/search", request)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, activityTypeContext]);

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ m: 1, color: "error.main" }}>
          Não há atividade para esta pesquisa.
        </Typography>
      );
    } else {
      const cards = (
        rowsPerPage > 0
          ? items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : items
      ).map((item) => (
        <ActivityCard
          list={true}
          key={item.id}
          idActivity={item.id}
          activityType={activityTypeContext[item.idAtividadeTipo]}
          name={item.nome}
          description={item.descricao}
          userIdCreator={item.userIdCriador}
          userIdRequester={item.userIdSolicitante}
          userCreator={item.nomeCriador}
          userRequester={item.nomeSolicitante}
          participants={item.participantes}
          division={item.divisao}
        />
      ));
      return (
        <>
          <Box>{cards}</Box>
          <Pagination
            name="Atividades"
            length={items.length}
            pageParentToChild={page}
            rowsPerPageParentToChild={rowsPerPage}
            pageCallback={pageCallback}
            rowsPerPageCallback={rowsPerPageCallback}
          />
        </>
      );
    }
  }
};

const ActivityCardList = (props) => {
  const dateNow = new Date();

  const [load, setLoad] = useState(false);

  const [name, setName] = useState("");
  const [activityType, setActivityType] = useState(null);
  const [idRequester, setIdRequester] = useState("");
  const [userCreator, setUserCreator] = useState("");
  const [unit, setUnit] = useState(null);
  const [divisionDiscipline, setDivisionDiscipline] = useState(null);
  const [discipline, setDiscipline] = useState(null);
  const [dateBegin, setDateBegin] = useState(dateNow);
  const [dateEnd, setDateEnd] = useState(dateNow);
  const [dateRegisteredBegin, setDateRegisteredBegin] = useState(dateNow);
  const [dateRegisteredEnd, setDateRegisteredEnd] = useState(dateNow);

  const [checkedActivity, setCheckedActivity] = useState(true);
  const [checkedRequester, setCheckedRequester] = useState(false);
  const [checkedRegistered, setCheckedRegistered] = useState(false);
  const [checkedReservation, setCheckedReservation] = useState(false);
  const [checkedOrg, setCheckedOrg] = useState(false);

  const userRole = GetUserRole();

  const handleCleanLoad = (event) => {
    load && setLoad(false);
  };

  const handleChangeName = (event) => {
    handleCleanLoad();
    setName(event.target.value);
  };

  const activityTypeCallback = (childData) => {
    handleCleanLoad();
    setActivityType(childData);
  };

  const handleChangeIdRequester = (event) => {
    handleCleanLoad();
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setIdRequester(newValue);
  };

  const handleChangeUserCreator = (event) => {
    handleCleanLoad();
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setUserCreator(newValue);
  };

  const unitCallback = (childData) => {
    handleCleanLoad();
    disciplineCallback(null);
    divisionDisciplineCallback(null);
    setUnit(childData);
  };

  const divisionDisciplineCallback = (childData) => {
    handleCleanLoad();
    disciplineCallback(null);
    setDivisionDiscipline(childData);
  };

  const disciplineCallback = (childData) => {
    handleCleanLoad();
    setDiscipline(childData);
  };

  const handleChangeCheckActivity = () => {
    setCheckedActivity(!checkedActivity);
    handleCleanLoad();
    handleCleanActivity();
  };

  const handleChangeCheckRequester = () => {
    setCheckedRequester(!checkedRequester);
    handleCleanLoad();
    handleCleanRequester();
  };

  const handleChangeCheckRegistered = () => {
    setCheckedRegistered(!checkedRegistered);
    handleCleanLoad();
    handleCleanRegistered();
  };

  const handleChangeCheckReservation = () => {
    setCheckedReservation(!checkedReservation);
    handleCleanLoad();
    handleCleanReservation();
  };

  const handleChangeCheckOrg = () => {
    setCheckedOrg(!checkedOrg);
    handleCleanLoad();
    handleCleanOrg();
  };

  const handleChangeDateBegin = (newValue) => {
    setDateBegin(newValue);
    handleCleanLoad();
  };

  const handleChangeDateEnd = (newValue) => {
    setDateEnd(newValue);
    handleCleanLoad();
  };

  const handleChangeDateRegisteredBegin = (newValue) => {
    setDateRegisteredBegin(newValue);
    handleCleanLoad();
  };

  const handleChangeDateRegisteredEnd = (newValue) => {
    setDateRegisteredEnd(newValue);
    handleCleanLoad();
  };

  const handleCleanActivity = (event) => {
    setName("");
    activityTypeCallback(null);
  };

  const handleCleanRequester = (event) => {
    setIdRequester("");
    setUserCreator("");
  };

  const handleCleanOrg = (event) => {
    unitCallback(null);
    divisionDisciplineCallback(null);
    disciplineCallback(null);
  };

  const handleCleanReservation = (event) => {
    setDateBegin(dateNow);
    setDateEnd(dateNow);
  };

  const handleCleanRegistered = (event) => {
    setDateRegisteredBegin(dateNow);
    setDateRegisteredEnd(dateNow);
  };

  const handleClean = (event) => {
    handleCleanLoad();

    setCheckedActivity(true);
    setCheckedOrg(false);
    setCheckedRequester(false);
    setCheckedRegistered(false);
    setCheckedReservation(false);

    handleCleanActivity();
    handleCleanRequester();
    handleCleanOrg();
    handleCleanReservation();
    handleCleanRegistered();
  };

  const handleClickSearch = (event) => {
    if (differenceInDays(dateBegin, dateEnd) > 0) {
      alert("A data fim não pode ser menor que a data início.");
    } else {
      if (differenceInDays(dateRegisteredBegin, dateRegisteredEnd) > 0) {
        alert("A data fim não pode ser menor que a data início.");
      } else {
        setLoad(true);
      }
    }
  };

  return (
    <>
      <Box>
        <InfoTooltip title="Pesquisar" placement="top">
          <IconButton
            variant="contained"
            onClick={handleClickSearch}
            sx={{ mr: 1 }}
          >
            <SearchIcon />
          </IconButton>
        </InfoTooltip>
        Filtrar por:
        <InfoTooltip title="nome - tipo" placement="top">
          <ToggleButton
            size="small"
            value={0}
            selected={checkedActivity}
            onChange={handleChangeCheckActivity}
            sx={{ m: 1 }}
          >
            <AssignmentIcon />
          </ToggleButton>
        </InfoTooltip>
        {userRole > 4 && (
          <InfoTooltip title="solicitante - usuário" placement="top">
            <ToggleButton
              size="small"
              value={1}
              selected={checkedRequester}
              onChange={handleChangeCheckRequester}
              sx={{ m: 1 }}
            >
              <PersonIcon />
            </ToggleButton>
          </InfoTooltip>
        )}
        <InfoTooltip title="disciplina" placement="top">
          <ToggleButton
            size="small"
            value={2}
            selected={checkedOrg}
            onChange={handleChangeCheckOrg}
            sx={{ m: 1 }}
          >
            <SchoolIcon />
          </ToggleButton>
        </InfoTooltip>
        {userRole > 4 && (
          <InfoTooltip title="período de cadastro" placement="top">
            <ToggleButton
              size="small"
              value={3}
              selected={checkedRegistered}
              onChange={handleChangeCheckRegistered}
              sx={{ m: 1 }}
            >
              <DateRangeIcon />
            </ToggleButton>
          </InfoTooltip>
        )}
        <InfoTooltip title="período com reservas" placement="top">
          <ToggleButton
            size="small"
            value={4}
            selected={checkedReservation}
            onChange={handleChangeCheckReservation}
            sx={{ m: 1 }}
          >
            <EventNoteIcon />
          </ToggleButton>
        </InfoTooltip>
        <InfoTooltip title="Reiniciar filtro" placement="top">
          <IconButton variant="contained" onClick={handleClean}>
            <RefreshIcon />
          </IconButton>
        </InfoTooltip>
        <Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {checkedActivity && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    value={name}
                    onChange={handleChangeName}
                    id="name"
                    label="Nome"
                    name="name"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SelectActivityType
                    dataParentToChild={activityType}
                    parentCallback={activityTypeCallback}
                  />
                </Grid>
              </>
            )}
            {checkedRequester && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    value={idRequester}
                    onChange={handleChangeIdRequester}
                    id="idRequester"
                    label="Solicitante (CPF)"
                    name="idRequester"
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    value={userCreator}
                    onChange={handleChangeUserCreator}
                    id="userCreator"
                    label="Usuário (CPF)"
                    name="userCreator"
                    autoComplete="off"
                  />
                </Grid>
              </>
            )}
            {checkedOrg && (
              <>
                <Grid item xs={12} lg={2}>
                  <SelectUnit
                    dataParentToChild={unit}
                    parentCallback={unitCallback}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SelectDivisionDiscipline
                    idUnit={unit ? unit.id : 0}
                    dataParentToChild={divisionDiscipline}
                    parentCallback={divisionDisciplineCallback}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SelectDiscipline
                    idDivision={divisionDiscipline ? divisionDiscipline.id : 0}
                    dataParentToChild={discipline}
                    parentCallback={disciplineCallback}
                  />
                </Grid>
              </>
            )}
            {checkedRegistered && (
              <Grid item xs={12} lg={6}>
                <Box sx={{ mt: 1 }}>
                  <ButtonDatePicker
                    sx={{ width: 150, mr: 3 }}
                    value={dateRegisteredBegin}
                    onChange={handleChangeDateRegisteredBegin}
                    id="dateBegin"
                    label="Cadastro início"
                    name="dateBegin"
                  />
                  <ButtonDatePicker
                    sx={{ width: 150 }}
                    value={dateRegisteredEnd}
                    disabled={!dateRegisteredBegin}
                    onChange={handleChangeDateRegisteredEnd}
                    id="dateEnd"
                    label="Cadastro fim"
                    name="dateEnd"
                  />
                </Box>
              </Grid>
            )}
            {checkedReservation && (
              <Grid item xs={12} lg={6}>
                <Box sx={{ mt: 1 }}>
                  <ButtonDatePicker
                    sx={{ width: 150, mr: 3 }}
                    value={dateBegin}
                    onChange={handleChangeDateBegin}
                    id="dateBegin"
                    label="Reservas início"
                    name="dateBegin"
                  />
                  <ButtonDatePicker
                    sx={{ width: 150 }}
                    value={dateEnd}
                    disabled={!dateBegin}
                    onChange={handleChangeDateEnd}
                    id="dateEnd"
                    label="Reservas fim"
                    name="dateEnd"
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {load && (
        <Box sx={{ mt: 2 }}>
          <CardList
            name={name}
            activityType={activityType}
            idRequester={idRequester}
            userCreator={userCreator}
            unit={unit}
            divisionDiscipline={divisionDiscipline}
            discipline={discipline}
            checkedReservation={checkedReservation}
            dateBegin={dateBegin}
            dateEnd={dateEnd}
            checkedRegistered={checkedRegistered}
            dateRegisteredBegin={dateRegisteredBegin}
            dateRegisteredEnd={dateRegisteredEnd}
          />
        </Box>
      )}
    </>
  );
};

export default ActivityCardList;
