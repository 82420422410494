import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";

const Fields = (props) => {
  const [division, setDivision] = useState("");
  const [name, setName] = useState("");
  const [disciplineId, setDisciplineId] = useState("");
  const [disciplineDuration, setDisciplineDuration] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const items = props.items;
    const discipline = props.discipline;

    setDivision(items.nome);
    setName(String(discipline.nome));
    setDescription(String(discipline.descricao));
    setDisciplineId(String(discipline.codigo));
    setDisciplineDuration(String(discipline.cargaHoraria));
  }, [props]);

  return (
    <Card raised sx={{ mb: 1, minWidth: 300 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              <strong>Nome:</strong> {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Código:</strong> {disciplineId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Divisão:</strong> {division}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Carga Horária:</strong> {`${disciplineDuration}h`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Descrição:</strong>{" "}
              {description ? description : "(Não informada)"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const GetDivision = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `divisao/${props.discipline.idDivisao}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} items={items} />;
};

const DisciplineCard = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `disciplina/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetDivision {...props} discipline={items} />;
};

DisciplineCard.defaultProps = {
  idDivision: 0,
};

DisciplineCard.propTypes = {
  idDivision: PropTypes.number,
};

export default DisciplineCard;
