import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import KeyIcon from "@mui/icons-material/Key";

import { GetUserRole } from "api/definitions";
import MenuAccount from "components/atoms/menu/MenuAccount";
import DrawerNotification from "components/molecules/menu/DrawerNotification";
import DrawerReservationLocation from "components/molecules/menu/DrawerReservationLocation";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const MenuPrimary = () => {
  const userRole = GetUserRole();
  const navigate = useNavigate();

  const handleClickLoan = () => {
    navigate("/emprestimos");
  };

  return (
    <Grid container columnSpacing={1}>
      <Grid item>
        <InfoTooltip placement="bottom-end" title="Empréstimos">
          <IconButton
            onClick={handleClickLoan}
            size="large"
            color="inherit"
            aria-label="menu"
            sx={{
              width: 40,
              height: 40,
              "&:hover": {
                color: "primary.main",
                backgroundColor: "white",
              },
            }}
          >
            <KeyIcon sx={{ width: 28, height: 28 }} />
          </IconButton>
        </InfoTooltip>
      </Grid>
      {userRole > 5 && (
        <Grid item>
          <DrawerReservationLocation />
        </Grid>
      )}
      <Grid item>
        <DrawerNotification />
      </Grid>
      <Grid item>
        <Box sx={{ ml: 2 }}>
          <MenuAccount />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MenuPrimary;
