import React, { useState, useEffect, forwardRef } from "react";

import { addYears } from "date-fns";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectSemester = forwardRef((props, ref) => {
  const [items, setItems] = useState([]);
  const [semester, setSemester] = useState(null);
  const [errorSemester, setErrorSemester] = useState(null);

  const handleChange = (event, newValue) => {
    setSemester(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setSemester(props.dataParentToChild);
    setErrorSemester(props.errorParentToChild);

    const dateToday = new Date();
    const dateNext = addYears(dateToday, 1);
    const datePrevious = addYears(dateToday, -1);
    const yearThis = dateToday.getFullYear();
    const yearNext = dateNext.getFullYear();
    const yearPrevious = datePrevious.getFullYear();

    const semester0 = `${yearPrevious}.1`;
    const semester1 = `${yearPrevious}.2`;
    const semester2 = `${yearThis}.1`;
    const semester3 = `${yearThis}.2`;
    const semester4 = `${yearNext}.1`;
    const semester5 = `${yearNext}.2`;

    const semesters = [];
    semesters.push({ label: semester0, id: semester0 });
    semesters.push({ label: semester1, id: semester1 });
    semesters.push({ label: semester2, id: semester2 });
    semesters.push({ label: semester3, id: semester3 });
    semesters.push({ label: semester4, id: semester4 });
    semesters.push({ label: semester5, id: semester5 });
    setItems(semesters);
  }, [props.dataParentToChild, props.errorParentToChild]);

  return (
    <Autocomplete
      disableClearable
      value={semester}
      onChange={handleChange}
      disablePortal
      id="semester"
      options={items}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) =>
        value === undefined ||
        value === "" ||
        option.id === value.id ||
        option.nome === value.nome
      }
      noOptionsText={"Nenhum cadastrado"}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          inputRef={ref}
          error={errorSemester}
          label="Semestre"
        />
      )}
    />
  );
});

export default SelectSemester;
