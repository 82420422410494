import React from "react";
import { useParams } from "react-router-dom";

import DisciplinePageNew from "components/pages/ActivityDiscipline/DisciplineNew";

const DisciplineNew = () => {
  const { id } = useParams();

  return <DisciplinePageNew id={id} />;
};

export default DisciplineNew;
