import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import PageContainer from "components/templates/PageContainer";
import ResourceCard from "components/molecules/card/ResourceCard";
import ResourceLocationTable from "components/molecules/table/ResourceLocationTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteResource = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `recurso/${props.id}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir este recurso",
              "error"
            );
          } else {
            snackContext.setMessage("Recurso excluído com sucesso", "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const userRole = GetUserRole();

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [checkedAccessible, setCheckedAccessible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const resource = props.resource;
    setId(String(resource.id));
    setName(String(resource.nome));
    const accessible = resource.pcd ? true : false;
    setCheckedAccessible(accessible);
  }, [props.resource]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClickEdit = (event) => {
    navigate("/recurso-atualiza/" + event.currentTarget.id);
  };

  //Delete
  const handleClickDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 5 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-end" title="Editar">
            <IconButton onClick={handleClickEdit} id={id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="top-end" title="Excluir">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <ResourceCard
        idResource={id}
        name={name}
        accessible={checkedAccessible ? "1" : "0"}
      />
      <Box>
        <ResourceLocationTable id={props.id} />
      </Box>
      <Box id="boxDelete">
        <DeleteResource
          loadDelete={loadDelete}
          id={id}
          handleClose={handleBack}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir este Recurso?
              <br />
              {`Recurso: ${name}`}
            </>
          }
        />
      </Box>
    </>
  );
};

const GetResource = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `recurso/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} resource={items} />;
};

const Resource = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Recurso"} icon={<ChairAltIcon />}>
      <GetResource {...props} />
    </PageContainer>
  );
};

Resource.defaultProps = {
  idResource: undefined,
};

Resource.propTypes = {
  idResource: PropTypes.string,
};

export default Resource;
