import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";
import Paper from "@mui/material/Paper";
import Pagination from "components/atoms/table/Pagination";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";
import {
  getDisplayDateTime,
  getFormattedDateURL,
} from "components/atoms/dateTime/GetFormattedDate";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const sortListByDescription = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.descricao.localeCompare(b.descricao);
  });
  return newList;
};

const DeleteLocationOccurrence = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `local/${props.idLocation}/ocorrencia/${props.idLocationOccurrence}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível remover esta Ocorrência deste Local.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Ocorrência removida deste Local com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const SolveLocationOccurrence = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadSolve) {
      const fullURLDelete = `local/${props.idLocation}/ocorrencia/${props.idLocationOccurrence}/resolver`;
      instance
        .put(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível solucionar esta Ocorrência.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Ocorrência solucionada com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadSolve && !isLoaded) return <CircularProgress />;
};

const OccurrencesTable = (props) => {
  const [loadSolve, setLoadSolve] = useState(false);
  const [openSolveConfirmDialog, setOpenSolveConfirmDialog] = useState(false);
  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [idLocationOccurrence, setIdLocationOccurrence] = useState("");
  const [description, setDescription] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const userRole = GetUserRole();

  const rows = props.rows;
  const rowsLength = rows.length;

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  //Delete
  const handleClickDelete = (event) => {
    const arr = String(event.currentTarget.id).split("&");
    setIdLocationOccurrence(arr[0]);
    setDescription(arr[1]);
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleBack = () => {
    setLoadDelete(false);
    props.handleReloadTable();
  };

  //Solve
  const handleClickSolve = (event) => {
    const arr = String(event.currentTarget.id).split("&");
    setIdLocationOccurrence(arr[0]);
    setDescription(arr[1]);
    setOpenSolveConfirmDialog(true);
  };
  const handleCloseSolveDialog = () => {
    setOpenSolveConfirmDialog(false);
  };
  const handleConfirmSolve = () => {
    setOpenSolveConfirmDialog(false);
    setLoadSolve(true);
  };
  const handleBackSolve = () => {
    setLoadSolve(false);
    props.handleReloadTable();
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Este local não tem Ocorrências.
      </Typography>
    );
  } else {
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row) => (
      <StyledTableRow key={row.id} id={row.id}>
        <StyledTableCell>
          {row.descricao ? row.descricao : "(Não informada)"}
        </StyledTableCell>
        <StyledTableCell>
          {row.ticket ? row.ticket : "(Não informado)"}
        </StyledTableCell>
        {userRole > 4 && (
          <>
            <StyledTableCell>{getDisplayDateTime(row.horario)}</StyledTableCell>
            <StyledTableCell>{row.relator}</StyledTableCell>
            <StyledTableCell sx={{ textAlign: "center" }}>
              {row.solucionado ? (
                <InfoTooltip placement="left" title="Solucionado">
                  <CheckCircleIcon color="success" />
                </InfoTooltip>
              ) : (
                <InfoTooltip placement="left" title="Solucionar">
                  <IconButton
                    onClick={handleClickSolve}
                    id={row.id + "&" + row.ticket}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </InfoTooltip>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {!row.solucionado && (
                <IconButton
                  onClick={props.handleClickEdit}
                  id={row.id}
                  sx={{
                    bgcolor: "",
                    "&:hover": {
                      backgroundColor: "warning.light",
                    },
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </StyledTableCell>
            <StyledTableCell>
              {!row.solucionado && (
                <IconButton
                  onClick={handleClickDelete}
                  id={row.id + "&" + row.descricao}
                  sx={{
                    bgcolor: "",
                    "&:hover": {
                      backgroundColor: "error.light",
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </StyledTableCell>
          </>
        )}
      </StyledTableRow>
    ));
    return (
      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="data table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Descrição</StyledTableCell>
                <StyledTableCell>Ticket</StyledTableCell>
                <StyledTableCell>Criada em</StyledTableCell>
                <StyledTableCell>Usuário</StyledTableCell>
                {userRole > 4 && (
                  <>
                    <StyledTableCell sx={{ width: 10 }}>
                      Solucionar
                    </StyledTableCell>
                    <StyledTableCell sx={{ width: 10 }}>Editar</StyledTableCell>
                    <StyledTableCell sx={{ width: 10 }}>
                      Excluir
                    </StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            </TableHead>
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          name="Ocorrências"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
        <Box id="boxDelete">
          <DeleteLocationOccurrence
            loadDelete={loadDelete}
            idLocation={props.idLocation}
            idLocationOccurrence={idLocationOccurrence}
            handleClose={handleBack}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.error}
            title="Exclusão"
            open={openDeleteConfirmDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
            content={
              <>
                Você tem certeza que gostaria de remover deste local esta
                ocorrência?
                <br />
                {`Ocorrência: ${description}`}
              </>
            }
          />
        </Box>
        <Box id="boxSolve">
          <SolveLocationOccurrence
            loadSolve={loadSolve}
            idLocation={props.idLocation}
            idLocationOccurrence={idLocationOccurrence}
            handleClose={handleBackSolve}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.warning}
            title="Solucionar"
            open={openSolveConfirmDialog}
            onClose={handleCloseSolveDialog}
            onConfirm={handleConfirmSolve}
            content={
              <>
                Você tem certeza que gostaria de solucionar esta ocorrência?
                <br />
                {`Ocorrência: ${description}`}
              </>
            }
          />
        </Box>
      </Box>
    );
  }
};

const LocationOccurrenceTable = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const handleClickEdit = (event) => {
    const occurrenceItems = items.filter(
      (item) => item.id === event.currentTarget.id
    );
    props.clickUpdateCallback(occurrenceItems);
  };

  const handleReloadTable = (event) => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    let fullURL = `local/${props.idLocation}/ocorrencia`;
    if (props.dateBegin) {
      const dateBegin = getFormattedDateURL(props.dateBegin);
      fullURL = `local/${props.idLocation}/ocorrencia/${dateBegin}`;
      if (props.dateEnd) {
        const dateEnd = getFormattedDateURL(props.dateEnd);
        fullURL = `local/${props.idLocation}/ocorrencia/${dateBegin}/${dateEnd}`;
      }
    }

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByDescription(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [
    props.idLocation,
    props.dateBegin,
    props.dateEnd,
    props.reloadTable,
    reloadTable,
    snackContext,
  ]);

  if (!isLoaded) return <CircularProgress />;
  else
    return (
      <OccurrencesTable
        idLocation={props.idLocation}
        rows={items}
        handleClickEdit={handleClickEdit}
        handleReloadTable={handleReloadTable}
      />
    );
};

export default LocationOccurrenceTable;
