import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import SearchReservation from "components/organisms/Reservation/SearchReservation";
import WeekReservation from "components/organisms/Reservation/WeekReservation";
import LocationResource from "components/organisms/Location/LocationResource";
import LocationOccurrence from "components/organisms/Location/LocationOccurrence";
import LocationOccupant from "components/organisms/Location/LocationOccupant";
import LocationLoan from "components/organisms/Location/LocationLoan";
import LocationCard from "components/molecules/card/LocationCard";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import MessageDialog from "components/atoms/info/MessageDialog";
import TabPanel from "components/atoms/table/TabPanel";

const DeleteLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `local/${props.idLocation}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir este local.",
              "error"
            );
          } else {
            snackContext.setMessage(`Local excluído com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, useRef and navigate
  const userRole = GetUserRole();

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const navigate = useNavigate();
  //#endregion

  const handleBack = () => {
    navigate(-1);
  };

  const handleClickEdit = (event) => {
    navigate(`/local-atualiza/${event.currentTarget.id}`);
  };

  //#region handleDelete
  const handleDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleCloseSuccessDelete = (event) => {
    setLoadDelete(false);
    navigate("/bloco");
  };
  //#endregion

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 5 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-start" title="Editar">
            <IconButton onClick={handleClickEdit} id={props.id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="right" title="Excluir">
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <LocationCard id={props.id} />
      <Box id="deleteBox">
        <DeleteLocation
          loadDelete={loadDelete}
          idLocation={props.id}
          handleClose={handleCloseSuccessDelete}
        />
        <MessageDialog
          confirm
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
        >
          <Typography sx={{ color: "error.main" }}>
            Você tem certeza que gostaria de excluir este local?
          </Typography>
        </MessageDialog>
      </Box>
    </>
  );
};

const Location = (props) => {
  const [currentTab, setCurrentTab] = useState(0);

  const userRole = GetUserRole();

  useEffect(() => {
    if (userRole > 5) {
      if (props.tab === "ocorrencias") setCurrentTab(3);
      else if (props.tab === "emprestimos") setCurrentTab(4);
    }
  }, [props.tab, userRole]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <PageContainer maxWidth="lg" title={"Local"} icon={<AccountBalanceIcon />}>
      <Fields {...props} />
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          variant="scrollable"
          visibleScrollbar
        >
          <Tab label={"Reservas da semana"} />
          <Tab label={"Pesquisar reservas"} />
          <Tab label={"Recursos"} />
          {userRole > 4 && <Tab label={"Ocorrências"} />}
          {userRole > 4 && <Tab label={"Empréstimos"} />}
          {userRole > 5 && <Tab label={"Ocupantes"} />}
          {userRole === "não está pronto" && <Tab label={"Permissões"} />}
        </Tabs>
        <TabPanel value={currentTab} index={0}>
          <WeekReservation id={props.id} from="location" />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <SearchReservation idLocation={props.id} />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <LocationResource idLocation={props.id} />
        </TabPanel>
        {userRole > 4 && (
          <TabPanel value={currentTab} index={3}>
            <LocationOccurrence idLocation={props.id} />
          </TabPanel>
        )}
        {userRole > 4 && (
          <TabPanel value={currentTab} index={4}>
            <LocationLoan idLocation={props.id} />
          </TabPanel>
        )}
        {userRole > 5 && (
          <TabPanel value={currentTab} index={5}>
            <LocationOccupant idLocation={props.id} />
          </TabPanel>
        )}
        {userRole === "não está pronto" && (
          <TabPanel value={currentTab} index={6}></TabPanel>
        )}
      </Box>
    </PageContainer>
  );
};

export default Location;
