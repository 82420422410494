import React from "react";
import { useParams } from "react-router-dom";

import ResourcePage from "components/pages/ResourceOccurrence/Resource";

const Resource = () => {
  const { id } = useParams();

  return <ResourcePage id={id} />;
};

export default Resource;
