import React from "react";
import { useParams } from "react-router-dom";

import BlockUpdatePage from "components/pages/BlockLocation/BlockUpdate";

const BlockUpdate = () => {
  const { id } = useParams();

  return <BlockUpdatePage id={id} />;
};

export default BlockUpdate;
