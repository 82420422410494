import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import AccessibleIcon from "@mui/icons-material/Accessible";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import Pagination from "components/atoms/table/Pagination";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";

const ResourceTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const navigate = useNavigate();

  const rows = props.rows;
  const rowsLength = rows.length;

  const handleClickView = (event) => {
    navigate(`/recurso/${event.currentTarget.id}`);
  };

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  if (rowsLength === 0) {
    return (
      <Typography sx={{ color: "error.main" }}>
        Erro ao carregar a tabela
      </Typography>
    );
  } else {
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row) => (
      <StyledTableRow
        key={row.id}
        id={row.id}
        onClick={handleClickView}
        sx={{
          "&:hover": {
            cursor: "pointer",
          },
        }}
      >
        <StyledTableCell>
          {row.nome}{" "}
          {row.pcd === "1" && (
            <AccessibleIcon fontSize="small" sx={{ color: "primary.main" }} />
          )}
        </StyledTableCell>
      </StyledTableRow>
    ));
    return (
      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="tabela de recursos">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Recursos</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>{cells}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          name="Recursos"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
      </Box>
    );
  }
};

ResourceTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default ResourceTable;
