import React, { useState, useEffect, useContext, useRef } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import LocationOccurrenceTable from "components/molecules/table/LocationOccurrenceTable";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";

const AddOccurrence = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `local/${props.idLocation}/ocorrencia`;

      const request = {
        ticket: props.ticket,
        descricao: props.description,
      };

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Ocorrência salva com sucesso`, "success");
          }
          props.handleClose();
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
          props.handleClose();
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const UpdateOccurrence = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.loadUpdate) {
      setIsLoaded(false);

      const fullURL = `local/${props.idLocation}/ocorrencia/${props.idOccurrence}`;

      const request = {
        ticket: props.ticket,
        descricao: props.description,
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(
              `Ocorrência atualizada com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
          props.handleClose();
        });
    }
  }, [props, snackContext]);

  if (props.loadUpdate && !isLoaded) return <CircularProgress />;
};

const LocationOccurrence = (props) => {
  const [load, setLoad] = useState(false);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [disableOccurrence, setDisableOccurrence] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const [idOccurrence, setIdOccurrence] = useState("");
  const [ticket, setTicket] = useState("");
  const [description, setDescription] = useState("");

  const [checked, setChecked] = useState("notSolved");
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [errorDescription, setErrorDescription] = useState(false);

  const refDescription = useRef(null);

  const userRole = GetUserRole();

  const handleChangeCheck = (event) => {
    const newValue = event.target.value;
    if (newValue === "all") {
      const dateNow = new Date();
      setDateBegin(dateNow);
    } else {
      setDateBegin(null);
      setDateEnd(null);
    }
    setChecked(newValue);
  };

  const handleChangeDateBegin = (newValue) => {
    setDateBegin(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    setDateEnd(newValue);
  };

  const handleShowForm = () => {
    !showForm && setShowForm(true);
  };

  const handleNotShowForm = () => {
    showForm && setShowForm(false);
    setDisableOccurrence(false);
  };

  const handleClean = (event) => {
    load && setLoad(false);
    loadUpdate && setLoadUpdate(false);
    handleNotShowForm();
    setTicket("");
    setDescription("");
    errorDescription && setErrorDescription(false);
  };

  const handleClose = (event) => {
    handleClean();
    setReloadTable(!reloadTable);
  };

  const clickUpdateCallback = (childData) => {
    handleShowForm();
    setDisableOccurrence(true);
    childData.forEach((element) => {
      setIdOccurrence(element.id);
      setTicket(element.ticket);
      setDescription(element.descricao);
    });
  };

  const handleChangeTicket = (event) => {
    if (load) setLoad(false);
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setTicket(newValue);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);
    if (errorDescription) setErrorDescription(false);
    setDescription(event.target.value);
  };

  const handleClick = () => {
    if (!description) {
      refDescription.current.focus();
      if (!errorDescription) setErrorDescription(true);
      alert("Por favor, preencha o campo descrição.");
    } else if (idOccurrence) {
      setLoadUpdate(true);
    } else {
      setLoad(true);
    }
  };

  const NewButtons = () => {
    const handleNew = () => {
      handleClean();
      handleShowForm();
    };

    return (
      <Box sx={{ textAlign: "right" }}>
        {!showForm && (
          <Button variant="contained" onClick={handleNew}>
            Adicionar Ocorrência a este Local
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {userRole > 4 && showForm && (
        <Box id="formLocationOccurrence" sx={{ mb: 1 }}>
          <Box id="ControlButtons">
            <InfoTooltip placement="top-start" title="Voltar">
              <IconButton onClick={handleNotShowForm}>
                <ArrowBackIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-start" title="Salvar">
              <IconButton onClick={handleClick}>
                <SaveIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={2}>
              <TextField
                autoFocus={disableOccurrence}
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 10 }}
                value={ticket}
                onChange={handleChangeTicket}
                id="ticket"
                label="Ticket"
                name="ticket"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                value={description}
                onChange={handleChangeDescription}
                inputRef={refDescription}
                error={errorDescription}
                id="descricao"
                label="Descrição"
                name="descricao"
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item>{userRole > 4 && <NewButtons />}</Grid>
        <Grid item>
          <FormControl id="formConflictInternal">
            <RadioGroup
              row
              aria-labelledby="radio-conflictInternal-group"
              name="controlled-radio-conflictInternal-group"
              value={checked}
              onChange={handleChangeCheck}
            >
              <FormControlLabel
                value="notSolved"
                control={<Radio />}
                label="Não solucionadas"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="Todas no intervalo"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {checked === "all" && (
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "primary.main",
            p: 2,
          }}
        >
          <ButtonDatePicker
            sx={{ width: 150, mr: 3 }}
            value={dateBegin}
            onChange={handleChangeDateBegin}
            id="dateBegin"
            label="Data início"
            name="dateBegin"
          />
          <ButtonDatePicker
            sx={{ width: 150 }}
            value={dateEnd}
            disabled={!dateBegin}
            onChange={handleChangeDateEnd}
            id="dateEnd"
            label="Data fim"
            name="dateEnd"
          />
        </Box>
      )}
      <Box id="boxData" sx={{ mt: 1 }}>
        <LocationOccurrenceTable
          idLocation={props.idLocation}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          clickUpdateCallback={clickUpdateCallback}
          reloadTable={reloadTable}
        />
        <AddOccurrence
          idLocation={props.idLocation}
          ticket={ticket}
          description={description}
          load={load}
          handleClose={handleClose}
        />
        <UpdateOccurrence
          idLocation={props.idLocation}
          idOccurrence={idOccurrence}
          ticket={ticket}
          description={description}
          loadUpdate={loadUpdate}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

export default LocationOccurrence;
