import React from "react";
import { useParams } from "react-router-dom";

import EquipmentUpdatePage from "components/pages/ReservationEquipment/EquipmentUpdate";

const EquipmentUpdate = () => {
  const { id } = useParams();

  return <EquipmentUpdatePage id={id} />;
};

export default EquipmentUpdate;
