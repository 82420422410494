import React, { useState, useEffect, useContext } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import ActivityReservationLocationTable from "components/molecules/table/ActivityReservationLocationTable";

const sortListByDate = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.inicio.localeCompare(b.inicio);
  });
  return newList;
};

const ActivityReservationLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [successDelete, setSuccessDelete] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);
    const fullURL = `atividade/${props.idActivity}/reserva/void`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(sortListByDate(result.reservas));
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, successDelete]);

  //Delete
  const successDeleteCallback = (id) => {
    setSuccessDelete(!successDelete);
  };

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Box sx={{ pl: 1, pr: 4 }}>
          <ActivityReservationLocationTable
            rows={items}
            successDeleteCallback={successDeleteCallback}
          />
        </Box>
      </>
    );
  }
};

export default ActivityReservationLocation;
