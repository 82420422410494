import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";

import { GetUserRole } from "api/definitions";
import BlockLocation from "components/organisms/Location/BlockLocation";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const BlockCard = (props) => {
  const [idBlock, setIdBlock] = useState("");
  const [viewLocations, setViewLocations] = useState(false);

  const userRole = GetUserRole();
  const navigate = useNavigate();

  const handleClickNew = (event) => {
    navigate(`/bloco/${event.currentTarget.id}/local-novo`);
  };

  const handleClickView = (event) => {
    navigate(`/bloco/${event.currentTarget.id}`);
  };

  const handleClickLocation = (event) => {
    setIdBlock(event.currentTarget.id);
    setViewLocations(!viewLocations);
  };

  return (
    <Card raised id={props.idBlock} sx={{ mb: 1, minWidth: 300 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={9} md={10}>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item>
                {props.list ? (
                  <Button onClick={handleClickView} id={props.idBlock}>
                    <Typography variant="h6">{props.name}</Typography>
                  </Button>
                ) : (
                  <Typography>
                    <strong>Nome:</strong> {props.name}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography>
                  <strong>Andares:</strong> {props.floor}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <strong>Subsolo:</strong> {props.underground}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <strong>Descrição:</strong>{" "}
                  {props.description ? props.description : "(Não informada)"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoTooltip title="Locais" placement="top">
                <ToggleButton
                  id={props.idBlock}
                  value={props.idBlock}
                  onClick={handleClickLocation}
                  selected={viewLocations}
                  aria-label="0"
                >
                  <ViewListIcon />
                </ToggleButton>
              </InfoTooltip>
              {userRole > 7 && viewLocations && (
                <Box sx={{ ml: 1 }}>
                  <InfoTooltip title="Novo local" placement="top">
                    <IconButton
                      id={props.idBlock}
                      color="primary"
                      aria-label="novo local"
                      onClick={handleClickNew}
                      sx={{
                        color: "white",
                        bgcolor: "primary.main",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </InfoTooltip>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        {viewLocations && <BlockLocation id={idBlock} />}
      </CardContent>
    </Card>
  );
};

BlockCard.defaultProps = {
  list: false,
  idBlock: undefined,
  name: undefined,
  description: undefined,
  floor: undefined,
  underground: undefined,
};

BlockCard.propTypes = {
  list: PropTypes.bool,
  idBlock: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  floor: PropTypes.number,
  underground: PropTypes.number,
};

export default BlockCard;
