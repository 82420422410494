import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import LocationTable from "components/molecules/table/LocationTable";
import TabPanel from "components/atoms/table/TabPanel";

const sortListByNumber = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.numero.localeCompare(b.numero);
  });
  return newList;
};

const BlockLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const snackContext = useContext(SnackContext);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (props.id) {
      const fullURL = `bloco/${props.id}/local/tree`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const index = Object.keys(result);
          let floorLabel = "";
          let arrayLocation = [];
          let arrayLabel = [];

          index.forEach((element) => {
            floorLabel = element;
            const values = Object.values(result[element]);
            arrayLocation = [];
            values.forEach((element) => {
              arrayLocation.push({
                numero: element.numero,
                nome: element.nome,
                idLocalTipo: element.idLocalTipo,
                idAcesso: element.idAcesso,
                assentos: element.assentos,
                id: element.id,
                andar: floorLabel,
              });
            });
            arrayLabel.push(sortListByNumber(arrayLocation));
          });
          setItems(arrayLabel);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.id) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      const manyTab = items.map((item) => (
        <Tab key={item[0].andar} label={item[0].andar} />
      ));
      let i = -1;
      const manyTabPanel = items.map((item) => {
        i++;
        return (
          <TabPanel key={item[0].andar} value={currentTab} index={i}>
            <LocationTable rows={item} />
          </TabPanel>
        );
      });

      const FloorTabs = () => {
        if (manyTabPanel.length > 0)
          return (
            <>
              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                variant="scrollable"
                visibleScrollbar
              >
                {manyTab}
              </Tabs>
              {manyTabPanel}
            </>
          );
        else
          return (
            <Typography sx={{ ml: 1, color: "error.main" }}>
              Nenhum local cadastrado
            </Typography>
          );
      };

      return (
        <Box>
          <FloorTabs />
        </Box>
      );
    }
  }
};

BlockLocation.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BlockLocation;
