import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import BadgeIcon from "@mui/icons-material/Badge";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import PersonTable from "components/molecules/table/PersonTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const PersonTableList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("pessoa")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhuma pessoa cadastrada.
        </Typography>
      );
    } else {
      return <PersonTable rows={items} />;
    }
  }
};

const Fields = () => {
  const navigate = useNavigate();
  const userRole = GetUserRole();

  const handleClickNewPerson = () => {
    navigate("/pessoa-nova");
  };

  return (
    <>
      <Box id="boxData" sx={{ mt: 2 }}>
        <PersonTableList />
      </Box>
      {userRole > 5 && (
        <Box
          id="boxData"
          sx={{ position: "absolute", top: "14%", right: "10%" }}
        >
          <InfoTooltip title="Nova pessoa" placement="left">
            <Fab
              size="medium"
              color="primary"
              aria-label="nova pessoa"
              onClick={handleClickNewPerson}
            >
              <AddIcon />
            </Fab>
          </InfoTooltip>
        </Box>
      )}
    </>
  );
};

const PersonList = () => {
  return (
    <PageContainer maxWidth="lg" title={"Pessoas"} icon={<BadgeIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default PersonList;
