import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import SelectDivisionType from "components/atoms/select/SelectDivisionType";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateDivision = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `unidade/${props.idUnit}/divisao`;

      const request = {
        idDivisaoTipo: props.divisionType.id,
        nome: props.name,
        descricao: props.description,
        uorg: props.acronym,
      };

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível cadastrar esta Divisão",
              "error"
            );
          } else {
            snackContext.setMessage(`Divisão salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, ref, navigate
  const [load, setLoad] = useState(false);

  const [divisionType, setDivisionType] = useState(null);
  const [idUnit, setIdUnit] = useState("");
  const [unit, setUnit] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [acronym, setAcronym] = useState("");

  const [errorDivisionType, setErrorDivisionType] = useState(false);
  const [errorName, setErrorName] = useState(false);

  const refDivisionType = useRef(null);
  const refName = useRef(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const items = props.items;
    setIdUnit(items.id);
    setUnit(items.nome);
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = () => {
    handleBack();
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const divisionTypeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDivisionType) setErrorDivisionType(false);

    setDivisionType(childData);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeAcronym = (event) => {
    if (load) setLoad(false);

    setAcronym(event.target.value);
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else if (!divisionType) {
      refDivisionType.current.focus();
      if (!errorDivisionType) setErrorDivisionType(true);
      alert("Por favor, preencha um Tipo de Divisão.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form" sx={{ mt: 1 }}>
        <Typography>
          <strong>Unidade:</strong> {unit}
        </Typography>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <SelectDivisionType
              dataParentToChild={divisionType}
              parentCallback={divisionTypeCallback}
              ref={refDivisionType}
              errorParentToChild={errorDivisionType}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 10 }}
              value={acronym}
              onChange={handleChangeAcronym}
              id="acronym"
              label="UORG"
              name="acronym"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refName}
              error={errorName}
              value={name}
              onChange={handleChangeName}
              id="name"
              label="Nome"
              name="name"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateDivision
          idUnit={idUnit}
          divisionType={divisionType}
          name={name}
          description={description}
          acronym={acronym}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const UnitItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `unidade/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const DivisionNew = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Nova Divisão"} icon={<AddBoxIcon />}>
      <UnitItems {...props} />
    </PageContainer>
  );
};

export default DivisionNew;
