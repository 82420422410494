import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { SnackContext } from "context/SnackProvider";
import { LocationTypeContext, AccessContext } from "context/CommonProvider";
import instance, { getErrorMessage } from "api/definitions";

const Fields = (props) => {
  const [block, setBlock] = useState("");
  const [name, setName] = useState("");
  const [pavement, setPavement] = useState(0);
  const [locationNumber, setLocationNumber] = useState(0);
  const [description, setDescription] = useState("");
  const [access, setAccess] = useState(null);
  const [locationType, setLocationType] = useState(null);
  const [locationLength, setLocationLength] = useState(0);
  const [locationWidth, setLocationWidth] = useState(0);
  const [area, setArea] = useState(0);
  const [seat, setSeat] = useState(0);

  useEffect(() => {
    const items = props.items;
    const itemPavement = items.andar;
    let labelPavement = "";
    if (itemPavement === 0) labelPavement = "Térreo";
    else if (itemPavement > 0) labelPavement = `${itemPavement}º andar`;
    else labelPavement = `${itemPavement}º subsolo`;

    setBlock(items.bloco);
    setPavement(labelPavement);
    setName(String(items.nome));
    setLocationNumber(items.numero);
    setLocationType(items.localTipo);
    setAccess(items.acesso);
    setDescription(String(items.descricao));
    setLocationLength(items.comprimento);
    setLocationWidth(items.largura);
    setArea(items.area);
    setSeat(items.assentos);
  }, [props]);

  return (
    <Card raised sx={{ mb: 4, minWidth: 300 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <Typography>
              <strong>Número:</strong> {locationNumber}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Nome:</strong> {name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Bloco:</strong> {`${block} (${pavement})`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Tipo de local:</strong> {locationType}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>Tipo de acesso:</strong> {access}
            </Typography>
          </Grid>
          <Grid item>
            <strong>Assentos:</strong> {seat}
          </Grid>
          <Grid item>
            <strong>Largura:</strong> {`${locationWidth}m`}
          </Grid>
          <Grid item>
            <strong>Comprimento:</strong> {`${locationLength}m`}
          </Grid>
          <Grid item>
            <strong>Área:</strong> {`${area}m`}
          </Grid>
          <Grid item>
            <strong>Descrição:</strong>{" "}
            {description ? description : "(Não informada)"}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const PavementRange = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);
  const locationTypeContext = useContext(LocationTypeContext);
  const accessContext = useContext(AccessContext);

  useEffect(() => {
    const location = props.items;
    const fullURL = `bloco/${location.idBloco}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        if (result) {
          const locationType = locationTypeContext[location.idLocalTipo];
          const access = accessContext[location.idAcesso];
          const blockLocation = {
            id: location.id,
            localTipo: locationType,
            idBloco: location.idBloco,
            acesso: access,
            nome: location.nome,
            descricao: location.descricao,
            andar: location.andar,
            numero: location.numero,
            comprimento: location.comprimento,
            largura: location.largura,
            area: location.area,
            assentos: location.assentos,
            bloco: result.nome,
            andares: result.andares,
            subsolo: result.subsolo,
          };
          setItems(blockLocation);
        }
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, snackContext, locationTypeContext, accessContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const GetLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = `local/${props.id}`;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <PavementRange items={items} />;
};

const LocationCard = (props) => {
  return <GetLocation {...props} />;
};

LocationCard.defaultProps = {
  idLocation: 0,
};

LocationCard.propTypes = {
  idLocation: PropTypes.number,
};

export default LocationCard;
