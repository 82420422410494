import React from "react";
import { useParams } from "react-router-dom";

import LocationPage from "components/pages/BlockLocation/Location";

const Location = () => {
  const { id, tab } = useParams();

  return <LocationPage id={id} tab={tab} />;
};

export default Location;
