import React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import PageContainer from "components/templates/PageContainer";
import BlockCardList from "components/organisms/CardList/BlockCardList";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import { GetUserRole } from "api/definitions";

const Fields = () => {
  const navigate = useNavigate();
  const userRole = GetUserRole();

  const handleClickNewBlock = () => {
    navigate("/bloco-novo");
  };

  return (
    <>
      <Box id="boxData" sx={{ mt: 2 }}>
        <BlockCardList />
      </Box>
      {userRole > 5 && (
        <Box
          id="boxData"
          sx={{ position: "absolute", top: "14%", right: "10%" }}
        >
          <InfoTooltip title="Novo bloco" placement="left">
            <Fab
              size="medium"
              color="primary"
              aria-label="novo bloco"
              onClick={handleClickNewBlock}
            >
              <AddIcon />
            </Fab>
          </InfoTooltip>
        </Box>
      )}
    </>
  );
};

const BlockList = () => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Blocos e Locais"}
      icon={<AccountBalanceIcon />}
    >
      <Fields />
    </PageContainer>
  );
};

export default BlockList;
