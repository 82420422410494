import React from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const PersonCard = (props) => {
  return (
    <Card raised id={props.userId} sx={{ mb: 4, minWidth: 300 }}>
      <CardContent>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item>
            <Typography>
              <strong>Nome:</strong> {props.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <strong>CPF:</strong> {props.userId}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PersonCard.defaultProps = {
  list: false,
  name: undefined,
  userId: undefined,
};

PersonCard.propTypes = {
  list: PropTypes.bool,
  name: PropTypes.string,
  userId: PropTypes.string,
};

export default PersonCard;
