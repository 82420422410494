import React from "react";
import { useParams } from "react-router-dom";

import LocationUpdatePage from "components/pages/BlockLocation/LocationUpdate";

const LocationUpdate = () => {
  const { id } = useParams();

  return <LocationUpdatePage id={id} />;
};

export default LocationUpdate;
