import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import AddIcon from "@mui/icons-material/Add";
import KeyIcon from "@mui/icons-material/Key";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import EquipmentTable from "components/molecules/table/EquipmentTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const sortListByName = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.nome.localeCompare(b.nome);
  });
  return newList;
};

const EquipmentTableList = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const handleReloadTable = (event) => {
    setReloadTable(!reloadTable);
  };

  useEffect(() => {
    setItems([]);
    setIsLoaded(false);

    instance
      .get("emprestavel")
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const newResult = sortListByName(result);
        setItems(newResult);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props, reloadTable, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    if (items.length === 0) {
      return (
        <Typography sx={{ color: "error.main" }}>
          Não há nenhum emprestável cadastrado.
        </Typography>
      );
    } else {
      return (
        <EquipmentTable rows={items} handleReloadTable={handleReloadTable} />
      );
    }
  }
};

const Fields = () => {
  const navigate = useNavigate();
  const userRole = GetUserRole();

  const handleClickNewEquipment = () => {
    navigate("/emprestavel-novo");
  };

  return (
    <>
      <Box id="boxData" sx={{ mt: 2 }}>
        <EquipmentTableList />
      </Box>
      {userRole > 5 && (
        <Box
          id="boxData"
          sx={{ position: "absolute", top: "14%", right: "10%" }}
        >
          <InfoTooltip title="Novo emprestável" placement="left">
            <Fab
              size="medium"
              color="primary"
              aria-label="novo emprestavel"
              onClick={handleClickNewEquipment}
            >
              <AddIcon />
            </Fab>
          </InfoTooltip>
        </Box>
      )}
    </>
  );
};

const EquipmentList = () => {
  return (
    <PageContainer maxWidth="lg" title={"Emprestáveis"} icon={<KeyIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default EquipmentList;
