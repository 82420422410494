import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventIcon from "@mui/icons-material/Event";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import UpdateReservationMany from "components/atoms/reservation/UpdateReservationMany";
import SwitchConflict from "components/molecules/switch/SwitchConflict";
import SwitchRepeatUpdate from "components/molecules/switch/SwitchRepeatUpdate";
import {
  getParseDate,
  getDisplayDate,
  getMinTime,
  formatReservation,
} from "components/atoms/dateTime/GetFormattedDate";

const Fields = (props) => {
  //#region useState, useRef and navigate
  const [load, setLoad] = useState(false);

  const [checkedConflict, setCheckedConflict] = useState(false);
  const [conflictInternal, setConflictInternal] = useState("manual");
  const [conflictExternal, setConflictExternal] = useState("manual");

  const [checkedRepeat, setCheckedRepeat] = useState(true);
  const [weekDays, setWeekDays] = useState(() => [1, 2, 3, 4, 5]);
  const [dateRepeatEnd, setDateRepeatEnd] = useState(null);

  const [block, setBlock] = useState(null);
  const [location, setLocation] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);
  const [dateBegin, setDateBegin] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);

  const [dateDisplay, setDateDisplay] = useState(null);
  const [hour, setHour] = useState(null);
  const [locationCurrent, setLocationCurrent] = useState(null);
  const [dateCurrent, setDateCurrent] = useState(null);
  const [hourCurrent, setHourCurrent] = useState(null);

  const [idActivity, setIdActivity] = useState(null);
  const [activity, setActivity] = useState(null);

  const navigate = useNavigate();
  //#endregion

  useEffect(() => {
    const reservation = props.reservation.reserva;
    const activity = props.reservation.atividade;
    const locationOld = props.reservation.local;
    const block = props.block;
    const location = props.location;
    setIdActivity(String(activity.id));
    setActivity(activity.nome);
    setDateReservation(getParseDate(props.date));
    setDateDisplay(getDisplayDate(props.date));
    setBlock({ label: block.nome, id: block.id });

    setLocationCurrent(`${locationOld.numero} - ${locationOld.nome}`);
    setDateCurrent(getDisplayDate(reservation.inicio));
    const formatedHourCurrent = formatReservation(reservation);
    setHourCurrent(formatedHourCurrent[2]);

    const hourEnd = Number(props.hour) + formatedHourCurrent[1];
    const hour1 = props.hour.length === 1 ? "0" + props.hour : props.hour;
    const hour2 = hourEnd < 10 ? "0" + hourEnd : hourEnd;
    setHour(`${hour1} - ${hour2}`);

    setLocation({
      label: `${location.numero} - ${location.nome}`,
      id: location.id,
    });
    setDateBegin(getMinTime(props.hour));
    setDateEnd(getMinTime(String(hourEnd)));
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const cleanLoad = () => {
    if (load) setLoad(false);
  };

  //#region Conflict
  const checkedConflictCallback = (childData) => {
    cleanLoad();
    setCheckedConflict(childData);
  };

  const conflictInternalCallback = (childData) => {
    cleanLoad();
    setConflictInternal(childData);
  };

  const conflictExternalCallback = (childData) => {
    cleanLoad();
    setConflictExternal(childData);
  };
  //#endregion

  //#region Repeat
  const checkedRepeatCallback = (childData) => {
    cleanLoad();
    setCheckedRepeat(childData);
  };

  const weekDaysCallback = (childData) => {
    cleanLoad();
    setWeekDays(childData);
  };

  const dateRepeatEndCallback = (childData) => {
    cleanLoad();
    setDateRepeatEnd(childData);
  };
  //#endregion

  const handleClick = () => {
    let errorLoad = false;

    if (!errorLoad && !dateBegin) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Início.");
    }
    if (!errorLoad && !dateEnd) {
      errorLoad = true;
      alert("Por favor, selecione a Hora Fim.");
    }
    if (!errorLoad && dateBegin >= dateEnd) {
      errorLoad = true;
      alert(
        "O horário de início não poder ser igual ou maior que o horário fim."
      );
    }

    if (checkedRepeat) {
      if (!errorLoad && weekDays.length === 0) {
        errorLoad = true;
        alert("Por favor, selecione pelo menos um dia da semana.");
      }
      if (!errorLoad && !dateRepeatEnd) {
        errorLoad = true;
        alert("Por favor, selecione uma Data final da Repetição.");
      }
      if (!errorLoad && dateReservation >= dateRepeatEnd) {
        errorLoad = true;
        alert(
          "A Data da Reserva não poder ser igual ou maior que a Data final da Repetição."
        );
      }
    }

    if (!errorLoad) {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="controlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6">Atual</Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  p: 2,
                  color: "white",
                  bgcolor: "primary.light",
                }}
              >
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AssignmentIcon />
                  <Typography sx={{ ml: 1 }}>{activity}</Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccountBalanceIcon />
                  <Typography sx={{ ml: 1 }}>
                    {locationCurrent} - {block && block.label}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <EventIcon />
                  <Typography sx={{ ml: 1 }}>{dateCurrent}</Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccessTimeIcon />
                  <Typography sx={{ ml: 1 }}>{hourCurrent}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h6">Atualização</Typography>
              </Box>
              <Box
                sx={{ mt: 1, p: 2, color: "white", bgcolor: "primary.main" }}
              >
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AssignmentIcon />
                  <Typography sx={{ ml: 1 }}>{activity}</Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccountBalanceIcon />
                  <Typography sx={{ ml: 1 }}>
                    {location && location.label} - {block && block.label}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <EventIcon />
                  <Typography sx={{ ml: 1 }}>{dateDisplay}</Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                  <AccessTimeIcon />
                  <Typography sx={{ ml: 1 }}>{hour}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box id="boxConfig" sx={{ mt: 2 }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} lg={6}>
              <SwitchRepeatUpdate
                repeatDisabled
                checkedParentToChild={checkedRepeat}
                weekDaysParentToChild={weekDays}
                repeatParentToChild={""}
                jumpParentToChild={1}
                dateRepeatEndParentToChild={dateRepeatEnd}
                dateReservationParentToChild={dateReservation}
                checkedParentCallback={checkedRepeatCallback}
                weekDaysParentCallback={weekDaysCallback}
                dateRepeatEndParentCallback={dateRepeatEndCallback}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <SwitchConflict
                checkedParentToChild={checkedConflict}
                internalParentToChild={conflictInternal}
                externalParentToChild={conflictExternal}
                checkedParentCallback={checkedConflictCallback}
                conflictInternalParentCallback={conflictInternalCallback}
                conflictExternalParentCallback={conflictExternalCallback}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <UpdateReservationMany
          idReservation={props.id}
          idActivity={idActivity}
          activityName={activity}
          weekDays={weekDays}
          dateRepeatEnd={dateRepeatEnd}
          conflictInternal={conflictInternal}
          conflictExternal={conflictExternal}
          location={location}
          block={block}
          dateReservation={dateReservation}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          load={load}
        />
      </Box>
    </>
  );
};

const GetBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.reservation) {
      const fullURL = `bloco/${props.reservation.local.idBloco}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} block={items} />;
};

const GetLocation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `local/${props.idLocation}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetBlock {...props} location={items} />;
};

const GetReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = "reserva/" + props.id;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <GetLocation {...props} reservation={items} />;
};

const ReservationUpdateDrop = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={`Atualizar Reserva`}
      icon={<AddBoxIcon />}
    >
      <GetReservation {...props} />
    </PageContainer>
  );
};

export default ReservationUpdateDrop;
