import React from "react";
import { useParams } from "react-router-dom";

import DivisionUpdatePage from "components/pages/UnitDivision/DivisionUpdate";

const DivisionUpdate = () => {
  const { id } = useParams();

  return <DivisionUpdatePage id={id} />;
};

export default DivisionUpdate;
