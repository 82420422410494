import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import PageContainer from "components/templates/PageContainer";
import UnitCard from "components/molecules/card/UnitCard";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteUnit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `unidade/${props.id}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir esta unidade",
              "error"
            );
          } else {
            snackContext.setMessage("Unidade excluída com sucesso", "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const userRole = GetUserRole();

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const unit = props.unit;
    setId(String(unit.id));
    setName(String(unit.nome));
    setDescription(String(unit.descricao));
  }, [props.unit]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClickEdit = (event) => {
    navigate("/unidade-atualiza/" + event.currentTarget.id);
  };

  //Delete
  const handleClickDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 7 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-end" title="Editar">
            <IconButton onClick={handleClickEdit} id={id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="top-end" title="Excluir">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <UnitCard
        idUnit={id}
        name={name}
        description={description ? description : "(Não informada)"}
      />
      <Box id="boxDelete">
        <DeleteUnit loadDelete={loadDelete} id={id} handleClose={handleBack} />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir esta Unidade?
              <br />
              {`Unidade: ${name}`}
            </>
          }
        />
      </Box>
    </>
  );
};

const GetUnit = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `unidade/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields {...props} unit={items} />;
};

const Unit = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Unidade"} icon={<VerticalSplitIcon />}>
      <GetUnit {...props} />
    </PageContainer>
  );
};

Unit.defaultProps = {
  idUnit: undefined,
};

Unit.propTypes = {
  idUnit: PropTypes.string,
};

export default Unit;
