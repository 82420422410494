import React from "react";
import { useParams } from "react-router-dom";

import ActivityPage from "components/pages/ActivityDiscipline/Activity";

const Activity = () => {
  const { id, tab } = useParams();

  return <ActivityPage id={id} tab={tab} />;
};

export default Activity;
