import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectActivity = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [activity, setActivity] = useState([]);
  const [errorActivity, setErrorActivity] = useState(null);

  const handleChange = (event, newValue) => {
    setActivity(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setActivity(props.dataParentToChild);
    setErrorActivity(props.errorParentToChild);

    const request = {
      period: "recent",
    };

    instance
      .post("atividade/search", request)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        const activities = [];
        result.map((item) =>
          activities.push({ label: `${item.nome} (${item.id})`, id: item.id })
        );
        setItems(sortListByLabel(activities));
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        setError(msg);
      });
  }, [props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        value={activity}
        onChange={handleChange}
        disablePortal
        id="Activity"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhuma cadastrada"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorActivity}
            label="Atividade"
          />
        )}
      />
    );
  }
});

export default SelectActivity;
