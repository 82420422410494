import React, { useState, useEffect, useContext, forwardRef } from "react";

import { AccessContext } from "context/CommonProvider";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const SelectLocationAccess = forwardRef((props, ref) => {
  const [access, setAccess] = useState(null);
  const [errorAccess, setErrorAccess] = useState(null);

  const handleChange = (event, newValue) => {
    setAccess(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setAccess(props.dataParentToChild);
    setErrorAccess(props.errorParentToChild);
  }, [props.dataParentToChild, props.errorParentToChild]);

  const accessContext = useContext(AccessContext);
  const items = [];
  for (var [key, value] of Object.entries(accessContext)) {
    items.push({ label: value, id: key });
  }

  return (
    <Autocomplete
      disableClearable
      value={access}
      onChange={handleChange}
      disablePortal
      id="access"
      options={items}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) =>
        value === undefined ||
        value === "" ||
        option.id === value.id ||
        option.nome === value.nome
      }
      noOptionsText={"Nenhum cadastrado"}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          margin="dense"
          inputRef={ref}
          error={errorAccess}
          label="Tipo de Acesso"
        />
      )}
    />
  );
});

export default SelectLocationAccess;
