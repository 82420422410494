import React, { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const TemporaryDrawer = (props) => {
  const [drawer, setDrawer] = useState(false);

  const openDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(true);
  };

  const closeDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(false);
  };

  const ReturnIcon = () => {
    switch (props.anchor) {
      case "left":
        return <KeyboardArrowLeft />;

      case "right":
        return <KeyboardArrowRight />;

      case "top":
        return <ExpandLess />;

      default:
        return <ExpandMore />;
    }
  };

  return (
    <Box>
      <IconButton
        onClick={openDrawer}
        sx={{
          mr: 1,
          color: "inherit",
          "&:hover": {
            color: "primary.main",
            backgroundColor: "white",
          },
        }}
      >
        {props.icon}
      </IconButton>
      <Drawer
        anchor={props.anchor}
        open={drawer}
        onClose={closeDrawer}
        sx={{ position: "absolute", top: "20" }}
      >
        <Box sx={{ textAlign: props.anchor === "left" && "right" }}>
          <IconButton onClick={closeDrawer} sx={{ m: 1 }}>
            <ReturnIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 250 }} role="presentation">
          {props.children}
        </Box>
      </Drawer>
    </Box>
  );
};

TemporaryDrawer.defaultProps = {
  anchor: "left",
  children: undefined,
  icon: undefined,
};

TemporaryDrawer.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
};

export default TemporaryDrawer;
