import React from "react";
import { useParams } from "react-router-dom";

import PersonUpdatePage from "components/pages/UserPerson/PersonUpdate";

const PersonUpdate = () => {
  const { userId } = useParams();

  return <PersonUpdatePage userId={userId} />;
};

export default PersonUpdate;
