import React, { useState, useEffect, forwardRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import instance, { getErrorMessage } from "api/definitions";

const sortListByLabel = (currentList) => {
  const newList = [...currentList].sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  return newList;
};

const SelectDivisionDiscipline = forwardRef((props, ref) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [divisionDiscipline, setDivisionDeparment] = useState([]);
  const [errorDivisionDiscipline, setErrorDivisionDiscipline] = useState(null);
  const [disabledSelect, setDisabledSelect] = useState(false);

  const handleChange = (event, newValue) => {
    setDivisionDeparment(newValue);

    props.parentCallback(newValue);
  };

  useEffect(() => {
    setIsLoaded(false);
    setDivisionDeparment(props.dataParentToChild);
    setErrorDivisionDiscipline(props.errorParentToChild);
    setDisabledSelect(false);

    if (props.idUnit === 0) {
      setIsLoaded(true);
      setDisabledSelect(true);
    } else {
      instance
        .get(`unidade/${props.idUnit}/divisao`)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          const department = result.Departamento;
          const coord = result.Coordenação;
          const divisionDisciplines = [];
          department.map((item) =>
            divisionDisciplines.push({ label: item.nome, id: item.id })
          );
          coord.map((item) =>
            divisionDisciplines.push({ label: item.nome, id: item.id })
          );
          setItems(sortListByLabel(divisionDisciplines));
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          setError(msg);
        });
    }
  }, [props.idUnit, props.dataParentToChild, props.errorParentToChild]);

  if (error) {
    return <div>Erro: {error}</div>;
  } else if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return (
      <Autocomplete
        disableClearable
        disabled={disabledSelect}
        value={divisionDiscipline}
        onChange={handleChange}
        disablePortal
        id="divisionDiscipline"
        options={items}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) =>
          value === undefined ||
          value === "" ||
          option.id === value.id ||
          option.nome === value.nome
        }
        noOptionsText={"Nenhum cadastrado"}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            inputRef={ref}
            error={errorDivisionDiscipline}
            label="Divisão"
          />
        )}
      />
    );
  }
});

export default SelectDivisionDiscipline;
