import React, { useState, useEffect, useContext, useRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import SelectParticipantType from "components/atoms/select/SelectParticipantType";
import SelectParticipantId from "components/atoms/select/SelectParticipantId";
import ActivityParticipantTable from "components/molecules/table/ActivityParticipantTable";

const AddParticipant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `atividade/${props.idActivity}/participante`;
      let request = props.participantIdMany;

      const participantType = props.participantType && props.participantType.id;

      request = [
        {
          userId: props.participantId,
          nome: props.name,
          idParticipanteTipo: participantType,
        },
      ];

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (result) {
            snackContext.setMessage(
              `Participante salvo com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const ActivityParticipant = (props) => {
  const [load, setLoad] = useState(false);
  const [disableParticipantId, setDisableParticipantId] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFormMany, setShowFormMany] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const [participantType, setParticipantType] = useState(null);
  const [participantId, setParticipantId] = useState("");
  const [name, setName] = useState("");

  const [participantIdMany, setParticipantIdMany] = useState([]);

  const [errorParticipantType, setErrorParticipantType] = useState(false);
  const [errorParticipantId, setErrorParticipantId] = useState(false);
  const [errorParticipantIdMany, setErrorParticipantIdMany] = useState(false);

  const refParticipantType = useRef(null);
  const refParticipantId = useRef(null);
  const refParticipantIdMany = useRef(null);

  const userRole = GetUserRole();

  const handleShowForm = () => {
    !showForm && setShowForm(true);
  };

  const handleNotShowForm = () => {
    showForm && setShowForm(false);
    setDisableParticipantId(false);
  };

  const handleShowFormMany = () => {
    !showFormMany && setShowFormMany(true);
  };

  const handleNotShowFormMany = () => {
    showFormMany && setShowFormMany(false);
  };

  const handleClose = (event) => {
    load && setLoad(false);
    handleNotShowForm();
    handleNotShowFormMany();
    setReloadTable(!reloadTable);
    setParticipantType(null);
    setName("");
    setParticipantId("");
    errorParticipantType && setErrorParticipantType(false);
    errorParticipantId && setErrorParticipantId(false);
  };

  const clickUpdateCallback = (childData) => {
    handleShowForm();
    setDisableParticipantId(true);
    childData.forEach((element) => {
      const participantTypeArray = {
        id: element.idParticipanteTipo,
        label: element.tipo,
      };
      setParticipantType(participantTypeArray);
      setName(element.nome);
      setParticipantId(element.userId);
    });
  };

  const participantTypeCallback = (childData) => {
    if (errorParticipantType) setErrorParticipantType(false);

    setParticipantType(childData);
  };

  const idParticipantCallback = (childData) => {
    if (load) setLoad(false);
    if (errorParticipantId) setErrorParticipantId(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setParticipantId(childData);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const participantIdManyCallback = (childData) => {
    if (setErrorParticipantIdMany) setErrorParticipantIdMany(false);

    setParticipantIdMany(childData);
    /**if (childData.length > 0) {
      const lastValue = childData.pop();
      const splitValue = lastValue.split(" ");
      const newValue = childData.concat(splitValue);
      setParticipantIdMany(newValue);
    } else setParticipantIdMany(childData); */
  };

  const handleClick = () => {
    if (!participantId) {
      refParticipantId.current.focus();
      if (!errorParticipantId) setErrorParticipantId(true);
      alert("Por favor, preencha o campo Participante (CPF).");
    } else if (!participantType) {
      refParticipantType.current.focus();
      if (!errorParticipantType) setErrorParticipantType(true);
      alert("Por favor, selecione um Tipo de Participante.");
    } else {
      setLoad(true);
    }
  };

  const handleClickAddMany = () => {
    if (participantIdMany.length === 0) {
      refParticipantIdMany.current.focus();
      if (!setErrorParticipantIdMany) setErrorParticipantIdMany(true);
      alert("Por favor, digite pelo menos um CPF e pressione [ Enter ].");
    } else {
      setLoad(true);
    }
  };

  const NewButtons = () => {
    const handleNew = () => {
      handleClose();
      handleShowForm();
    };

    const handleNewMany = () => {
      handleClose();
      handleShowFormMany();
    };

    return (
      <Box>
        {!showForm && !showFormMany && (
          <Button variant="contained" onClick={handleNew}>
            Adicionar Participante
          </Button>
        )}
        {!showForm && !showFormMany && (
          <Button variant="contained" onClick={handleNewMany} sx={{ ml: 2 }}>
            Adicionar Participantes Autorizados
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {userRole > 5 && <NewButtons />}
      {userRole > 5 && showForm && (
        <Box id="formActivityParticipant">
          <Box id="ControlButtons">
            <InfoTooltip placement="top-start" title="Voltar">
              <IconButton onClick={handleNotShowForm}>
                <ArrowBackIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-start" title="Salvar">
              <IconButton onClick={handleClick}>
                <SaveIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Box>
            <VerifyPerson
              title="Participante"
              dataParentToChild={participantId}
              parentCallback={idParticipantCallback}
              ref={refParticipantId}
              errorParentToChild={errorParticipantId}
            />
          </Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus={disableParticipantId}
                fullWidth
                margin="dense"
                value={name}
                onChange={handleChangeName}
                id="name"
                label="Nome"
                name="name"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectParticipantType
                autoFocus
                dataParentToChild={participantType}
                parentCallback={participantTypeCallback}
                ref={refParticipantType}
                errorParentToChild={errorParticipantType}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {userRole > 5 && showFormMany && (
        <Box id="formActivityParticipant">
          <Box id="ControlButtons">
            <InfoTooltip placement="top-start" title="Voltar">
              <IconButton onClick={handleNotShowFormMany}>
                <ArrowBackIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-start" title="Salvar">
              <IconButton onClick={handleClickAddMany}>
                <SaveIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12}>
              <SelectParticipantId
                dataParentToChild={participantIdMany}
                parentCallback={participantIdManyCallback}
                ref={refParticipantIdMany}
                errorParentToChild={errorParticipantIdMany}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box id="boxData" sx={{ mt: 2 }}>
        <ActivityParticipantTable
          idActivity={props.idActivity}
          clickUpdateCallback={clickUpdateCallback}
          reloadTable={reloadTable}
        />
        <AddParticipant
          idActivity={props.idActivity}
          participantType={participantType}
          name={name}
          participantId={participantId}
          participantIdMany={participantIdMany}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

export default ActivityParticipant;
