import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import KeyIcon from "@mui/icons-material/Key";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

import PageContainer from "components/templates/PageContainer";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import SelectEquipment from "components/atoms/select/SelectEquipment";
import EquipmentLoanTable from "components/molecules/table/EquipmentLoanTable";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const Fields = () => {
  const dateNow = new Date();
  const [load, setLoad] = useState(false);
  const [checkedFilterEquipment, setCheckedFilterEquipment] = useState(false);
  const [checkedFilterUser, setCheckedFilterUser] = useState(false);
  const [checkedFilterExit, setCheckedFilterExit] = useState(false);
  const [checkedFilterReturned, setCheckedFilterReturned] = useState(false);
  const [checkedLoanReturn, setCheckedLoanReturn] = useState(true);
  const [idGiver, setIdGiver] = useState("");
  const [idReceiver, setIdReceiver] = useState("");
  const [idReturner, setIdReturner] = useState("");
  const [equipment, setEquipment] = useState(null);
  const [dateLoanBegin, setDateLoanBegin] = useState(dateNow);
  const [dateLoanEnd, setDateLoanEnd] = useState(dateNow);
  const [dateReturnedBegin, setDateReturnedBegin] = useState(dateNow);
  const [dateReturnedEnd, setDateReturnedEnd] = useState(dateNow);

  const [errorEquipment, setErrorEquipment] = useState(false);

  const refEquipment = useRef(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/");
  };

  const handleCleanLoad = (event) => {
    load && setLoad(false);
  };

  const handleClean = (event) => {
    handleCleanLoad();
    setCheckedFilterEquipment(false);
    setCheckedFilterUser(false);
    setCheckedFilterExit(false);
    setCheckedFilterReturned(false);
    setCheckedLoanReturn(true);
    setIdGiver("");
    setIdReceiver("");
    setIdReturner("");
    setEquipment(null);
    setErrorEquipment(false);
  };

  const handleChangeCheckFilterEquipment = () => {
    setCheckedFilterEquipment(!checkedFilterEquipment);
    handleCleanLoad();
  };

  const handleChangeCheckFilterUser = () => {
    setCheckedFilterUser(!checkedFilterUser);
    handleCleanLoad();
  };

  const handleChangeCheckFilterExit = () => {
    setCheckedFilterExit(!checkedFilterExit);
    handleCleanLoad();
  };

  const handleChangeCheckFilterReturned = () => {
    setCheckedFilterReturned(!checkedFilterReturned);
    if (!checkedFilterReturned) {
      setCheckedLoanReturn(false);
    }
    handleCleanLoad();
  };

  const handleChangeCheckedLoanReturn = (event) => {
    setCheckedLoanReturn(event.target.checked);
    handleCleanLoad();
  };

  const equipmentCallback = (childData) => {
    if (errorEquipment) setErrorEquipment(false);

    setEquipment(childData);
    handleCleanLoad();
  };

  const handleChangeIdGiver = (event) => {
    handleCleanLoad();
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setIdGiver(newValue);
  };

  const handleChangeIdReceiver = (event) => {
    handleCleanLoad();
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setIdReceiver(newValue);
  };

  const handleChangeIdReturner = (event) => {
    handleCleanLoad();
    const newValue = event.target.value.trim();
    if (
      Number(newValue) ||
      newValue === "0" ||
      newValue === "00" ||
      newValue === ""
    )
      setIdReturner(newValue);
  };

  const handleChangeDateLoanBegin = (newValue) => {
    setDateLoanBegin(newValue);
    handleCleanLoad();
  };

  const handleChangeDateLoanEnd = (newValue) => {
    setDateLoanEnd(newValue);
    handleCleanLoad();
  };

  const handleChangeDateReturnedBegin = (newValue) => {
    setDateReturnedBegin(newValue);
    handleCleanLoad();
  };

  const handleChangeDateReturnedEnd = (newValue) => {
    setDateReturnedEnd(newValue);
    handleCleanLoad();
  };

  const handleClickSearch = (event) => {
    if (differenceInDays(dateLoanBegin, dateLoanEnd) > 0) {
      alert("A data fim não pode ser menor que a data início.");
    } else {
      if (differenceInDays(dateReturnedBegin, dateReturnedEnd) > 0) {
        alert("A data fim não pode ser menor que a data início.");
      } else {
        setLoad(true);
      }
    }
  };

  return (
    <Box>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip title="Pesquisar" placement="top">
          <IconButton
            variant="contained"
            onClick={handleClickSearch}
            sx={{ mr: 1 }}
          >
            <SearchIcon />
          </IconButton>
        </InfoTooltip>
        Filtrar por:
        <InfoTooltip title="emprestável" placement="top">
          <ToggleButton
            size="small"
            value={0}
            selected={checkedFilterEquipment}
            onChange={handleChangeCheckFilterEquipment}
            sx={{ m: 1 }}
          >
            <KeyIcon />
          </ToggleButton>
        </InfoTooltip>
        <InfoTooltip title="usuários" placement="top">
          <ToggleButton
            size="small"
            value={0}
            selected={checkedFilterUser}
            onChange={handleChangeCheckFilterUser}
            sx={{ m: 1 }}
          >
            <PersonIcon />
          </ToggleButton>
        </InfoTooltip>
        <InfoTooltip title="período de empréstimo" placement="top">
          <ToggleButton
            size="small"
            value={0}
            selected={checkedFilterExit}
            onChange={handleChangeCheckFilterExit}
            sx={{ m: 1 }}
          >
            <EventIcon />
          </ToggleButton>
        </InfoTooltip>
        <InfoTooltip title="período de devolução" placement="top">
          <ToggleButton
            size="small"
            value={0}
            selected={checkedFilterReturned}
            onChange={handleChangeCheckFilterReturned}
            sx={{ m: 1 }}
          >
            <EventAvailableIcon />
          </ToggleButton>
        </InfoTooltip>
        <InfoTooltip title="Reiniciar filtro" placement="top">
          <IconButton variant="contained" onClick={handleClean}>
            <RefreshIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          {checkedFilterEquipment && (
            <Grid item xs={12} sm={6} lg={3}>
              <SelectEquipment
                dataParentToChild={equipment}
                parentCallback={equipmentCallback}
                ref={refEquipment}
                errorParentToChild={errorEquipment}
              />
            </Grid>
          )}
          {checkedFilterUser && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 200 }}
                  value={idGiver}
                  onChange={handleChangeIdGiver}
                  id="idGiver"
                  label="Concedente (CPF)"
                  name="idGiver"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  inputProps={{ maxLength: 200 }}
                  value={idReceiver}
                  onChange={handleChangeIdReceiver}
                  id="idReceiver"
                  label="Recebedor (CPF)"
                  name="idReceiver"
                  autoComplete="off"
                />
              </Grid>
              {!checkedLoanReturn && (
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    inputProps={{ maxLength: 200 }}
                    value={idReturner}
                    onChange={handleChangeIdReturner}
                    id="idReturner"
                    label="Devolvedor (CPF)"
                    name="idReturner"
                    autoComplete="off"
                  />
                </Grid>
              )}
            </>
          )}
          {checkedFilterExit && (
            <Grid item xs={12} lg={6}>
              <Box sx={{ mt: 1 }}>
                <ButtonDatePicker
                  sx={{ width: 150, mr: 3 }}
                  value={dateLoanBegin}
                  onChange={handleChangeDateLoanBegin}
                  id="dateLoanBegin"
                  label="Empréstimo início"
                  name="dateLoanBegin"
                />
                <ButtonDatePicker
                  sx={{ width: 150 }}
                  value={dateLoanEnd}
                  disabled={!dateLoanBegin}
                  onChange={handleChangeDateLoanEnd}
                  id="dateLoanEnd"
                  label="Empréstimo fim"
                  name="dateLoanEnd"
                />
              </Box>
            </Grid>
          )}
          {checkedFilterReturned && (
            <Grid item xs={12} lg={6}>
              <Box sx={{ mt: 1 }}>
                <ButtonDatePicker
                  sx={{ width: 150, mr: 3 }}
                  value={dateReturnedBegin}
                  onChange={handleChangeDateReturnedBegin}
                  id="dateReturnedBegin"
                  label="Devolução início"
                  name="dateReturnedBegin"
                />
                <ButtonDatePicker
                  sx={{ width: 150 }}
                  value={dateReturnedEnd}
                  disabled={!dateReturnedBegin}
                  onChange={handleChangeDateReturnedEnd}
                  id="dateReturnedEnd"
                  label="Devolução fim"
                  name="dateReturnedEnd"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Checkbox
        color="primary"
        checked={checkedLoanReturn}
        onChange={handleChangeCheckedLoanReturn}
        inputProps={{
          "aria-label": "Todos empréstimos",
        }}
      />
      Somente empréstimos a devolver
      <EquipmentLoanTable
        load={load}
        return={checkedLoanReturn}
        idEmprestavel={equipment && equipment.id}
        userIdConcedente={idGiver}
        userId={idReceiver}
        userIdDevolucao={idReturner}
        checkedFilterExit={checkedFilterExit}
        dateLoanBegin={dateLoanBegin}
        dateLoanEnd={dateLoanEnd}
        checkedFilterReturned={checkedFilterReturned}
        dateReturnedBegin={dateReturnedBegin}
        dateReturnedEnd={dateReturnedEnd}
      />
    </Box>
  );
};

const Loan = () => {
  return (
    <PageContainer maxWidth="lg" title={"Empréstimos"} icon={<KeyIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default Loan;
