import React, { useState } from "react";

import Button from "@mui/material/Button";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

function ButtonField(props) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      sx={{ height: 55 }}
    >
      {label}
    </Button>
  );
}

export default function ButtonTimePicker(props) {
  const [open, setOpen] = useState(false);

  return (
    <TimePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
}
