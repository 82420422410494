import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import SelectActivityType from "components/atoms/select/SelectActivityType";
import SelectUnit from "components/atoms/select/SelectUnit";
import SelectDivisionDiscipline from "components/atoms/select/SelectDivisionDiscipline";
import SelectDiscipline from "components/atoms/select/SelectDiscipline";
import SelectSemester from "components/atoms/select/SelectSemester";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const CreateActivity = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const idActivityType = props.activityType.id;
      let request = null;
      if (idActivityType === "2") {
        request = {
          idAtividadeTipo: idActivityType,
          idDisciplina: props.discipline.id,
          turma: props.className,
          semestre: props.semester.label,
          nome: props.name,
          descricao: props.description,
          userIdSolicitante: props.idRequester,
          participantes: props.participants,
        };
      } else {
        request = {
          idAtividadeTipo: idActivityType,
          nome: props.name,
          descricao: props.description,
          userIdSolicitante: props.idRequester,
          participantes: props.participants,
        };
      }

      instance
        .post("atividade", request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível cadastrar esta atividade",
              "error"
            );
          } else {
            snackContext.setMessage(`Atividade salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = () => {
  const [load, setLoad] = useState(false);
  const [changeFields, setChangeFields] = useState(false);

  const [activityType, setActivityType] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [participants, setParticipants] = useState("");
  const [idRequester, setIdRequester] = useState("");
  const [unit, setUnit] = useState(null);
  const [divisionDiscipline, setDivisionDiscipline] = useState(null);
  const [discipline, setDiscipline] = useState(null);
  const [semester, setSemester] = useState(null);
  const [className, setClassName] = useState("");

  const [errorActivityType, setErrorActivityType] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorParticipants, setErrorParticipants] = useState(false);
  const [errorIdRequester, setErrorIdRequester] = useState(false);
  const [errorUnit, setErrorUnit] = useState(false);
  const [errorDivisionDiscipline, setErrorDivisionDiscipline] = useState(false);
  const [errorDiscipline, setErrorDiscipline] = useState(false);
  const [errorSemester, setErrorSemester] = useState(false);
  const [errorClassName, setErrorClassName] = useState(false);

  const refActivityType = useRef(null);
  const refName = useRef(null);
  const refParticipants = useRef(null);
  const refIdRequester = useRef(null);
  const refUnit = useRef(null);
  const refDivisionDiscipline = useRef(null);
  const refDiscipline = useRef(null);
  const refSemester = useRef(null);
  const refClassName = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const dateToday = new Date();
    const yearThis = dateToday.getFullYear();

    const semester1 = `${yearThis}.1`;
    const semester2 = `${yearThis}.2`;

    const semesters = [];
    semesters.push({ label: semester1, id: "2" });
    semesters.push({ label: semester2, id: "3" });
    dateToday.getMonth() < 5
      ? setSemester(semesters[0])
      : setSemester(semesters[1]);
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  const idRequesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorIdRequester) setErrorIdRequester(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setIdRequester(childData);
  };

  const activityTypeCallback = (childData) => {
    if (load) setLoad(false);
    if (errorActivityType) setErrorActivityType(false);

    if (childData) {
      if (childData.label === "Ensalamento") {
        !changeFields && setChangeFields(true);
      } else {
        changeFields && setChangeFields(false);
      }
    }

    setActivityType(childData);
  };

  const unitCallback = (childData) => {
    if (load) setLoad(false);
    if (errorUnit) setErrorUnit(false);

    disciplineCallback(null);
    divisionDisciplineCallback(null);

    setUnit(childData);
  };

  const divisionDisciplineCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDivisionDiscipline) setErrorDivisionDiscipline(false);

    disciplineCallback(null);

    setDivisionDiscipline(childData);
  };

  const disciplineCallback = (childData) => {
    if (load) setLoad(false);
    if (errorDiscipline) setErrorDiscipline(false);

    setDiscipline(childData);
  };

  const semesterCallback = (childData) => {
    if (load) setLoad(false);
    if (errorSemester) setErrorSemester(false);

    setSemester(childData);
  };

  const handleChangeParticipants = (event) => {
    if (load) setLoad(false);
    if (errorParticipants) setErrorParticipants(false);
    const newValue = event.target.value.trim();
    if (Number(newValue) || newValue === "") setParticipants(newValue);
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    if (load) setLoad(false);

    setDescription(event.target.value);
  };

  const handleChangeClassName = (event) => {
    if (load) setLoad(false);
    if (errorClassName) setErrorClassName(false);

    setClassName(event.target.value);
  };

  const handleDeleteAll = () => {
    if (load) setLoad(false);
    activityTypeCallback(null);
    unitCallback(null);
    divisionDisciplineCallback(null);
    disciplineCallback(null);
    semesterCallback(null);
    setName("");
    setDescription("");
    setParticipants("");
    setIdRequester("");
    setClassName("");
    if (errorName) setErrorName(false);
    if (errorClassName) setErrorClassName(false);
  };

  const handleClose = (event) => {
    handleDeleteAll();
  };

  const handleClick = () => {
    if (!idRequester) {
      refIdRequester.current.focus();
      if (!errorIdRequester) setErrorIdRequester(true);
      alert("Por favor, preencha o campo CPF.");
    } else if (!activityType) {
      refActivityType.current.focus();
      if (!errorActivityType) setErrorActivityType(true);
      alert("Por favor, preencha um Tipo de Atividade.");
    } else if (!participants) {
      refParticipants.current.focus();
      if (!errorParticipants) setErrorParticipants(true);
      alert("Por favor, preencha o Número de Participantes.");
    } else if (changeFields) {
      if (!className) {
        refClassName.current.focus();
        if (!errorClassName) setErrorClassName(true);
        alert("Por favor, preencha o nome da Turma.");
      } else if (!unit) {
        refUnit.current.focus();
        if (!errorUnit) setErrorUnit(true);
        alert("Por favor, preencha uma Unidade.");
      } else if (!divisionDiscipline) {
        refDivisionDiscipline.current.focus();
        if (!errorDivisionDiscipline) setErrorDivisionDiscipline(true);
        alert("Por favor, preencha uma Divisão.");
      } else if (!discipline) {
        refDiscipline.current.focus();
        if (!errorDiscipline) setErrorDiscipline(true);
        alert("Por favor, preencha uma Disciplina.");
      } else if (!semester) {
        refSemester.current.focus();
        if (!errorSemester) setErrorSemester(true);
        alert("Por favor, preencha um Semestre.");
      } else {
        setLoad(true);
      }
    } else if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box component="form">
        <VerifyPerson
          title="Solicitante"
          dataParentToChild={idRequester}
          parentCallback={idRequesterCallback}
          ref={refIdRequester}
          errorParentToChild={errorIdRequester}
        />
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={5} lg={3}>
            <SelectActivityType
              dataParentToChild={activityType}
              parentCallback={activityTypeCallback}
              ref={refActivityType}
              errorParentToChild={errorActivityType}
            />
          </Grid>
          <Grid item xs={7} sm={4} lg={3}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              inputRef={refParticipants}
              error={errorParticipants}
              value={participants}
              onChange={handleChangeParticipants}
              id="participants"
              label="Número de Participantes"
              name="participants"
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {!changeFields && (
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                inputRef={refName}
                error={errorName}
                value={name}
                onChange={handleChangeName}
                id="name"
                label="Nome da Atividade"
                name="name"
                autoComplete="off"
              />
            )}
            {changeFields && (
              <TextField
                fullWidth
                margin="dense"
                inputProps={{ maxLength: 200 }}
                inputRef={refClassName}
                error={errorClassName}
                value={className}
                onChange={handleChangeClassName}
                id="className"
                label="Turma"
                name="className"
                autoComplete="off"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="dense"
              inputProps={{ maxLength: 200 }}
              value={description}
              onChange={handleChangeDescription}
              id="description"
              label="Descrição da Atividade"
              name="description"
              autoComplete="off"
            />
          </Grid>
        </Grid>
        {changeFields && (
          <Grid container rowSpacing={0} columnSpacing={2}>
            <Grid item xs={12} lg={3}>
              <SelectUnit
                dataParentToChild={unit}
                parentCallback={unitCallback}
                ref={refUnit}
                errorParentToChild={errorUnit}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectDivisionDiscipline
                idUnit={unit ? unit.id : 0}
                dataParentToChild={divisionDiscipline}
                parentCallback={divisionDisciplineCallback}
                ref={refDivisionDiscipline}
                errorParentToChild={errorDivisionDiscipline}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectDiscipline
                idDivision={divisionDiscipline ? divisionDiscipline.id : 0}
                dataParentToChild={discipline}
                parentCallback={disciplineCallback}
                ref={refDiscipline}
                errorParentToChild={errorDiscipline}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectSemester
                dataParentToChild={semester}
                parentCallback={semesterCallback}
                ref={refSemester}
                errorParentToChild={errorSemester}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <CreateActivity
          activityType={activityType}
          name={name}
          description={description}
          participants={participants}
          idRequester={idRequester}
          discipline={discipline}
          semester={semester}
          className={className}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const ActivityNew = () => {
  return (
    <PageContainer maxWidth="lg" title={"Nova Atividade"} icon={<AddBoxIcon />}>
      <Fields />
    </PageContainer>
  );
};

export default ActivityNew;
