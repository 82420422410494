import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import { ActivityTypeContext } from "context/CommonProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import SearchReservation from "components/organisms/Reservation/SearchReservation";
import WeekReservation from "components/organisms/Reservation/WeekReservation";
import ActivityParticipant from "components/organisms/Activity/ActivityParticipant";
import ActivityReservationLocation from "components/organisms/Activity/ActivityReservationLocation";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import ActivityCard from "components/molecules/card/ActivityCard";
import TabPanel from "components/atoms/table/TabPanel";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteActivity = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `atividade/${props.id}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir esta atividade.",
              "error"
            );
          } else {
            snackContext.setMessage(
              `Atividade excluída com sucesso`,
              "success"
            );
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  //#region useState, useNavigate, GetUserRole, useContext
  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [id, setId] = useState("");
  const [activityType, setActivityType] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [participants, setParticipants] = useState("");
  const [userCreator, setUserCreator] = useState("");
  const [userRequester, setUserRequester] = useState("");

  const [unit, setUnit] = useState("");
  const [divisionDiscipline, setDivisionDiscipline] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [semester, setSemester] = useState("");
  const [className, setClassName] = useState("");

  const navigate = useNavigate();
  const userRole = GetUserRole();
  const activityTypeContext = useContext(ActivityTypeContext);
  //#endregion

  useEffect(() => {
    const activity = props.activity;
    setId(String(activity.id));
    setActivityType(activityTypeContext[activity.idAtividadeTipo]);
    setName(String(activity.nome));
    setDescription(String(activity.descricao));
    setParticipants(String(activity.participantes));
    setUserCreator(String(activity.nomeCriador));
    setUserRequester(String(activity.nomeSolicitante));
    if (activity.subclass) {
      setUnit(String(activity.subclass.unidade));
      setDivisionDiscipline(String(activity.subclass.divisao));
      setDiscipline(String(activity.subclass.disciplina));
      setSemester(String(activity.subclass.semestre));
      setClassName(String(activity.subclass.turma));
    }
  }, [props.activity, activityTypeContext]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClickEdit = (event) => {
    navigate("/atividade-atualiza/" + event.currentTarget.id);
  };

  //Delete
  const handleClickDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };

  return (
    <Box>
      <Box id="ControlButton" sx={{ mb: 1 }}>
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        {userRole > 5 && (
          <Box id="ControlButtons" sx={{ display: "inline" }}>
            <InfoTooltip placement="top-end" title="Editar">
              <IconButton onClick={handleClickEdit} id={id}>
                <EditIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-end" title="Excluir">
              <IconButton onClick={handleClickDelete}>
                <DeleteIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
        )}
      </Box>
      <ActivityCard
        idActivity={String(id)}
        activityType={activityType}
        name={name}
        description={description}
        userCreator={userCreator}
        userRequester={userRequester}
        participants={participants}
        unit={unit}
        division={divisionDiscipline}
        discipline={discipline}
        semester={semester}
        className={className}
      />
      <Box id="deleteBox">
        <DeleteActivity
          loadDelete={loadDelete}
          id={id}
          handleClose={handleBack}
        />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir esta atividade?
              <br />
              {`Atividade: ${name}`}
            </>
          }
        />
      </Box>
    </Box>
  );
};

const Activity = (props) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const userRole = GetUserRole();
  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.tab) setCurrentTab(2);
    if (props.id) {
      const fullURL = `atividade/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, props.tab, snackContext]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  if (!isLoaded) {
    return (
      <PageContainer
        maxWidth="lg"
        title={"Atividade"}
        icon={<AssignmentIcon />}
      >
        <CircularProgress />
      </PageContainer>
    );
  } else {
    return (
      <PageContainer
        maxWidth="lg"
        title={"Atividade"}
        icon={<AssignmentIcon />}
      >
        <Fields {...props} activity={items} />
        <Box sx={{ mt: 1 }}>
          <Tabs value={currentTab} onChange={handleChangeTab}>
            <Tab label={"Reservas da semana"} />
            <Tab label={"Pesquisar reservas"} />
            {userRole > 5 && <Tab label={"Reservas sem Local"} />}
            <Tab label={"Participantes"} />
          </Tabs>
          <TabPanel value={currentTab} index={0}>
            <WeekReservation
              id={props.id}
              idActivityType={String(items.idAtividadeTipo)}
              activityName={String(items.nome)}
              from="activity"
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <SearchReservation idActivity={props.id} />
          </TabPanel>
          {userRole > 5 && (
            <TabPanel value={currentTab} index={2}>
              <ActivityReservationLocation idActivity={props.id} />
            </TabPanel>
          )}
          <TabPanel value={currentTab} index={3}>
            <ActivityParticipant idActivity={props.id} />
          </TabPanel>
        </Box>
      </PageContainer>
    );
  }
};

Activity.defaultProps = {
  idActivity: undefined,
};

Activity.propTypes = {
  idActivity: PropTypes.string,
};

export default Activity;
