import React, { useState, useEffect, useContext, useRef } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import VerifyPerson from "components/atoms/info/VerifyPerson";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import SelectOccupantType from "components/atoms/select/SelectOccupantType";
import LocationOccupantTable from "components/molecules/table/LocationOccupantTable";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import ButtonTimePicker from "components/atoms/dateTime/ButtonTimePicker";
import {
  getFormattedDateURL,
  getFormattedTime,
  getFormattedTimeHour,
  getMinTime,
  getMaxTime,
} from "components/atoms/dateTime/GetFormattedDate";

const AddOccupant = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `local/${props.idLocation}/ocupante`;

      let dateExpirationFormatted = null;
      let timeBeginFormatted = null;
      let timeEndFormatted = null;
      if (props.showDate) {
        const dateExpiration = props.dateExpiration;
        dateExpirationFormatted = getFormattedDateURL(dateExpiration);
      }
      if (props.showHour) {
        const timeBegin = props.dateBegin;
        const timeEnd = props.dateEnd;
        timeBeginFormatted = getFormattedTime(timeBegin);
        timeEndFormatted = getFormattedTime(timeEnd);
      }

      const request = [
        {
          idOcupacaoTipo: props.occupantType.id,
          nome: props.name,
          userId: props.occupantId,
          entrada: timeBeginFormatted,
          saida: timeEndFormatted,
          vencimento: dateExpirationFormatted,
        },
      ];

      instance
        .post(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage("Erro", "error");
          } else {
            snackContext.setMessage(`Ocupante salvo com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const LocationOccupant = (props) => {
  const dateNow = new Date();
  const hourForBegin = getMinTime("07");
  const hourForEnd = getMinTime("22");

  const [load, setLoad] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showHour, setShowHour] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [disableOccupantId, setDisableOccupantId] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);

  const [occupantType, setOccupantType] = useState(null);
  const [occupantId, setOccupantId] = useState("");
  const [name, setName] = useState("");
  const [dateExpiration, setDateExpiration] = useState(dateNow);
  const [dateBegin, setDateBegin] = useState(hourForBegin);
  const [dateEnd, setDateEnd] = useState(hourForEnd);

  const [errorOccupantType, setErrorOccupantType] = useState(false);
  const [errorOccupantId, setErrorOccupantId] = useState(false);

  const refOccupantType = useRef(null);
  const refOccupantId = useRef(null);

  const userRole = GetUserRole();

  const handleShowForm = () => {
    !showForm && setShowForm(true);
  };

  const handleNotShowForm = () => {
    showForm && setShowForm(false);
    setDisableOccupantId(false);
  };

  const handleChangeCheckHour = () => {
    setShowHour(!showHour);
  };

  const handleChangeCheckDate = () => {
    setShowDate(!showDate);
  };

  const handleClose = (event) => {
    load && setLoad(false);
    handleNotShowForm();
    setShowHour(false);
    setShowDate(false);
    setReloadTable(!reloadTable);
    setOccupantType(null);
    setOccupantId("");
    setName("");
    errorOccupantType && setErrorOccupantType(false);
    errorOccupantId && setErrorOccupantId(false);
  };

  const clickUpdateCallback = (childData) => {
    handleShowForm();
    setDisableOccupantId(true);
    childData.forEach((element) => {
      const occupantTypeArray = {
        id: element.idOcupanteTipo,
        label: element.tipo,
      };
      setOccupantType(occupantTypeArray);
      setName(element.nome);
      setOccupantId(element.userId);
    });
  };

  const occupantTypeCallback = (childData) => {
    if (errorOccupantType) setErrorOccupantType(false);

    setOccupantType(childData);
  };

  const idOccuppantCallback = (childData) => {
    if (load) setLoad(false);
    if (errorOccupantId) setErrorOccupantId(false);
    if (
      Number(childData) ||
      childData === "0" ||
      childData === "00" ||
      childData === ""
    )
      setOccupantId(childData);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeDateExpiration = (newValue) => {
    setDateExpiration(newValue);
  };

  const handleChangeDateBegin = (newValue) => {
    setDateBegin(newValue);
  };

  const handleChangeDateEnd = (newValue) => {
    setDateEnd(newValue);
  };

  const getDateEnd = () => {
    const hour = String(Number(getFormattedTimeHour(dateBegin)) + 1);

    return hour;
  };

  const handleClick = () => {
    if (!occupantId) {
      refOccupantId.current.focus();
      if (!errorOccupantId) setErrorOccupantId(true);
      alert("Por favor, preencha o campo Ocupante (CPF).");
    } else if (!occupantType) {
      refOccupantType.current.focus();
      if (!errorOccupantType) setErrorOccupantType(true);
      alert("Por favor, selecione um Tipo de Ocupante.");
    } else {
      setLoad(true);
    }
  };

  const NewButtons = () => {
    const handleNew = () => {
      handleClose();
      handleShowForm();
    };

    return (
      <Box>
        {!showForm && (
          <Button variant="contained" onClick={handleNew}>
            Adicionar Ocupante a este Local
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      {userRole > 5 && <NewButtons />}
      {userRole > 5 && showForm && (
        <Box id="formLocationOccupant">
          <Box id="ControlButtons">
            <InfoTooltip placement="top-start" title="Voltar">
              <IconButton onClick={handleNotShowForm}>
                <ArrowBackIcon />
              </IconButton>
            </InfoTooltip>
            <InfoTooltip placement="top-start" title="Salvar">
              <IconButton onClick={handleClick}>
                <SaveIcon />
              </IconButton>
            </InfoTooltip>
          </Box>
          <Box>
            <Checkbox checked={showHour} onChange={handleChangeCheckHour} />
            Faixa de horário
            <Checkbox checked={showDate} onChange={handleChangeCheckDate} />
            Data de vencimento
          </Box>
          <Box>
            <VerifyPerson
              title="Ocupante"
              dataParentToChild={occupantId}
              parentCallback={idOccuppantCallback}
              ref={refOccupantId}
              errorParentToChild={errorOccupantId}
            />
          </Box>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus={disableOccupantId}
                fullWidth
                margin="dense"
                value={name}
                onChange={handleChangeName}
                id="name"
                label="Nome"
                name="name"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectOccupantType
                autofocus
                dataParentToChild={occupantType}
                parentCallback={occupantTypeCallback}
                ref={refOccupantType}
                errorParentToChild={errorOccupantType}
              />
            </Grid>
            {showHour && (
              <>
                <Grid item xs={6} sm={3} lg={2}>
                  <ButtonTimePicker
                    id={"dateBegin"}
                    views={["hours"]}
                    skipDisabled
                    minTime={getMinTime("07")}
                    maxTime={getMaxTime("21")}
                    value={dateBegin}
                    label={
                      dateBegin == null
                        ? "Hora Início"
                        : `Hora Início: ${getFormattedTimeHour(dateBegin)}`
                    }
                    onChange={handleChangeDateBegin}
                  />
                </Grid>
                <Grid item xs={6} sm={3} lg={2}>
                  <ButtonTimePicker
                    disable
                    id={"dateEnd"}
                    views={["hours"]}
                    disabled={!dateBegin}
                    skipDisabled
                    minTime={dateBegin && getMinTime(getDateEnd())}
                    maxTime={getMaxTime("22")}
                    value={dateEnd}
                    label={
                      dateEnd == null
                        ? "Hora Fim"
                        : `Hora Fim: ${getFormattedTimeHour(dateEnd)}`
                    }
                    onChange={handleChangeDateEnd}
                  />
                </Grid>
              </>
            )}
            {showDate && (
              <>
                <Grid item xs={12} sm={4} lg={2}>
                  <ButtonDatePicker
                    value={dateExpiration}
                    onChange={handleChangeDateExpiration}
                    id="dateExpire"
                    label="Data de Vencimento"
                    name="dateExpire"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      )}

      <Box id="boxData" sx={{ mt: 2 }}>
        <LocationOccupantTable
          idLocation={props.idLocation}
          clickUpdateCallback={clickUpdateCallback}
          reloadTable={reloadTable}
        />
        <AddOccupant
          idLocation={props.idLocation}
          occupantType={occupantType}
          name={name}
          occupantId={occupantId}
          showHour={showHour}
          showDate={showDate}
          dateExpiration={dateExpiration}
          dateBegin={dateBegin}
          dateEnd={dateEnd}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

export default LocationOccupant;
