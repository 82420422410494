import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { alpha } from "@mui/material/styles";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";

import EditIcon from "@mui/icons-material/Edit";

import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import {
  StyledTableCell,
  StyledTableRow,
} from "components/atoms/table/StyledTable";

import Paper from "@mui/material/Paper";

import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import Pagination from "components/atoms/table/Pagination";
import InfoTooltip from "components/atoms/info/InfoTooltip";

import {
  getDisplayDateWeek,
  getDisplayTime,
} from "components/atoms/dateTime/GetFormattedDate";

import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const request = {
        reservas: props.selected,
      };
      instance
        .delete("reserva", { data: request })
        .then(function (response) {
          setIsLoaded(true);
          //const result = response.data;

          snackContext.setMessage("Reservas excluídas com sucesso", "success");
          props.handleClose();
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

function EnhancedTableToolbar(props) {
  const { numSelected, handleClickDelete } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...{
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        },
      }}
    >
      {numSelected > 0 ? (
        <>
          <InfoTooltip title="Excluir" placement="top">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}{" "}
            {numSelected === 1
              ? " reserva selecionada"
              : "reservas selecionadas"}
          </Typography>
        </>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Reservas
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleClickDelete: PropTypes.func.isRequired,
};

const headActivityCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Data",
  },
  {
    id: "begin",
    numeric: true,
    disablePadding: false,
    label: "Início",
  },
  {
    id: "end",
    numeric: true,
    disablePadding: false,
    label: "Fim",
  },
  {
    id: "block",
    numeric: false,
    disablePadding: false,
    label: "Bloco",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Local",
  },
];

const headLocationCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Data",
  },
  {
    id: "begin",
    numeric: true,
    disablePadding: false,
    label: "Início",
  },
  {
    id: "end",
    numeric: true,
    disablePadding: false,
    label: "Fim",
  },
  {
    id: "activity",
    numeric: false,
    disablePadding: false,
    label: "Atividade",
  },
];

function EnhancedTableHead(props) {
  const { userRole, onSelectAllClick, numSelected, rowCount, headCells } =
    props;

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell padding="checkbox">
          {userRole > 7 && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all",
              }}
              sx={{
                color: "white",
                "&.Mui-checked": {
                  color: "white",
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "white",
                },
                "&:hover": {
                  color: "primary.main",
                  backgroundColor: "white",
                },
                mr: 2,
              }}
            />
          )}
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            //align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
        {userRole > 7 && (
          <StyledTableCell padding="none">Editar</StyledTableCell>
        )}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ReservationActivityLocationTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const [msgDelete, setMsgDelete] = useState("");

  const [selected, setSelected] = useState([]);

  const userRole = GetUserRole();
  const navigate = useNavigate();

  const rows = props.rows;
  const rowsLength = rows.length;

  const handleClickEdit = (event) => {
    navigate(`/reserva-atualiza/${event.currentTarget.id}`);
  };

  //Delete
  const handleClickDelete = (event) => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = (event) => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = (event) => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };
  const handleCloseSuccessDelete = (event) => {
    setLoadDelete(false);
    setSuccessDelete(!successDelete);
    setSelected([]);
    props.successDeleteCallback();
  };

  //#region checkbox
  const createMsgDelete = (newSelected) => {
    let selectedMsg;

    rows.forEach((element) => {
      newSelected.forEach((sel) => {
        if (element.id === sel) {
          selectedMsg = (
            <>
              {selectedMsg}
              <StyledTableRow>
                <StyledTableCell>
                  {getDisplayDateWeek(element.inicio)}
                </StyledTableCell>
                <StyledTableCell>
                  {getDisplayTime(element.inicio)}
                </StyledTableCell>
                <StyledTableCell>{getDisplayTime(element.fim)}</StyledTableCell>
                {props.from === "activity" ? (
                  <>
                    <StyledTableCell>{element.bloco}</StyledTableCell>
                    <StyledTableCell>
                      {element.numero} - {element.local}
                    </StyledTableCell>
                  </>
                ) : (
                  <>
                    <StyledTableCell>{element.atividade}</StyledTableCell>
                  </>
                )}
              </StyledTableRow>
            </>
          );
        }
      });
    });

    setMsgDelete(selectedMsg);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      createMsgDelete(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    createMsgDelete(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  //#endregion

  const pageCallback = (childData) => {
    setPage(childData);
  };

  const rowsPerPageCallback = (childData) => {
    setRowsPerPage(childData);
  };

  if (rowsLength === 0) {
    return (
      <Box sx={{ mt: 3, mb: 2 }}>
        <Typography sx={{ color: "error.main" }}>
          Nenhuma reserva encontrada.
        </Typography>
      </Box>
    );
  } else {
    const headCells =
      props.from === "activity" ? headActivityCells : headLocationCells;
    const cellsHeadDelete = headCells.map((headCell) => (
      <StyledTableCell key={headCell.id}>{headCell.label}</StyledTableCell>
    ));
    const cells = (
      rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const labelId = `enhanced-table-checkbox-${index}`;
      return (
        <StyledTableRow
          role="checkbox"
          aria-checked={isItemSelected}
          tabIndex={-1}
          key={row.id}
          selected={isItemSelected}
        >
          <StyledTableCell padding="checkbox">
            {userRole > 7 && (
              <Checkbox
                onClick={(event) => handleClick(event, row.id)}
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            )}
          </StyledTableCell>
          <StyledTableCell id={labelId} width="210">
            {getDisplayDateWeek(row.inicio)}
          </StyledTableCell>
          <StyledTableCell width="70">
            {getDisplayTime(row.inicio)}
          </StyledTableCell>
          <StyledTableCell width="70">
            {getDisplayTime(row.fim)}
          </StyledTableCell>
          {props.from === "activity" ? (
            <>
              <StyledTableCell>
                {row.bloco ? row.bloco : "Sem local"}
              </StyledTableCell>
              <StyledTableCell>
                {row.local ? `${row.numero} - ${row.local}` : "Sem local"}
              </StyledTableCell>
            </>
          ) : (
            <>
              <StyledTableCell>{row.atividade}</StyledTableCell>
            </>
          )}
          {userRole > 7 && (
            <StyledTableCell
              component="th"
              id={labelId}
              scope="row"
              padding="none"
              width="50"
            >
              <InfoTooltip placement="top-start" title="Editar Reserva">
                <IconButton id={row.id} onClick={handleClickEdit}>
                  <EditIcon />
                </IconButton>
              </InfoTooltip>
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    });

    return (
      <Box sx={{ mt: 3, mb: 2 }}>
        <Paper>
          <EnhancedTableToolbar
            numSelected={selected.length}
            handleClickDelete={handleClickDelete}
          />
          <TableContainer>
            <Table aria-label="data table" size="small">
              <EnhancedTableHead
                userRole={userRole}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                headCells={headCells}
              />
              <TableBody>{cells}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Pagination
          name="Reservas"
          length={rows.length}
          pageParentToChild={page}
          rowsPerPageParentToChild={rowsPerPage}
          pageCallback={pageCallback}
          rowsPerPageCallback={rowsPerPageCallback}
        />
        <Box id="deleteBox">
          <DeleteReservation
            loadDelete={loadDelete}
            selected={selected}
            handleClose={handleCloseSuccessDelete}
          />
          <MessageDialog
            confirm
            variant={MessageDialogVariants.error}
            title="Exclusão"
            open={openDeleteConfirmDialog}
            onClose={handleCloseDeleteDialog}
            onConfirm={handleConfirmDelete}
          >
            <p>
              Você tem certeza que gostaria de excluir{" "}
              {selected.length === 1 ? "esta reserva:" : "estas reservas:"}{" "}
            </p>
            <TableContainer>
              <Table aria-label="data table" size="small">
                <TableHead>
                  <StyledTableRow>{cellsHeadDelete}</StyledTableRow>
                </TableHead>
                <TableBody>{msgDelete}</TableBody>
              </Table>
            </TableContainer>
          </MessageDialog>
        </Box>
      </Box>
    );
  }
};

ReservationActivityLocationTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default ReservationActivityLocationTable;
