import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { addWeeks } from "date-fns";
import { endOfWeek, startOfWeek, nextDay } from "date-fns";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import {
  formatReservation,
  getFormatDate,
  getFormattedDateURL,
  getParseDate,
} from "components/atoms/dateTime/GetFormattedDate";
import ReservationTable from "components/molecules/table/ReservationTable";

const WeekReservation = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [text, setText] = useState("");
  const [dateDisplay, setDateDisplay] = useState(new Date());
  const [reloadTable, setReloadTable] = useState(false);

  const snackContext = useContext(SnackContext);

  const doReloadTable = () => {
    setReloadTable(!reloadTable);
  };

  const handleBackButtonClick = () => {
    const newDate = addWeeks(dateDisplay, -1);
    setDateDisplay(newDate);
  };

  const handleNextButtonClick = () => {
    const newDate = addWeeks(dateDisplay, 1);
    setDateDisplay(newDate);
  };

  useEffect(() => {
    setIsLoaded(false);
    if (props.id) {
      //#region Days of the week
      const dayWeek = [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ];
      const sunday = startOfWeek(dateDisplay);
      const monday = nextDay(sunday, 1);
      const tuesday = nextDay(sunday, 2);
      const wednesday = nextDay(sunday, 3);
      const thursday = nextDay(sunday, 4);
      const friday = nextDay(sunday, 5);
      const saturday = endOfWeek(dateDisplay);
      const arrayWeek = [
        getFormattedDateURL(sunday),
        getFormattedDateURL(monday),
        getFormattedDateURL(tuesday),
        getFormattedDateURL(wednesday),
        getFormattedDateURL(thursday),
        getFormattedDateURL(friday),
        getFormattedDateURL(saturday),
      ];
      const textDate =
        getFormatDate(sunday) + " até " + getFormatDate(saturday);
      setText(textDate);
      //#endregion

      const from = props.activityName ? "atividade" : "local";
      const fullURL = `${from}/${props.id}/reserva/${arrayWeek[0]}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          let resultNew = [];
          for (let index = 0; index < 7; index++) {
            resultNew[index] = [];
          }

          const resultReservations = Object.values(result.reservas);
          resultReservations.forEach((element) => {
            const dateBegin = getParseDate(element.inicio);
            const indexDay = dateBegin.getDay();

            resultNew[indexDay].push(element);
          });

          let tableRow = [];
          let dayIndex = 0;
          resultNew.forEach((row) => {
            let cellsRow = [];
            let cellsIndex = [];
            let cells = [];

            row.forEach((reservation) => {
              const reservationFormatted = formatReservation(reservation);
              cellsIndex.push(reservationFormatted[0]);
              cells[reservationFormatted[0]] = {
                idReservation: reservation.id,
                requester: reservation.solicitante,
                idActivity: reservation.idAtividade,
                idActivityType: props.idActivityType
                  ? props.idActivityType
                  : reservation.idAtividadeTipo,
                activityName: reservation.atividade,
                idLocation: reservation.idLocal,
                blockName: reservation.bloco,
                locationName: `${reservation.local} (${reservation.numero})`,
                participants: reservation.participantes,
                colSpan: reservationFormatted[1],
                intervalHour: reservationFormatted[2],
                intervalDate: reservationFormatted[3],
              };
            });
            for (let index = 7; index < 22; index++) {
              if (cellsIndex.includes(index)) {
                cellsRow.push({
                  key: index,
                  index: index,
                  resultValueId: props.id,
                  activity: cells[index],
                });
                const gap = cells[index].colSpan - 1;
                if (gap > 0) index += gap;
              } else {
                cellsRow.push({
                  key: index,
                  id: `${props.id}&${index}&${arrayWeek[dayIndex]}`,
                });
              }
            }

            tableRow.push({
              numero: dayWeek[dayIndex],
              reservas: cellsRow,
              id: arrayWeek[dayIndex],
            });
            dayIndex++;
          });
          setItems(tableRow);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext, dateDisplay, reloadTable]);

  if (props.id) {
    if (!isLoaded) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <Box sx={{ textAlign: "center" }}>
            <Box sx={{ display: "inline-flex" }}>
              <IconButton onClick={handleBackButtonClick}>
                <KeyboardArrowLeft />
              </IconButton>
              <Typography sx={{ mt: 1 }}>{text}</Typography>
              <IconButton onClick={handleNextButtonClick}>
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          </Box>
          <ReservationTable
            {...props}
            items={items}
            doReloadTable={doReloadTable}
          />
        </>
      );
    }
  }
};

WeekReservation.propTypes = {
  id: PropTypes.string.isRequired,
};

export default WeekReservation;
