import React from "react";
import { useParams } from "react-router-dom";

import ReservationNewPage from "components/pages/ReservationEquipment/ReservationNew";

const ReservationNew = () => {
  const { name, id, date, hour } = useParams();

  return <ReservationNewPage name={name} id={id} date={date} hour={hour} />;
};

export default ReservationNew;
