import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { SnackContext } from "context/SnackProvider";
import PageContainer from "components/templates/PageContainer";
import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";
import BlockCard from "components/molecules/card/BlockCard";
import BlockReservation from "components/organisms/Reservation/BlockReservation";
import InfoTooltip from "components/atoms/info/InfoTooltip";
import instance, { GetUserRole, getErrorMessage } from "api/definitions";
import MessageDialog, {
  MessageDialogVariants,
} from "components/atoms/info/MessageDialog";

const DeleteBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    setIsLoaded(false);
    if (props.loadDelete) {
      const fullURLDelete = `bloco/${props.id}`;
      instance
        .delete(fullURLDelete)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível excluir este bloco",
              "error"
            );
          } else {
            snackContext.setMessage("Bloco excluído com sucesso", "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.loadDelete && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const userRole = GetUserRole();
  const dateNow = new Date();
  const [dateReservation, setDateReservation] = useState(dateNow);

  const [loadDelete, setLoadDelete] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [floor, setFloor] = useState(0);
  const [underground, setUnderground] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const block = props.block;
    setId(String(block.id));
    setName(String(block.nome));
    setDescription(String(block.descricao));
    setFloor(block.andares);
    setUnderground(block.subsolo);
  }, [props.block]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeDateReservation = (newValue) => {
    setDateReservation(newValue);
  };

  const handleClickEdit = (event) => {
    navigate("/bloco-atualiza/" + event.currentTarget.id);
  };

  //Delete
  const handleClickDelete = () => {
    setOpenDeleteConfirmDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteConfirmDialog(false);
  };
  const handleConfirmDelete = () => {
    setOpenDeleteConfirmDialog(false);
    setLoadDelete(true);
  };

  return (
    <>
      <InfoTooltip placement="top-start" title="Voltar">
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </InfoTooltip>
      {userRole > 5 && (
        <Box id="ControlButtons" sx={{ display: "inline" }}>
          <InfoTooltip placement="top-end" title="Editar">
            <IconButton onClick={handleClickEdit} id={id}>
              <EditIcon />
            </IconButton>
          </InfoTooltip>
          <InfoTooltip placement="top-end" title="Excluir">
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </InfoTooltip>
        </Box>
      )}
      <BlockCard
        idBlock={id}
        name={name}
        description={description ? description : "(Não informada)"}
        floor={floor}
        underground={underground}
      />
      <Box id="boxDelete">
        <DeleteBlock loadDelete={loadDelete} id={id} handleClose={handleBack} />
        <MessageDialog
          confirm
          variant={MessageDialogVariants.error}
          title="Exclusão"
          open={openDeleteConfirmDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleConfirmDelete}
          content={
            <>
              Você tem certeza que gostaria de excluir este bloco?
              <br />
              {`Bloco: ${name}`}
            </>
          }
        />
      </Box>
      <Box id="boxReservation" sx={{ mt: 4, textAlign: "center" }}>
        <Box sx={{ display: "inline-flex" }}>
          <Typography variant="h6" sx={{ mt: 1.5, mr: 1.5 }}>
            Reservas:
          </Typography>
          <ButtonDatePicker
            value={dateReservation}
            onChange={handleChangeDateReservation}
            id="dateReservation"
            label="Data das Reservas"
            name="dateReservation"
          />
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <BlockReservation id={id} dateReservation={dateReservation} />
      </Box>
    </>
  );
};

const GetBlock = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.id) {
      const fullURL = `bloco/${props.id}`;
      instance
        .get(fullURL)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          setItems(result);
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props.id, snackContext]);

  if (!isLoaded) {
    return <CircularProgress />;
  } else {
    return <Fields {...props} block={items} />;
  }
};

const Block = (props) => {
  return (
    <PageContainer maxWidth="lg" title={"Bloco"} icon={<AccountBalanceIcon />}>
      <GetBlock {...props} />
    </PageContainer>
  );
};

Block.defaultProps = {
  idBlock: undefined,
};

Block.propTypes = {
  idBlock: PropTypes.string,
};

export default Block;
