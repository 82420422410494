import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";
import ViewListIcon from "@mui/icons-material/ViewList";

import { GetUserRole } from "api/definitions";
import UnitDivision from "components/organisms/UnitDivision";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const UnitCard = (props) => {
  const [idUnit, setIdUnit] = useState("");
  const [viewDivisions, setViewDivisions] = useState(false);

  const userRole = GetUserRole();
  const navigate = useNavigate();

  const handleClickNew = (event) => {
    navigate(`/unidade/${props.idUnit}/divisao-nova`);
  };

  const handleClickView = (event) => {
    navigate(`/unidade/${event.currentTarget.id}`);
  };

  const handleClickDivisions = (event) => {
    setIdUnit(event.currentTarget.id);
    setViewDivisions(!viewDivisions);
  };

  return (
    <Card raised id={props.idUnit} sx={{ mb: 1, minWidth: 300 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={9} md={10}>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item>
                {props.list ? (
                  <Button onClick={handleClickView} id={props.idUnit}>
                    <Typography variant="h6"> {props.name}</Typography>
                  </Button>
                ) : (
                  <Typography>
                    <strong>Nome:</strong> {props.name}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography>
                  <strong>Descrição:</strong>{" "}
                  {props.description ? props.description : "(Não informada)"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} md={2}>
            <Box sx={{ display: "flex" }}>
              <InfoTooltip title="Divisões" placement="top">
                <ToggleButton
                  id={props.idUnit}
                  value={props.idUnit}
                  onClick={handleClickDivisions}
                  selected={viewDivisions}
                  aria-label="0"
                >
                  <ViewListIcon />
                </ToggleButton>
              </InfoTooltip>
              {userRole > 7 && viewDivisions && (
                <Box sx={{ ml: 1 }}>
                  <InfoTooltip title="Nova Divisão" placement="top">
                    <IconButton
                      id={props.idUnit}
                      color="primary"
                      aria-label="nova divisão"
                      onClick={handleClickNew}
                      sx={{
                        color: "white",
                        bgcolor: "primary.main",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                        },
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </InfoTooltip>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {viewDivisions && <UnitDivision id={idUnit} />}
      </CardContent>
    </Card>
  );
};

UnitCard.defaultProps = {
  list: false,
  idUnit: undefined,
  name: undefined,
  description: undefined,
};

UnitCard.propTypes = {
  list: PropTypes.bool,
  idUnit: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};

export default UnitCard;
