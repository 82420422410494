import React from "react";
import { useParams } from "react-router-dom";

import ReservationUpdateDropPage from "components/pages/ReservationEquipment/ReservationUpdateDrop";

const ReservationUpdateDrop = () => {
  const { id, idLocation, date, hour } = useParams();

  return (
    <ReservationUpdateDropPage
      id={id}
      idLocation={idLocation}
      date={date}
      hour={hour}
    />
  );
};

export default ReservationUpdateDrop;
