import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { addDays } from "date-fns";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Tooltip from "@mui/material/Tooltip";

import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ButtonDatePicker from "components/atoms/dateTime/ButtonDatePicker";

const SwitchRepeatUpdate = (props) => {
  const [checkedRepeat, setCheckedRepeat] = useState(false);
  const [weekDays, setWeekDays] = useState(() => []);
  const [dateRepeatEnd, setDateRepeatEnd] = useState(null);
  const [dateReservation, setDateReservation] = useState(null);

  useEffect(() => {
    setCheckedRepeat(props.checkedParentToChild);
    setWeekDays(props.weekDaysParentToChild);
    setDateRepeatEnd(props.dateRepeatEndParentToChild);
    setDateReservation(props.dateReservationParentToChild);
  }, [props]);

  const handleChangeCheckConflict = (event) => {
    const newValue = event.target.checked;
    setCheckedRepeat(newValue);
    props.checkedParentCallback(newValue);
  };

  const handleChangeWeekDays = (event, newValue) => {
    setWeekDays(newValue);
    props.weekDaysParentCallback(newValue);
  };

  const handleChangeDateRepeatEnd = (newValue) => {
    setDateRepeatEnd(newValue);
    props.dateRepeatEndParentCallback(newValue);
  };

  return (
    <>
      <Switch
        checked={checkedRepeat}
        disabled={props.repeatDisabled}
        onChange={handleChangeCheckConflict}
      />
      Selecionar Repetição
      {checkedRepeat && (
        <Box
          sx={{
            border: 1,
            borderRadius: 2,
            borderColor: "primary.main",
            pl: 2,
            pr: 2,
            pb: 2,
          }}
        >
          <Box sx={{ mt: 2 }}>
            <ToggleButtonGroup
              color="primary"
              value={weekDays}
              onChange={handleChangeWeekDays}
              aria-label="weekDays"
            >
              <ToggleButton value={0} aria-label="0" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Domingo" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">D</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={1} aria-label="1" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Segunda" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">S</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={2} aria-label="2" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Terça" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">T</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={3} aria-label="3" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Quarta" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">Q</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={4} aria-label="4" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Quinta" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">Q</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={5} aria-label="5" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Sexta" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">S</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={6} aria-label="6" sx={{ height: 50, p: 0 }}>
                <Tooltip title="Sábado" followCursor>
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h6">S</Typography>
                  </Box>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ mt: 2 }}>
            <ButtonDatePicker
              value={dateRepeatEnd}
              onChange={handleChangeDateRepeatEnd}
              minDate={addDays(dateReservation, 1)}
              id="dateRepeatEnd"
              label="Data final da Repetição"
              name="dateRepeatEnd"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

SwitchRepeatUpdate.defaultProps = {
  repeatDisabled: false,
  checkedParentToChild: false,
  weekDaysParentToChild: [],
  dateRepeatEndParentToChild: undefined,
  dateReservationParentToChild: undefined,
  checkedParentCallback: () => {},
  weekDaysParentCallback: () => {},
  dateRepeatEndParentCallback: () => {},
};

SwitchRepeatUpdate.propTypes = {
  repeatDisabled: PropTypes.bool,
  checkedParentToChild: PropTypes.bool,
  weekDays: PropTypes.array,
  dateRepeatEndParentToChild: PropTypes.instanceOf(Date),
  dateReservationParentToChild: PropTypes.instanceOf(Date),
  checkedParentCallback: PropTypes.func,
  weekDaysParentCallback: PropTypes.func,
  dateRepeatEndParentCallback: PropTypes.func,
};

export default SwitchRepeatUpdate;
