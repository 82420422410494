import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import AccessibleIcon from "@mui/icons-material/Accessible";

import { SnackContext } from "context/SnackProvider";
import instance, { getErrorMessage } from "api/definitions";
import PageContainer from "components/templates/PageContainer";
import InfoTooltip from "components/atoms/info/InfoTooltip";

const ModifyResource = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    if (props.load) {
      setIsLoaded(false);

      const fullURL = `recurso/${props.id}`;

      const accessible = props.accessible ? "1" : "0";
      const request = {
        nome: props.name,
        pcd: accessible,
      };

      instance
        .put(fullURL, request)
        .then(function (response) {
          setIsLoaded(true);
          const result = response.data;
          if (!result.performed) {
            snackContext.setMessage(
              "Não foi possível atualizar este recurso",
              "error"
            );
          } else if (result.affected === 0) {
            snackContext.setMessage(
              "Nada foi modificado. Modifique alguma informação ou clique em voltar.",
              "warning"
            );
          } else {
            snackContext.setMessage(`Atualização salva com sucesso`, "success");
            props.handleClose();
          }
        })
        .catch(function (error) {
          setIsLoaded(true);
          const msg = getErrorMessage(error);
          snackContext.setMessage(msg, "error");
        });
    }
  }, [props, snackContext]);

  if (props.load && !isLoaded) return <CircularProgress />;
};

const Fields = (props) => {
  const [load, setLoad] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [checkedAccessible, setCheckedAccessible] = useState(false);

  const [errorName, setErrorName] = useState(false);

  const refName = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const items = props.items;
    setId(String(items.id));
    setName(String(items.nome));
    const accessible = items.pcd ? true : false;
    setCheckedAccessible(accessible);
  }, [props]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleClose = (event) => {
    handleBack();
  };

  const handleChangeName = (event) => {
    if (load) setLoad(false);
    if (errorName) setErrorName(false);

    setName(event.target.value);
  };

  const handleChangeAccessible = (event) => {
    if (load) setLoad(false);

    setCheckedAccessible(event.target.checked);
  };

  const handleClick = () => {
    if (!name) {
      refName.current.focus();
      if (!errorName) setErrorName(true);
      alert("Por favor, preencha o Nome.");
    } else {
      setLoad(true);
    }
  };

  return (
    <>
      <Box id="ControlButtons">
        <InfoTooltip placement="top-start" title="Voltar">
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </InfoTooltip>
        <InfoTooltip placement="top-start" title="Salvar">
          <IconButton onClick={handleClick}>
            <SaveIcon />
          </IconButton>
        </InfoTooltip>
      </Box>
      <Box
        component="form"
        sx={{
          mt: 4,
        }}
      >
        <Box sx={{ display: "flex", mt: 2 }}>
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <AccessibleIcon />
          </Avatar>
          <InfoTooltip placement="right" title="Recurso PCD">
            <Checkbox
              color="primary"
              checked={checkedAccessible}
              onChange={handleChangeAccessible}
              inputProps={{
                "aria-label": "Recurso PCD",
              }}
            />
          </InfoTooltip>
        </Box>
        <TextField
          fullWidth
          margin="dense"
          inputProps={{ maxLength: 200 }}
          inputRef={refName}
          error={errorName}
          value={name}
          onChange={handleChangeName}
          id="name"
          label="Nome"
          name="name"
          autoComplete="off"
        />
      </Box>
      <Box id="boxData" sx={{ mt: 2 }}>
        <ModifyResource
          id={id}
          name={name}
          accessible={checkedAccessible}
          load={load}
          handleClose={handleClose}
        />
      </Box>
    </>
  );
};

const ResourceItems = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const snackContext = useContext(SnackContext);

  useEffect(() => {
    const fullURL = "recurso/" + props.id;

    instance
      .get(fullURL)
      .then(function (response) {
        setIsLoaded(true);
        const result = response.data;
        setItems(result);
      })
      .catch(function (error) {
        setIsLoaded(true);
        const msg = getErrorMessage(error);
        snackContext.setMessage(msg, "error");
      });
  }, [props.id, snackContext]);

  if (!isLoaded) return <CircularProgress />;
  else return <Fields items={items} />;
};

const ResourceUpdate = (props) => {
  return (
    <PageContainer
      maxWidth="lg"
      title={"Atualizar Recurso"}
      icon={<EditIcon />}
    >
      <ResourceItems {...props} />
    </PageContainer>
  );
};

export default ResourceUpdate;
